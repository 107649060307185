<template>
    <div>
      <Navbar />
      <section class="section has-background-white" style="min-height: 100vh;">
        <div class="card">
        <span class="title">{{$t('payments.title')}}</span>
      
        <table style="margin-top: 15px;" class="table is-fullwidth is-bordered is-striped is-hoverable">
          <thead>
            <th>{{$t('payments.created_at')}}</th>
            <th>{{$t('payments.amount')}}</th>
            <th>{{$t('payments.currency')}}</th>
          </thead>
          <tbody v-if="payments.length">
            <tr v-for="payment in payments" :key="payment.id">
              <td>{{ payment.created_at }}</td>
              <td>{{ payment.amount }}</td>
              <td>{{ payment.currency }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td></td>
              <td class="has-text-centered" colspan="6">{{ $t("payments.not_found") }}</td>
            </tr>
          </tbody>
        </table>
        </div>
      </section>  
     
    </div>
  </template>  
  <script>
  import Navbar from "@/components/NavBar.vue";
  
  export default {
    name: "Payments",
    data() {
      return {
        payments: [],
      };
    },
    components: {
    Navbar
    },
    methods: {
      convertDate(timestamp) {
        const dateObject = new Date(timestamp);
        const formattedTime = dateObject.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        const formattedDate = dateObject.toLocaleDateString([], { day: '2-digit', month: '2-digit', year: 'numeric' });
        return `${formattedTime} ${formattedDate}`;
      },
    },
    mounted() {
      
      this.$http.get("payments?page=1&size=10").then((res) => {
        this.payments = res.data.data;
              for (var i=0;i<this.payments.length;i++) {
                this.payments[i].created_at = this.convertDate(this.payments[i].created_at);

            
              }
      });
    },
  };
  </script>
