<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style>
::-webkit-selection {
  background-color: #9aa5af !important;
  color: #000000 !important;
}
::-moz-selection {
  background-color: #9aa5af !important;
  color: #000000 !important;
}
::selection {
  background-color: #9aa5af !important;
  color: #000000 !important;
}
</style>

<script>
import { mapState } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapState({
      isToastActive: (state) => state.toast.isActive,
      toastMessage: (state) => state.toast.message,
      toastType: (state) => state.toast.type,
    }),
  },
  mounted: () => {},
  watch: {
    isToastActive(newVal) {
      if (newVal) {
        var t = {
          position: "top-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          closeButton: "button",
        };

        if (this.toastType == "error") {
          this.$toast.error(this.toastMessage, t);
        }

        if (this.toastType == "info" || this.toastType == "") {
          this.$toast.info(this.toastMessage, t);
        }

        if (this.toastType == "success") {
          this.$toast.success(this.toastMessage, t);
        }
      }
    },
  },
};
</script>
