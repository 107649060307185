<template>
  <div :class="['modal', isActive ? 'is-active' : '']">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t("languageSelector.title") }}</p>
        <button
          class="delete"
          aria-label="close"
          @click="SET_MODAL_STATE_LANGUAGE_SELECTOR(false)"
        ></button>
      </header>
      <section class="modal-card-body">
        <ul class="language-list content">
          <li v-for="language in languages" :key="language.code">
            <div class="control">
              <label :for="language.code" class="radio">
                <input
                  type="radio"
                  :id="language.code"
                  :value="language.code"
                  v-model="selectedLanguage"
                  @change="changeLanguage"
                />

                {{ language.name }}</label
              >
            </div>
          </li>
        </ul>
      </section>
      <footer class="modal-card-foot"></footer>
    </div>
  </div>
</template>
<style scoped></style>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      languages: [
        { code: "tr", name: "Türkçe" },
        { code: "en", name: "English" },
      ],
      selectedLanguage: this.$i18n.locale,
    };
  },
  computed: {
    ...mapGetters(["selectedCamera"]),
    ...mapState({
      isActive: (state) => state.modals.languageSelector.isActive,
    }),
  },
  methods: {
    ...mapMutations(["SET_MODAL_STATE_LANGUAGE_SELECTOR", "SET_USER_LANGUAGE"]),
    changeLanguage() {
      this.SET_USER_LANGUAGE(this.selectedLanguage);
      this.$i18n.locale = this.selectedLanguage;
    },
  },
};
</script>

<style></style>
