<template>
  <div class="navbar-item is-hidden-touch">
    <div class="buttons has-addons">
      <button
        :title="$t('viewButtonGroup.files')"
        :class="[
          'button',
          'is-small',
          'is-white',
          currentRoute === 'FileList' ? '' : 'is-outlined',
        ]"
        @click="openFileListPage"
      >
        <span class="icon is-small">
          <i class="fas fa-list"></i>
        </span>
      </button>
      <button
        :title="$t('viewButtonGroup.videos')"
        :class="[
          'button',
          'is-small',
          'is-white',
          currentRoute === 'Video' ? '' : 'is-outlined',
        ]"
        @click="openVideoPage"
      >
        <span class="icon is-small">
          <i class="fas fa-film"></i>
        </span>
      </button>
      <button
        :title="$t('viewButtonGroup.timeline')"
        :class="[
          'button',
          'is-small',
          'is-white',
          currentRoute === 'Photo' ? '' : 'is-outlined',
        ]"
        @click="openTimelinePage"
      >
        <span class="icon is-small">
          <i class="fas fa-images"></i>
        </span>
      </button>
      <button
        :title="$t('viewButtonGroup.compare')"
        :class="[
          'button',
          'is-small',
          'is-white',
          currentRoute === 'Comparison' ? '' : 'is-outlined',
        ]"
        @click="openComparisonPage"
      >
        <span class="icon is-small">
          <i class="fas fa-columns"></i>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions([
      "openVideoPage",
      "openTimelinePage",
      "openFileListPage",
      "openComparisonPage",
    ]),
  },
  computed: {
    currentRoute() {
      return this.$route.name;
    },
  },
};
</script>

<style></style>
