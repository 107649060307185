<template>
  <div class="columns">
    <div class="column" v-if="permissions.includes('attach_project')">
      <Panel
        :header-text="$t('user.project_permissions.availableProjects')"
        :items="availableProjects"
        :totalPages="totalPages"
        :totalRecords="totalRecords"
        :action-button-text="$t('user.project_permissions.addAllProjects')"
        panel-theme="link"
        :message-text-if-no-items="
          $t('user.project_permissions.noProjectsAvailable')
        "
        @action-button-handler="addAllProjects"
        displayField="name"
        @select-item="addProject"
        @get-page="getPage"
        :pagination="true"
      />
    </div>
    <div class="column">
      <Panel
        :header-text="$t('user.project_permissions.selectedProjects')"
        :items="selectedProjects"
        :totalPages="totalPagesForSharedProjects"
        :action-button-text="$t('user.project_permissions.removeAllProjects')"
        panel-theme="danger"
        :message-text-if-no-items="
          $t('user.project_permissions.noProjectsSelected')
        "
        @action-button-handler="removeAllProjects"
        displayField="name"
        @select-item="removeProject"
      />
    </div>
  </div>
</template>

<script>
import Panel from "@/components/Panel.vue";
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    Panel,
  },
  data() {
    return {
      totalPagesForSharedProjects: 0,
      totalPages: 0,
      totalRecords: 0,
      companyProjects: [],
    };
  },
  computed: {
    availableProjects() {
      return this.companyProjects.filter(
        (project) => !this.user.projects.find((p) => p.id == project.id)
      );
    },
    selectedProjects() {
      //filter user projects with company id is same as selectedcompany id
      return this.user.projects.filter(
        (project) => project.companyId == this.selectedCompany.id
      );
    },
    ...mapState({
      user: (state) => state.userForm,
      company: (state) => state.selectedCompany,
      permissions: (state) => state.user.permissions,
    }),
    ...mapGetters(["selectedCompany"]),

    // totalPages() {
    //   return this.selectedUser.totalPages;
    // },
    // totalRecords() {
    //   return this.selectedUser.totalRecords;
    // },
    // totalPagesForSharedProjects() {
    //   return this.selectedUser.totalPagesForSharedProjects;
    // },
  },
  methods: {
    ...mapMutations(["SET_USERFORM_PROJECTS"]),
    getCompanyProjects() {
      this.$http
        .get("project/", {
          params: {
            op: "list_company_projects",
            cid: this.selectedCompany.id,
            page: 1,
            pageSize: 10,
          },
        })
        .then((response) => {
          console.log(response);
          this.companyProjects = response.data;
        });
    },
    addAllProjects() {
      console.log("Add all projects");
    },
    addProject(project) {
      console.log(project);
      let formData = new FormData();
      formData.append("pid", project.id);
      formData.append("uid", this.user.id);
      formData.append("op", "attach_project");

      this.$http
        .post("attach-project/", formData)
        .then(() => {
          this.SET_USERFORM_PROJECTS([...this.user.projects, project]);
          this.getCompanyProjects();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeProject(project) {
      if (!this.permissions.includes("detach_project")) return;
      let formData = new FormData();
      formData.append("pid", project.id);
      formData.append("uid", this.user.id);
      formData.append("op", "detach_project");

      this.$http
        .post("detach-project/", formData)
        .then(() => {
          this.SET_USERFORM_PROJECTS(
            this.user.projects.filter((p) => p.id !== project.id)
          );
          this.getCompanyProjects();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeAllProjects() {
      if (!this.permissions.includes("detach_project")) return;
      console.log("Remove all projects");
    },
    getPage() {
      console.log("Get page");
    },
  },
  mounted() {
    this.getCompanyProjects();
    this.availableProjects;
  },
};
</script>

<style></style>
