<template>
    <div>
      <Navbar />
      <section class="section has-background-white" style="min-height: 100vh;">
        <div class="card">
        <span class="title">{{$t('invoices.title')}}</span>
      
        <table style="margin-top: 15px;" class="table is-fullwidth is-bordered is-striped is-hoverable">
          <thead>
            <th>{{$t('invoices.created_at')}}</th>
            <th>{{$t('invoices.amount')}}</th>
            <th>{{$t('invoices.currency')}}</th>
            <th></th>
          </thead>
          <tbody v-if="invoices.length && isLoading === false">
            <tr v-for="invoice in invoices" :key="invoice.id">
              <td>{{ invoice.created_at }}</td>
              <td>{{ invoice.amount }}</td>
              <td>{{ invoice.currency }}</td>
              <td v-if="invoice.is_paid">{{ $t("invoices.paid") }}</td>
              <td v-else>
              <div class="field is-grouped">
                <p v-if="invoice.is_retried_before === false && invoice.payment_try_count === 3" class="control">
                  <button class="button is-small is-link is-outlined" @click="retryPayment(invoice.id)">
                    {{ $t("invoices.retry") }}
                  </button>
                </p>
                <p class="control">
                  <button class="button is-small is-link is-outlined" @click="$router.push('/credit-cards')">
                    {{ $t("invoices.manage_saved_cards") }}
                  </button>
                </p>
              </div>
            </td>
            
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td></td>
              <td class="has-text-centered" colspan="6">{{ $t("invoices.not_found") }}</td>
            </tr>
          </tbody>
        </table>
        </div>
      </section>  
     
    </div>
  </template>  
  <script>
  import Navbar from "@/components/NavBar.vue";
  
  export default {
    name: "Invoices",
    data() {
      return {
        invoices: [],
        isLoading: false,
      };
    },
    components: {
    Navbar
    },
    methods: {
      convertDate(timestamp) {
        const dateObject = new Date(timestamp);
        const formattedTime = dateObject.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        const formattedDate = dateObject.toLocaleDateString([], { day: '2-digit', month: '2-digit', year: 'numeric' });
        return `${formattedTime} ${formattedDate}`;
      },
      retryPayment(id){
        this.isLoading = true;
        let formData = new FormData();
        this.$http.put(`invoices/${id}/retry`,formData)
        setTimeout(() => {
          this.getInvoices();
          this.isLoading = false;
        }, 2000);

        setTimeout(() => {
          this.getInvoices();
        }, 5000);

        setTimeout(() => {
          this.getInvoices();
        }, 10000);
    },
      getInvoices(){
        this.$http.get("invoices?page=1&size=10").then((res) => {
        this.invoices = res.data.data;
              for (var i=0;i<this.invoices.length;i++) {
                this.invoices[i].created_at = this.convertDate(this.invoices[i].created_at);
              }
      });
      }
    },
    mounted() {
      this.getInvoices();
    },
  };
  </script>
