<template>
  <div class="camera-settings">
    <Navbar />
    <section class="section has-background-white" style="min-height: 100vh">
      <h1 class="title">{{ $t("cameraSettings.camera_settings_title") }}</h1>
      <div class="field">
        <label class="label"> {{ $t("cameraSettings.snapshot_frequency") }}</label>
        <div class="control">
          <label
            v-for="(schedule, index) in schedules"
            :key="index"
            class="radio"
          >
            <input
              type="radio"
              name="camera-schedule"
              :value="schedule.Name"
              v-model="selectedSchedule"
            />
            {{ schedule.Description }}
          </label>
          <label class="radio">
            <input
              type="radio"
              name="camera-schedule"
              v-model="selectedSchedule"
              value="disabled"
            />
            {{ $t("cameraSettings.stop") }}
          </label>
        </div>
      </div>
      <button class="button is-primary" @click="saveSchedule">{{ $t("cameraSettings.save") }}</button>
    </section>
  </div>
</template>
<style scoped>
.radio {
  display: block;
}
.radio + .radio {
  margin-top: 0.5rem;
  margin-left: 0;
}
</style>

<script>
import Navbar from "@/components/NavBar.vue";
import { mapGetters } from "vuex";
// import { mapMutations, mapActions } from "vuex";

export default {
  name: "BookmarkList",
  components: {
    Navbar,
  },
  data() {
    return {
      schedules: [],
      selectedSchedule: "",
    };
  },

  computed: {
    ...mapGetters(["selectedCamera"]),
  },
  mounted() {
    this.getSchedules();
  },
  methods: {
    getSchedules() {
      this.$http
        .get("schedule", {
          params: {
            op: "list_schedule",
            cid: this.selectedCamera.id,
          },
        })
        .then((response) => {
          this.schedules = response.data;

          let selected = this.schedules.find(
            (schedule) => schedule.State === "ENABLED"
          );
          if (selected) {
            this.selectedSchedule = selected.Name;
          } else {
            this.selectedSchedule = "disabled";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveSchedule() {
      var formData = new FormData();
      formData.append(
        "op",
        this.selectedSchedule !== "disabled" ? "ENABLE" : "DISABLE"
      );

      formData.append("cid", this.selectedCamera.id);
      

      this.$http
        .post("schedule", formData)
        .then((response) => {
          if (response.status == 200) {
            this.$showToastWithType(this.$t("cameraSettings.updated"),"success")
          }
          
        })
        .catch(() => {
          
        });
    },
  },
};
</script>
