<template>
  <div>
    <h1 class="title has-text-white">{{ $t("emailValidation.pageTitle") }}</h1>
    <div class="box">
    <transition name="fade" mode="out-in">
      <div v-if="validated" key="validation-success">
        <p class="notification is-success">
          {{ $t("emailValidation.validationDone") }}
        </p>
        <div class="field has-text-centered">
          <p class="control">
            <button
              class="button is-info"
              @click="$router.push('/')"
            >
              {{ $t("general.back_to_login") }}
            </button>
          </p>
        </div>
      </div>
      <div v-else key="validation-normal">
        <form action="">
          <p class="notification is-warning">
            {{ $t("emailValidation.validationText") }}
          </p>
          <div class="field">
            <p class="control has-icons-left">
              <input
                :class="['input', tokenInputClass]"
                type="token"
                v-model="token"
                :placeholder="$t('emailValidation.code')"
              />
              <span class="icon is-small is-left">
                <i class="fas fa-lock"></i>
              </span>
            </p>
          </div>
          <div class="field">
            <p class="control">
              <button
                :class="[
                  'button',
                  'is-fullwidth',
                  'is-info',
                  inProgress ? 'is-loading' : '',
                ]"
                @click.prevent="validationHandler"
              >
                {{ $t("emailValidation.validateButtonText") }}
              </button>
            </p>
          </div>
          <div class="field mt-4">
            <p class="control has-text-centered">
              <button class="button is-text" @click="resendCode">
                {{ $t("emailValidation.resendButtonText") }}
              </button>
            </p>
          </div>
        </form>
      </div>
      </transition>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { mapState } from "vuex";

// const timeOut = 3000;

export default {
  data() {
    return {
      email: "",
      token: "",
      tokenInputClass: "",
      failed: false,
      inProgress: false,
      validated: false,
      show:true,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  name: "EmailValidation",
  methods: {
    resendCode() {
      return "aBcdE12345";
    },
    async validationHandler(e) {
      e.preventDefault();
      if (!this.token) {
        this.$showToastWithType(
          this.$t("emailValidation.validationFailed"),
          "error"
        );
        return;
      }

      var formData = new FormData();
      formData.append("token", this.token);

      this.inProgress = true;
      this.$http
        .post("email-validation", formData)
        .then((response) => {
          if (response?.status < 300) {
            this.$showToastWithType(
              this.$t("emailValidation.validationDone"),
              "success"
            );
            setTimeout(() => {
              // router.push("/");
              this.validated = true;
            }, 1500);
          }
        })
        .catch((err) => {
          if (err.status) {
            this.$showToast(this.$t("register.errorMessage"));
          }
        })
        .finally(() => {
          this.inProgress = false;
        });
    },
    login_handler() {
      router.push("/");
    },
  },
  beforeMount() {
    if (!this.$route.query.email) {
      this.$router.push("/");
    }
  },
  mounted() {
    sessionStorage.removeItem("jwt");

    this.email = this.$route.query.email;

    if (this.$route.query.token) {
      this.token = this.$route.query.token;
    }
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(10px);
}
</style>
