<template>
  <nav class="navbar is-transparent px-5">
    <div class="navbar-brand">
      <a class="navbar-item" href="https://natilon.com">
        <img
          src="../assets/images/natilon_logo_white.svg"
          alt="Natilon | Time-lapse video creation made easy"
        />
      </a>
      <div class="navbar-burger" data-target="navbarExampleTransparentExample">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

    <div id="navbarExampleTransparentExample" class="navbar-menu">
      <div class="navbar-start"></div>

      <div class="navbar-end">
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link" href="#">
            <span class="icon">
              <i class="fas fa-globe"></i>
            </span>
            <span> {{ languages[selectedLanguage] }} </span>
          </a>
          <div class="navbar-dropdown is-boxed">
            <a
              v-for="lang in Object.keys(languages)"
              :key="lang"
              class="navbar-item"
              @click="setLanguage(lang)"
            >
              {{ languages[lang] }}
            </a>
          </div>
        </div>
        <div class="navbar-item" v-if="$route.name == 'LoginForm'">
          <p>{{ $t("login.not_a_user_yet") }}</p>
        </div>
        <div class="navbar-item" v-if="$route.name == 'LoginForm'">
          <div class="field is-grouped">
            <p class="control">
              <a class="button is-danger" @click="$router.push('/register')">
                <span class="icon">
                  <i class="fas fa-user-plus"></i>
                </span>
                <span>{{ $t("register.register") }}</span>
              </a>
            </p>
          </div>
        </div>

        <div class="navbar-item" v-if="$route.name != 'LoginForm'">
          <div class="field">
            <p class="control">
              <a class="button is-info" @click="$router.push('/')">
                <span>{{ $t("login.login") }}</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<style scoped>
.navbar-dropdown .navbar-item {
  color: hsl(0deg, 0%, 38%);
}
</style>
<script>
import { mapMutations } from "vuex";
export default {
  components: {},
  data() {
    return {
      languages: {
        tr: "Türkçe",
        en: "English",
      },
      selectedLanguage: this.$i18n.locale,
    };
  },
  methods: {
    ...mapMutations(["SET_USER_LANGUAGE"]),
    // ...mapMutations(["SET_CURRENT_LOGIN_FORM"]),
    setLanguage(language) {
      //for each this.languages console.log(language)
      this.selectedLanguage = language;
      this.$i18n.locale = language;
      this.SET_USER_LANGUAGE(this.selectedLanguage);
      // this.SET_MODAL_STATE_LANGUAGE_SELECTOR(false);
    },
  },
  mounted() {
    console.log("Route:", this.$route.name);
  },
};
</script>
