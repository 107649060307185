<template>
  <div class="columns">
    <div class="column" v-if="permissions.includes('attach_role')">
      <Panel
        :header-text="$t('user.roles.availableRoles')"
        :items="availableRoles"
        :totalPages="totalPages"
        :totalRecords="totalRecords"
        :action-button-text="$t('user.roles.addAllRoles')"
        panel-theme="link"
        :message-text-if-no-items="$t('user.roles.noRolesAvailable')"
        @action-button-handler="addAllRoles"
        displayField="title"
        @select-item="addRole"
        @get-page="getPage"
        :pagination="true"
      />
    </div>
    <div class="column">
      <Panel
        :header-text="$t('user.roles.selectedRoles')"
        :items="user.roles"
        :totalPages="totalPagesForSharedRoles"
        :action-button-text="$t('user.roles.removeAllRoles')"
        panel-theme="danger"
        :message-text-if-no-items="$t('user.roles.noRolesSelected')"
        @action-button-handler="removeAllRoles"
        displayField="title"
        @select-item="removeRole"
      />
    </div>
  </div>
</template>

<script>
import Panel from "@/components/Panel.vue";
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    Panel,
  },
  data() {
    return {
      totalPagesForSharedRoles: 0,
      totalPages: 0,
      totalRecords: 0,
      companyRoles: [],
    };
  },
  computed: {
    availableRoles() {
      return this.companyRoles.filter(
        (roles) => !this.user.roles.find((r) => r.id == roles.id)
      );
    },
    selectedRoles() {
      //filter user projects with company id is same as selectedcompany id
      return this.user.roles.filter(
        (role) => role.companyId == this.selectedCompany.id
      );
    },
    ...mapState({
      user: (state) => state.userForm,
      company: (state) => state.selectedCompany,
      permissions: (state) => state.user.permissions,
    }),
    ...mapGetters(["selectedCompany"]),

    // totalPages() {
    //   return this.selectedUser.totalPages;
    // },
    // totalRecords() {
    //   return this.selectedUser.totalRecords;
    // },
    // totalPagesForSharedProjects() {
    //   return this.selectedUser.totalPagesForSharedProjects;
    // },
  },
  methods: {
    ...mapMutations(["SET_USERFORM_ROLES"]),
    getCompanyRoles() {
      this.$http
        .get("list-company-roles/", {
          params: {
            op: "list_company_roles",
            cid: this.selectedCompany.id,
            page: 1,
            pageSize: 10,
          },
        })
        .then((response) => {
          console.log(response);
          this.companyRoles = response.data;
        });
    },
    addAllRoles() {
      console.log("Add all roles");
    },
    addRole(role) {
      console.log(role);
      let formData = new FormData();
      formData.append("rid", role.id);
      formData.append("uid", this.user.id);
      formData.append("op", "attach_role");

      this.$http
        .post("attach-role/", formData)
        .then(() => {
          this.SET_USERFORM_ROLES([...this.user.roles, role]);
          this.getCompanyRoles();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeRole(role) {
      if (!this.permissions.includes("detach_role")) return;
      let formData = new FormData();
      formData.append("rid", role.id);
      formData.append("uid", this.user.id);
      formData.append("op", "detach_role");

      this.$http
        .post("detach-role/", formData)
        .then(() => {
          this.SET_USERFORM_ROLES(
            this.user.roles.filter((r) => r.id !== role.id)
          );
          this.getCompanyRoles();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeAllRoles() {
      if (!this.permissions.includes("detach_role")) return;
      console.log("Remove all roles");
    },
    getPage() {
      console.log("Get page");
    },
  },
  mounted() {
    this.getCompanyRoles();
  },
};
</script>

<style></style>
