<template>
  <section class="section" style="min-height: 100vh">
    <div class="level">
      <div class="level-left">
        <h1 class="title">{{ $t("project.projectListPageHeader") }}</h1>
      </div>
      <div class="level-right">
        <div class="field is-grouped">
          <p class="control">
            <button
              :title="$t('project.create')"
              class="button is-link"
              @click="openForm(0, '')"
            >
              <span class="icon"><i class="fas fa-plus"></i></span>
            </button>
          </p>
        </div>
      </div>
    </div>
    <table class="table is-fullwidth is-bordered is-striped is-hoverable mt-5">
      <thead>
        <th>{{ $t("project.name") }}</th>
        <th></th>
      </thead>
      <tbody v-if="projects.length && !isLoading">
        <tr v-for="project in projects" :key="project.id">
          <td>{{ project.name }}</td>
          <td>
            <div class="field is-grouped">
              <p class="control">
                <button
                  :title="$t('project.edit')"
                  class="button is-small is-link is-outlined"
                  @click="openForm(project.id, project.name)"
                >
                  <span class="icon is-small">
                    <i class="fas fa-edit"></i>
                  </span>
                </button>
              </p>
              <p class="control">
                <button
                  :title="$t('project.delete')"
                  class="button is-small is-danger is-outlined"
                  @click="remove(project.id)"
                >
                  <span class="icon is-small">
                    <i class="fas fa-trash"></i>
                  </span>
                </button>
              </p>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="isLoading">
        <tr>
          <td></td>
          <td class="has-text-centered" colspan="6">
            {{ $t("project.listLoading") }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td></td>
          <td class="has-text-centered" colspan="6">
            {{ $t("project.noData") }}
          </td>
        </tr>
      </tbody>
      <transition name="fade">
        <div class="modal is-active" v-if="isFormActive">
          <div class="modal-background"></div>
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">
                <slot name="title">
                  {{ projectId ? $t("project.update") : $t("project.create") }}
                </slot>
              </p>
              <button
                class="delete"
                aria-label="close"
                @click="isFormActive = false"
              ></button>
            </header>
            <section class="modal-card-body">
              <div class="field">
                <label class="label">{{ $t("project.name") }}</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="projectName"
                    required
                  />
                </div>
              </div>
            </section>
            <footer class="modal-card-foot">
              <div class="field is-grouped">
                <p class="control">
                  <button
                    :class="[
                      'button',
                      'is-success',
                      isLoading ? 'is-loading' : '',
                    ]"
                    @click="createOrUpdate"
                  >
                    {{
                      projectId ? $t("project.update") : $t("project.create")
                    }}
                  </button>
                </p>
              </div>
            </footer>
          </div>
        </div>
      </transition>
    </table>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ProjectList",
  data() {
    return {
      projectName: "",
      projectId: 0,
      selectedCompanyId: 0,
      isFormActive: false,
      page: 1,
      size: 10,
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      projects: (state) => state.projects,
      companies: (state) => state.companies,
    }),
  },
  methods: {
    ...mapActions(["refreshState"]),
    openForm(id, name) {
      this.projectId = id;
      this.projectName = name;
      this.isFormActive = true;
    },
    closeForm() {
      this.isFormActive = false;
    },
    createOrUpdate() {
      this.isLoading = true;
      let formData = new FormData();
      if (this.projectId == 0) {
        formData.append("op", "create_project");
        formData.append("name", this.projectName);
        formData.append("company_id", this.selectedCompanyId);
        this.$http.post("project", formData);
      }
      if (this.projectId > 0) {
        formData.append("op", "update_project");
        formData.append("id", this.projectId);
        formData.append("name", this.projectName);
        this.$http.put("project", formData);
      }
      setTimeout(() => {
        this.refreshState();
        this.isLoading = false;
        this.closeForm();
      }, "1000");
    },
    remove(id) {
      this.isLoading = true;
      this.$http
        .delete("project", {
          params: {
            op: "delete_project",
            id: id,
          },
        })
        .finally(() => {
          this.refreshState();
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.projectName = "";
    this.projectId = 0;
    this.isFormActive = false;
    this.selectedCompanyId = this.companies[0].id;
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.field.is-horizontal {
  margin-top: 1em;
}
.field.is-horizontal .field-body {
  flex-grow: 2;
}
.modal-card-body {
  min-height: 540px;
  color: #000;
}
</style>
