<template>
  <div class="columns">
    <div class="column" v-if="permissions.includes('attach_camera')">
      <Panel
        :header-text="$t('user.camera_permissions.availableCameras')"
        :items="availableCameras"
        :totalPages="totalPages"
        :totalRecords="totalRecords"
        :action-button-text="$t('user.camera_permissions.addAllCameras')"
        panel-theme="link"
        :message-text-if-no-items="
          $t('user.camera_permissions.noCamerasAvailable')
        "
        @action-button-handler="addAllCameras"
        displayField="longName"
        @select-item="addCamera"
        @get-page="getPage"
        :pagination="true"
      />
    </div>
    <div class="column">
      <Panel
        :header-text="$t('user.camera_permissions.selectedCameras')"
        :items="selectedCameras"
        :totalPages="totalPagesForSharedCameras"
        :action-button-text="$t('user.camera_permissions.removeAllCameras')"
        panel-theme="danger"
        :message-text-if-no-items="
          $t('user.camera_permissions.noCamerasSelected')
        "
        @action-button-handler="removeAllCameras"
        displayField="longName"
        @select-item="removeCamera"
      />
    </div>
  </div>
</template>

<script>
import Panel from "@/components/Panel.vue";
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    Panel,
  },
  data() {
    return {
      totalPagesForSharedCameras: 0,
      totalPages: 0,
      totalRecords: 0,
      projectCameras: [],
    };
  },
  computed: {
    availableCameras() {
      return this.projectCameras.filter(
        (cam) => !this.user.cameras.find((c) => c.id == cam.id)
      );
    },
    selectedCameras() {
      //filter user projects with company id is same as selectedcompany id
      return this.user.cameras.filter(
        (cam) => cam.companyId == this.selectedCompany.id
      );
    },
    ...mapState({
      user: (state) => state.userForm,
      company: (state) => state.selectedCompany,
      permissions: (state) => state.user.permissions,
    }),
    ...mapGetters(["selectedCompany"]),

    // totalPages() {
    //   return this.selectedUser.totalPages;
    // },
    // totalRecords() {
    //   return this.selectedUser.totalRecords;
    // },
    // totalPagesForSharedProjects() {
    //   return this.selectedUser.totalPagesForSharedProjects;
    // },
  },
  methods: {
    ...mapMutations(["SET_USERFORM_CAMERAS"]),
    getProjectCameras() {
      this.$http
        .get("list-all-project-cameras/", {
          params: {
            op: "list_all_project_cameras",
            uid: this.user.id,
            cid: this.selectedCompany.id,
            page: 1,
            pageSize: 10,
          },
        })
        .then((response) => {
          console.log(response);
          this.projectCameras = response.data;
        });
    },
    addAllCameras() {
      console.log("Add all projects");
    },
    addCamera(camera) {
      console.log(camera);
      let formData = new FormData();
      formData.append("cid", camera.id);
      formData.append("uid", this.user.id);
      formData.append("op", "attach_camera");

      this.$http
        .post("attach-camera/", formData)
        .then(() => {
          this.SET_USERFORM_CAMERAS([...this.user.cameras, camera]);
          this.getProjectCameras();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeCamera(camera) {
      if (!this.permissions.includes("detach_camera")) return;
      let formData = new FormData();
      formData.append("cid", camera.id);
      formData.append("uid", this.user.id);
      formData.append("op", "detach_camera");

      this.$http
        .post("detach-camera", formData)
        .then(() => {
          this.SET_USERFORM_CAMERAS(
            this.user.cameras.filter((c) => c.id !== camera.id)
          );
          this.getProjectCameras();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeAllCameras() {
      if (!this.permissions.includes("detach_camera")) return;
      console.log("Remove all cameras");
    },
    getPage() {
      console.log("Get page");
    },
  },
  mounted() {
    this.getProjectCameras();
  },
};
</script>

<style></style>
