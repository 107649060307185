<template>
  <div :class="['modal', isActive ? 'is-active' : '']">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <slot name="title"> {{ $t("bookmark.form.title") }} </slot>
        </p>
        <button
          class="delete"
          aria-label="close"
          @click="SET_MODAL_STATE_BOOKMARK(false)"
        ></button>
      </header>
      <section class="modal-card-body">
        <div class="field">
          <label class="label">{{ $t("bookmark.form.bookmarkTitle") }}</label>
          <div class="control">
            <input
              class="input"
              type="text"
              v-model="currentPhoto.bookmark.title"
              required
            />
          </div>
        </div>
        <div class="control has-text-dark">
          <label class="radio">
            <input
              type="radio"
              name="boomark-type"
              value="personal"
              v-model="currentPhoto.bookmark.type"
            />
            {{ $t("bookmark.form.personal") }}
          </label>
          <label class="radio">
            <input
              type="radio"
              name="bookmark-type"
              value="general"
              v-model="currentPhoto.bookmark.type"
            />
            {{ $t("bookmark.form.general") }}
          </label>
        </div>
      </section>
      <footer class="modal-card-foot">
        <div class="field is-grouped">
          <p class="control">
            <button
              :class="['button', 'is-danger', isLoading ? 'is-loading' : '']"
              :disabled="currentPhoto.bookmark.id ? false : true"
              @click="removeBookmark"
            >
              {{ $t("bookmark.form.remove") }}
            </button>
          </p>
          <p class="control">
            <button
              :class="['button', 'is-success', isLoading ? 'is-loading' : '']"
              @click="saveBookmark"
            >
              {{
                $t(
                  currentPhoto.bookmark.id
                    ? "bookmark.form.update"
                    : "bookmark.form.save"
                )
              }}
            </button>
          </p>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  name: "BookmarkForm",
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      isActive: (state) => state.modals.bookmarkForm.isActive,
      selectedDate: (state) => state.selectedDate,
      currentPhoto: (state) => state.currentPhoto,
    }),
    ...mapGetters(["selectedCamera"]),
  },
  methods: {
    ...mapMutations(["SET_MODAL_STATE_BOOKMARK"]),
    saveBookmark() {
      let formData = new FormData();
      formData.append("cid", this.selectedCamera.id);
      formData.append("ts", this.currentPhoto.timestamp);
      formData.append("bid", this.currentPhoto.bookmark.id);
      formData.append("title", this.currentPhoto.bookmark.title || "Bookmark");
      formData.append("type", this.currentPhoto.bookmark.type);
      let op = this.currentPhoto.bookmark.id ? "update" : "create";

      this.$http
        .post("bookmark/", formData)
        .then((res) => {
          if (op == "create") {
            this.currentPhoto.bookmark.id = res.data.id;
          }
          this.SET_MODAL_STATE_BOOKMARK(false);
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },
    removeBookmark() {
      var formData = new FormData();

      formData.append("cid", this.selectedCamera.id);
      formData.append("bid", this.currentPhoto.bookmark.id);
      formData.append("op", "REMOVE");

      this.$http
        .delete("bookmark", { data: formData })
        .then(() => {
          this.currentPhoto.bookmark = {
            id: null,
            title: "",
            type: "personal",
          };

          this.SET_MODAL_STATE_BOOKMARK(false);
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },
  },
};
</script>
