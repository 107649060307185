var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar-item is-hidden-touch"},[_c('div',{staticClass:"buttons has-addons"},[_c('button',{class:[
        'button',
        'is-small',
        'is-white',
        _vm.currentRoute === 'FileList' ? '' : 'is-outlined',
      ],attrs:{"title":_vm.$t('viewButtonGroup.files')},on:{"click":_vm.openFileListPage}},[_vm._m(0)]),_c('button',{class:[
        'button',
        'is-small',
        'is-white',
        _vm.currentRoute === 'Video' ? '' : 'is-outlined',
      ],attrs:{"title":_vm.$t('viewButtonGroup.videos')},on:{"click":_vm.openVideoPage}},[_vm._m(1)]),_c('button',{class:[
        'button',
        'is-small',
        'is-white',
        _vm.currentRoute === 'Photo' ? '' : 'is-outlined',
      ],attrs:{"title":_vm.$t('viewButtonGroup.timeline')},on:{"click":_vm.openTimelinePage}},[_vm._m(2)]),_c('button',{class:[
        'button',
        'is-small',
        'is-white',
        _vm.currentRoute === 'Comparison' ? '' : 'is-outlined',
      ],attrs:{"title":_vm.$t('viewButtonGroup.compare')},on:{"click":_vm.openComparisonPage}},[_vm._m(3)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-list"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-film"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-images"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-columns"})])
}]

export { render, staticRenderFns }