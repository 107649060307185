<template>
    <div>
      <Navbar />
      <section class="section has-background-white" style="min-height: 100vh">
        <PricingCard /> 
      </section>

    </div>
  </template>  
  <script>
  import Navbar from "@/components/NavBar.vue";
  import PricingCard from "@/components/PricingCard.vue";  
  import { mapMutations } from "vuex";
  export default {
    name: "Subscription",
    data() {
      return {
       
      };
    },
    methods: {
      ...mapMutations([
      "SET_PAYMENTFORM_ISACTIVE",
      "SET_PAYMENTFORM_SELECTED_PACKAGE_ID",
    ]),
    },
    components: {
    Navbar,PricingCard
    },
    mounted(){
    }
  };
  </script>

<style>
.card {
    max-width: 320px;
    padding: 1rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 20px 20px 30px rgba(0, 0, 0, .05);
  }
  
  .title {
    font-weight: 600;
    color: rgb(31 41 55);
  }
  
  .description {
    margin-top: 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: rgb(75 85 99);
  }
  
  .description a {
    --tw-text-opacity: 1;
    color: rgb(59 130 246);
  }

</style>
  
