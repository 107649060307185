var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section",staticStyle:{"min-height":"100vh"}},[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("user.list.title")))])]),_c('div',{staticClass:"level-right"},[_c('div',{staticClass:"field is-grouped"},[_c('p',{staticClass:"control"},[_c('button',{class:['button', 'is-link', _vm.isLoading ? 'is-loading' : ''],attrs:{"title":_vm.$t('user.list.reload')},on:{"click":_vm.loadUsers}},[_vm._m(0)])]),(_vm.permissions.includes('create_user'))?_c('p',{staticClass:"control"},[_c('button',{staticClass:"button is-link",attrs:{"title":_vm.$t('user.list.addUser')},on:{"click":_vm.newUserForm}},[_vm._m(1)])]):_vm._e()])])]),_c('table',{staticClass:"table is-fullwidth is-bordered is-striped is-hoverable mt-5"},[_c('thead',[_c('th',[_vm._v(_vm._s(_vm.$t("user.list.status")))]),_c('th',[_vm._v(_vm._s(_vm.$t("user.fullName")))]),_c('th',[_vm._v(_vm._s(_vm.$t("user.email")))]),_c('th',[_vm._v(_vm._s(_vm.$t("user.createdAt")))]),_c('th',[_vm._v(_vm._s(_vm.$t("user.updatedAt")))])]),(_vm.users.length && !_vm.isLoading)?_c('tbody',_vm._l((_vm.users),function(user){return _c('tr',{key:user.id,on:{"click":function($event){return _vm.loadUserDetails(user.id)}}},[_c('td',[_c('span',{staticClass:"icon"},[_c('i',{class:[
                'fas',
                user.isActive
                  ? 'fa-user has-text-success'
                  : 'fa-user-slash has-text-danger',
              ]})])]),_c('td',[_c('span',[_vm._v(" "+_vm._s(user.fullName)+" ")])]),_c('td',[_vm._v(_vm._s(user.email))]),_c('td',[_vm._v(_vm._s(_vm._f("convertTimestampToDate")(user.createdAt)))]),_c('td',[_vm._v(_vm._s(_vm._f("convertTimestampToDate")(user.updatedAt)))])])}),0):(_vm.isLoading)?_c('tbody',[_c('tr',[_c('td',{staticClass:"has-text-centered",attrs:{"colspan":"6"}},[_vm._v(" "+_vm._s(_vm.$t("user.list.loading"))+" ")])])]):_c('tbody',[_c('tr',[_c('td',{staticClass:"has-text-centered",attrs:{"colspan":"6"}},[_vm._v(" "+_vm._s(_vm.$t("user.list.noUsers"))+" ")])])])]),_c('user-form',{on:{"changed":_vm.loadUsers}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-sync"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-plus"})])
}]

export { render, staticRenderFns }