<template>
  <div
    v-if="isShowCameraSelector && otherProjects.length > 0"
    class="navbar-item has-dropdown is-hoverable"
  >
    <a
      class="navbar-link has-text-weight-semibold"
      href="#"
      @click="expandMenu"
      >{{ selectedProject.name }}</a
    >
    <div class="navbar-dropdown has-background-link">
      <a
        class="navbar-item"
        href="#"
        v-for="project in otherProjects"
        :key="project.id"
        @click="selectProjectHandler(project.id)"
        >{{ project.name }}</a
      >
    </div>
  </div>
  <div
    v-else-if="isShowCameraSelector"
    class="navbar-item has-text-weight-semibold"
  >
    {{ selectedProject.name }}
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters(["otherProjects", "selectedProject", "firstCamera"]),
    currentRoute() {
      return this.$route.name;
    },
    isShowCameraSelector() {
      return (
        this.currentRoute !== "UserList" &&
        this.currentRoute !== "CameraList" &&
        this.currentRoute !== "ProjectList"
      );
    },
  },
  methods: {
    ...mapMutations(["SELECT_PROJECT", "SELECT_CAMERA"]),
    selectProjectHandler(id) {
      this.SELECT_PROJECT(id);
      if (this.firstCamera) {
        this.SELECT_CAMERA(this.firstCamera.id);
      } else {
        console.log("No camera found");
      }

      this.closeMenu();
    },
    expandMenu(event) {
      event.target.closest(".has-dropdown").classList.toggle("is-active");
    },
    closeMenu() {
      document.querySelector(".navbar-menu").classList.remove("is-active");
      const $navbarBurgers = Array.prototype.slice.call(
        document.querySelectorAll(".navbar-burger"),
        0
      );

      if ($navbarBurgers.length > 0) {
        $navbarBurgers.forEach((el) => {
          el.classList.toggle("is-active");
        });
      }
      document
        .querySelectorAll(".navbar-item.has-dropdown.is-active")
        .forEach((el) => {
          el.classList.remove("is-active");
        });
    },
  },
};
</script>
