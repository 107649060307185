<template>
  <div :class="['modal', isActive ? 'is-active' : '']">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t("photoShare.title") }}</p>
        <button class="delete" aria-label="close" @click="close"></button>
      </header>
      <section class="modal-card-body">
        <p class="notification">
          {{ $t("photoShare.title") }}
        </p>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">{{ $t("photoShare.validityPeriod") }}</label>
          </div>
          <div class="field-body">
            <div class="field is-narrow">
              <div class="control">
                <div :class="['select', isLoading ? 'is-loading' : '']">
                  <select v-model="linkDuration" :disabled="!!isLoading">
                    <option value="">{{ $t("photoShare.select") }}</option>
                    <option value="1">1 {{ $t("photoShare.day") }}</option>
                    <option value="2">2 {{ $t("photoShare.day") }}</option>
                    <option value="3">3 {{ $t("photoShare.day") }}</option>
                    <option value="4">4 {{ $t("photoShare.day") }}</option>
                    <option value="5">5 {{ $t("photoShare.day") }}</option>
                    <option value="6">6 {{ $t("photoShare.day") }}</option>
                    <option value="7">7 {{ $t("photoShare.day") }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <center v-if="photoLink">
          <a
            class="button is-success mt-5 mb-5"
            :href="photoLink"
            download
            target="_blank"
            >{{ $t("photoShare.download") }}</a
          >
          <p>{{ $t("photoShare.orShare") }}:</p>

          <div class="field has-addons mt-5 mb-5">
            <p class="control is-expanded">
              <input
                id="natilon-link"
                type="text"
                class="input"
                :value="photoLink"
              />
            </p>
            <p class="control">
              <button class="button is-success" @click="copyLink">
                {{ $t("photoShare.copyLink") }}
              </button>
            </p>
          </div>
          <img v-if="qrlink" :src="qrlink" />
        </center>
      </section>
      <footer class="modal-card-foot"></footer>
    </div>
  </div>
</template>
<style scoped>
.field.is-horizontal {
  margin-top: 1em;
}
.field.is-horizontal .field-body {
  flex-grow: 2;
}
.modal-card-body {
  min-height: 540px;
  color: #000;
}
</style>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      photoLink: null,
      linkDuration: "",
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["selectedCamera"]),
    ...mapState({
      currentPhoto: (state) => state.currentPhoto,
      isActive: (state) => state.modals.photoShare.isActive,
    }),
    qrlink() {
      return this.photoLink + ".qr";
    },
  },
  watch: {
    linkDuration() {
      if (this.linkDuration === "") return;
      this.createLink();
    },
  },
  methods: {
    ...mapMutations(["SET_MODAL_STATE_PHOTO_SHARE"]),
    copyLink: function () {
      let input = document.querySelector("#natilon-link");
      input.select();
      input.setSelectionRange(0, 9999);
      document.execCommand("copy");
      this.$showToastWithType(this.$t('video.share.linkCopied')+"\n\n" + this.photoLink,"info");
    },
    createLink: function () {
      this.photoLink = null;
      this.isLoading = true;
      this.$http
        .get("share-photo/", {
          params: {
            op: "share_photo",
            cid: this.selectedCamera.id,
            t: this.currentPhoto.timestamp,
            d: this.linkDuration,
          },
        })
        .then((res) => {
          this.isLoading = false;
          this.photoLink = res.data.url;
        })
        .catch((err) => {
          this.linkDuration = "";
          this.isLoading = false;
          this.$showToastWithType(err.message,"error");
        });
    },
    close() {
      this.SET_MODAL_STATE_PHOTO_SHARE(false);
      this.photoLink = null;
      this.linkDuration = "";
    },
  },
};
</script>

<style></style>
