<template>
  <div :class="['modal', isActive ? 'is-active' : '']">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{$t('paymentForm.amount')}}: {{preparePaymentForm.payment_amount}} {{preparePaymentForm.currency}}</p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <form ref="paymentForm" @submit.prevent="submitPayment" action="https://www.paytr.com/odeme" method="post">
          <section class="modal-card-body">
        
            <div class="field">
              <label class="label">{{$t('paymentForm.owner')}}</label>
              <div class="control">
                <input class="input" name="cc_owner" v-model="preparePaymentForm.cc_owner" />
                <span v-if="!isCardOwnerValid" class="error">{{$t('paymentForm.ownerInvalid')}}.</span>
              </div>
            </div>

            <div class="field">
              <label class="label">{{$t('paymentForm.number')}}</label>
              <div class="control">
                <input class="input" name="card_number" v-model="preparePaymentForm.card_number" />
                <span v-if="!isCardNumberValid" class="error">{{$t('paymentForm.numberInvalid')}}</span>
              </div>
            </div>

            <div class="field">
              <label class="label">{{$t('paymentForm.expiryMonth')}}</label>
              <div class="control">
                <input class="input" name="expiry_month" v-model="preparePaymentForm.expiry_month" />
                <span v-if="!isExpiryMonthValid" class="error">{{$t('paymentForm.expiryMonthInvalid')}}</span>
              </div>
            </div>

            <div class="field">
              <label class="label">{{$t('paymentForm.expiryYear')}}</label>
              <div class="control">
                <input class="input" name="expiry_year" v-model="preparePaymentForm.expiry_year" />
                <span v-if="!isExpiryYearValid" class="error">{{$t('paymentForm.expiryYearInvalid')}}</span>
              </div>
            </div>

            <div class="field">
              <label class="label">{{$t('paymentForm.cvv')}}</label>
              <div class="control">
                <input class="input" name="cvv" v-model="preparePaymentForm.cvv" />
                <span v-if="!isCvvValid" class="error">{{$t('paymentForm.cvvInvalid')}}</span>
              </div>
            </div>

          </section>

          
        
          <input type="hidden" name="merchant_id" v-model="preparePaymentForm.merchant_id">
          <input type="hidden" name="user_ip" v-model="preparePaymentForm.user_ip">
          <input type="hidden" name="merchant_oid" v-model="preparePaymentForm.merchant_oid">
          <input type="hidden" name="email" v-model="preparePaymentForm.email">
          <input type="hidden" name="payment_type" v-model="preparePaymentForm.payment_type">
          <input type="hidden" name="payment_amount" v-model="preparePaymentForm.payment_amount">
          <input type="hidden" name="currency" v-model="preparePaymentForm.currency">
          <input type="hidden" name="test_mode" v-model="preparePaymentForm.test_mode">
          <input type="hidden" name="non_3d" v-model="preparePaymentForm.non_3d">
          <input type="hidden" name="merchant_ok_url" v-model="preparePaymentForm.merchant_ok_url">
          <input type="hidden" name="merchant_fail_url" v-model="preparePaymentForm.merchant_fail_url">
          <input type="hidden" name="user_name" v-model="preparePaymentForm.user_name">
          <input type="hidden" name="user_address" v-model="preparePaymentForm.user_address">
          <input type="hidden" name="user_phone" v-model="preparePaymentForm.user_phone">
          <input type="hidden" name="user_basket" v-model="preparePaymentForm.user_basket">
          <input type="hidden" name="debug_on" v-model="preparePaymentForm.debug_on">
          <input type="hidden" name="client_lang" v-model="preparePaymentForm.client_lang">
          <input type="hidden" name="paytr_token" v-model="preparePaymentForm.paytr_token">
          <input type="hidden" name="non3d_test_failed" v-model="preparePaymentForm.non3d_test_failed">
          <input type="hidden" name="installment_count" v-model="preparePaymentForm.installment_count">
          <input type="hidden" name="utoken" v-model="preparePaymentForm.utoken">
          <input type="hidden" name="store_card" v-model="preparePaymentForm.store_card">
        
          
          <footer class="modal-card-foot">
            <div class="field is-grouped">
              <p class="control">
                <button type="submit" class="button">Pay</button>
              </p>
            </div>
          </footer>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapMutations } from "vuex";
  export default {
    name: 'PaymentForm',
    computed:{
      ...mapState({
      cameraCount: (state) => state.paymentForm.cameraCount,
      isActive: (state) => state.paymentForm.isActive,
      packageId: (state) => state.paymentForm.selectedPackageId,
      packageType: (state) => state.paymentForm.selectedPackageType,
    }),
    },
    mounted () {
      this.isFormValid = true;
      this.prepareOrderForm();
    },
    data() {
      return {
        isFormValid: true,
        preparePaymentForm: {
          cc_owner:"",
          card_number:"",
          expiry_month:"",
          expiry_year:"",
          cvv:"",
        },
        isCardOwnerValid: true,
        isCardNumberValid: true,
        isExpiryMonthValid: true,
        isExpiryYearValid: true,
        isCvvValid: true
      };
    },
    watch: {
      
    },
    methods: {
      ...mapMutations([
      "SET_PAYMENTFORM_CAMERA_COUNT",  
      "SET_PAYMENTFORM_ISACTIVE",
      "SET_PAYMENTFORM_SELECTED_PACKAGE_ID",
      "SET_PAYMENTFORM_SELECTED_PACKAGE_TYPE",
    ]),
    close(){
      this.SET_PAYMENTFORM_CAMERA_COUNT(0);
      this.SET_PAYMENTFORM_ISACTIVE(false);
      this.SET_PAYMENTFORM_SELECTED_PACKAGE_ID(0);
      this.SET_PAYMENTFORM_SELECTED_PACKAGE_TYPE("");
    },

      submitPayment() { 
        this.isFormValid = true;
        this.isCardOwnerValid = true;
        this.isCardNumberValid = true;
        this.isExpiryMonthValid = true;
        this.isExpiryYearValid = true;
        this.isCvvValid = true;

        if (this.preparePaymentForm.cc_owner == "") {
            this.isCardOwnerValid = false;
            this.isFormValid = false
        }
        if (this.preparePaymentForm.card_number == "") {
            this.isCardNumberValid = false;
            this.isFormValid = false
        }
        if (this.preparePaymentForm.expiry_month == "") {
            this.isExpiryMonthValid = false;
            this.isFormValid = false
        }
        if (this.preparePaymentForm.expiry_year == "") {
            this.isExpiryYearValid = false;
            this.isFormValid = false
        }
        if (this.preparePaymentForm.cvv == "") {
            this.isCvvValid = false;
            this.isFormValid = false;
        }

        if (this.isFormValid) {
          this.close();
          this.$refs.paymentForm.submit();
        }
      
      },
      prepareOrderForm(){
      let formData = new FormData();
        formData.append("camera_count", this.cameraCount);
        formData.append("package_id", this.packageId);
        formData.append("package_type", this.packageType);
        this.$http.post("order",formData)
        .then((res) => {
            this.preparePaymentForm = res.data;
        })
        .catch((e) => {
          console.log(e);
          this.$showToastWithType(this.$i18n.t('package.failed_to_create_payment_page'),"error")
        });
        }
    }
  };
  </script>
