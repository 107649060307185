<template>
  <div class="container is-fullhd">
    <LoginNavbar />
    <div class="hero is-fullheight-with-navbar">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div
              class="column is-full-mobile is-two-thirds-tablet is-one-third-widescreen"
            >
              <transition name="fade" mode="out-in">
                <router-view></router-view>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <LoginFooter />
    </div>
  </div>
</template>

<script>
import LoginNavbar from "@/components/LoginNavbar";
import LoginFooter from "@/components/LoginFooter";

export default {
  components: {
    LoginNavbar,
    LoginFooter,
  },
};
</script>
<style scoped>
.hero {
  background-color: #000068 !important;
}
</style>
