<template>
  <section class="section" style="min-height: 100vh">
    <div class="level">
      <div class="level-left">
        <h1 class="title">{{ $t("user.list.title") }}</h1>
      </div>
      <div class="level-right">
        <div class="field is-grouped">
          <p class="control">
            <button
              :title="$t('user.list.reload')"
              :class="['button', 'is-link', isLoading ? 'is-loading' : '']"
              @click="loadUsers"
            >
              <span class="icon"><i class="fas fa-sync"></i></span>
            </button>
          </p>
          <p class="control" v-if="permissions.includes('create_user')">
            <button
              :title="$t('user.list.addUser')"
              class="button is-link"
              @click="newUserForm"
            >
              <span class="icon"><i class="fas fa-plus"></i></span>
            </button>
          </p>
        </div>
      </div>
    </div>
    <table class="table is-fullwidth is-bordered is-striped is-hoverable mt-5">
      <thead>
        <th>{{ $t("user.list.status") }}</th>
        <th>{{ $t("user.fullName") }}</th>
        <th>{{ $t("user.email") }}</th>
        <th>{{ $t("user.createdAt") }}</th>
        <th>{{ $t("user.updatedAt") }}</th>
      </thead>
      <tbody v-if="users.length && !isLoading">
        <tr
          v-for="user in users"
          :key="user.id"
          @click="loadUserDetails(user.id)"
        >
          <td>
            <span class="icon"
              ><i
                :class="[
                  'fas',
                  user.isActive
                    ? 'fa-user has-text-success'
                    : 'fa-user-slash has-text-danger',
                ]"
              ></i
            ></span>
          </td>
          <td>
            <span>
              {{ user.fullName }}
            </span>
          </td>

          <td>{{ user.email }}</td>
          <td>{{ user.createdAt | convertTimestampToDate }}</td>
          <td>{{ user.updatedAt | convertTimestampToDate }}</td>
        </tr>
      </tbody>
      <tbody v-else-if="isLoading">
        <tr>
          <td class="has-text-centered" colspan="6">
            {{ $t("user.list.loading") }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td class="has-text-centered" colspan="6">
            {{ $t("user.list.noUsers") }}
          </td>
        </tr>
      </tbody>
    </table>
    <user-form @changed="loadUsers" />
  </section>
</template>

<style scoped>
tr {
  cursor: pointer;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(50px);
}
</style>
<script>
import UserForm from "@/components/modals/user/";
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  name: "UserList",
  data() {
    return {
      users: [
        {
          details: {
            id: 0,
            fullName: "",
            email: "",
          },
          permissions: [],
        },
      ],
      isLoading: false,
    };
  },
  components: {
    UserForm,
  },
  computed: {
    ...mapGetters(["selectedCompany"]),
    ...mapState({
      userDetailsModal: (state) => state.modals.userDetails.isActive,
      permissions: (state) => state.user.permissions,
    }),
  },
  watch: {
    selectedCompany: function () {
      this.loadUsers();
    },
  },
  filters: {
    convertTimestampToDate: function (timestamp) {
      let date = new Date(timestamp * 1000);
      let day = ("0" + date.getDate()).slice(-2);
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let hours = ("0" + date.getHours()).slice(-2);
      let minutes = ("0" + date.getMinutes()).slice(-2);
      return `${day}.${month}.${date.getFullYear()}, ${hours}:${minutes}`;
    },
  },
  methods: {
    ...mapMutations([
      "SET_MODAL_STATE_USER_DETAILS",
      "SET_USERFORM_ID",
      "SET_USERFORM_FULLNAME",
      "SET_USERFORM_ISACTIVE",
      "SET_USERFORM_EMAIL",
      "SET_USERFORM_PROJECTS",
      "SET_USERFORM_CAMERAS",
      "SET_USERFORM_ROLES",
    ]),
    loadUserDetails(id) {
      console.log(id);
      this.$http
        .get("user-details/", {
          params: { op: "user_details", cid: this.selectedCompany.id, uid: id },
        })
        .then((res) => {
          let user = res.data;

          this.SET_USERFORM_ID(id);
          this.SET_USERFORM_FULLNAME(user.fullName);
          this.SET_USERFORM_EMAIL(user.email);
          this.SET_USERFORM_ISACTIVE(user.isActive);
          this.SET_USERFORM_PROJECTS(user.projects);
          this.SET_USERFORM_CAMERAS(user.cameras);
          this.SET_USERFORM_ROLES(user.roles);

          this.SET_MODAL_STATE_USER_DETAILS(true);
        });
    },
    loadUsers() {
      this.isLoading = true;
      this.$http
        .get("list-users/", {
          params: {
            op: "list_users",
            cid: this.selectedCompany.id,
          },
        })
        .then((res) => {
          let users = [];
          if (res.data !== null) {
            users = res.data;
          }

          this.users = users;
          setTimeout(() => (this.isLoading = false), 500);
        });
    },
    newUserForm() {
      this.SET_USERFORM_ID(null);
      this.SET_USERFORM_FULLNAME("");
      this.SET_USERFORM_EMAIL("");
      this.SET_USERFORM_ISACTIVE("");

      this.SET_MODAL_STATE_USER_DETAILS(true);
    },
  },
  mounted() {
    this.loadUsers();
  },
};
</script>
