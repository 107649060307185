<template>
  <div class="hero-body is-relative has-background-black">
    <transition name="fade">
      <Loading v-if="isLoading" />
    </transition>
    <p class="buttons" id="toolbarDiv">
      <button id="home" class="button is-black">
        <span class="icon is-small">
          <i class="fas fa-home"></i>
        </span>
      </button>
      <button id="zoom-in" class="button is-black">
        <span class="icon is-small">
          <i class="fas fa-search-plus"></i>
        </span>
      </button>
      <button id="zoom-out" class="button is-black">
        <span class="icon is-small">
          <i class="fas fa-search-minus"></i>
        </span>
      </button>
      <button id="full-page" class="button is-black">
        <span class="icon is-small">
          <i class="fas fa-expand-alt"></i>
        </span>
      </button>
    </p>
    <div id="osd-viewport" :class="[isLoading ? 'blur' : '']"></div>
  </div>
</template>
<style scoped>
.fade-enter-active {
  opacity: 0.7;
  transition: opacity 2s ease;
}
.fade-leave-active {
  opacity: 0;
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.blur {
  filter: blur(10px);
  transition: filter 2s ease;
}
.center {
  z-index: 25;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<script>
import OpenSeadragon from "openseadragon";
import { mapState } from "vuex";
import Loading from "@/components/Loading.vue";

export default {
  name: "PhotoViewport",
  components: {
    Loading,
  },
  data() {
    return {
      viewer: null,
    };
  },
  computed: {
    ...mapState(["currentPhoto", "isLoading"]),
  },
  watch: {
    currentPhoto(photo) {
      this.viewer.open(photo.dziUrl);
    },
    clientHeight: function () {
      this.resizeViewPort();
    },
  },
  methods: {
    resizeViewPort: function () {
      let timeline = 150;
      let navbar = 56;
      let totalHeight = document.documentElement.clientHeight;

      document.querySelector("#osd-viewport").style.height =
        totalHeight - (timeline + navbar + 20) + "px";

      document.querySelector("#osd-viewport").style.width =
        document.documentElement.clientWidth + "px";
    },
  },
  mounted() {
    if (this.viewer !== null) return;
    this.viewer = OpenSeadragon({
      id: "osd-viewport",
      showNavigator: true,
      visibilityRatio: 1,
      constrainDuringPan: true,
      tileSources: "",
      toolbar: "toolbarDiv",
      zoomInButton: "zoom-in",
      zoomOutButton: "zoom-out",
      homeButton: "home",
      fullPageButton: "full-page",
      preserveViewport: "preserveViewport",
    });
    window.addEventListener("resize", () => {
      if (this.$route.name === "Timeline") {
        this.resizeViewPort();
      }
    });

    if (!this.viewer.world.getItemCount()) {
      this.viewer.open(this.currentPhoto.dziUrl);
    }

    this.resizeViewPort();
  },
};
</script>
<style scoped>
#toolbarDiv {
  z-index: 29;
  position: absolute !important;
  top: 0px;
  left: 0px;
  opacity: 0.3;
  padding: 15px;
  transition: opacity 1s;
}

#toolbarDiv:hover {
  opacity: 1;
}

.hero-body {
  padding: 0.5rem;
}
</style>
