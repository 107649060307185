export const SET_SELECTED_DATE = "SET_SELECTED_DATE";
export const SET_SELECTED_SIDE = "SET_SELECTED_SIDE";
export const SET_PHOTO_VIEWPORT_HEIGHT = "SET_PHOTO_VIEWPORT_HEIGHT";
export const SET_DATE_INTERVAL = "SET_DATE_INTERVAL";

export const SET_CURRENT_PHOTO = "SET_CURRENT_PHOTO";
export const SET_COMPARISON_PHOTO = "SET_COMPARISON_PHOTO";

export const SET_IS_AUTHENTICATED = "SET_IS_AUTHENTICATED";
export const SET_USER_ID = "SET_USER_ID";
export const SET_USER_FULLNAME = "SET_USER_FULLNAME";
export const SET_USER_EMAIL = "SET_USER_EMAIL";
export const SET_USER_PERMISSONS = "SET_USER_PERMISSONS";
export const SET_USER_PROJECTS = "SET_USER_PROJECTS";
export const SET_USER_LANGUAGE = "SET_USER_LANGUAGE";
export const SET_USER_TIMEZONE = "SET_USER_TIMEZONE";

export const SET_COMPANIES = "SET_COMPANIES";
export const SET_SELECTED_COMPANY = "SET_SELECTED_COMPANY";

export const SELECT_PROJECT = "SELECT_PROJECT";
export const SET_PROJECTS = "SET_PROJECTS";

export const SET_CAMERAS = "SET_CAMERAS";
export const SELECT_CAMERA = "SELECT_CAMERA";
export const SELECT_FIRST_CAMERA = "SELECT_FIRST_CAMERA";

export const SET_IS_LOADING = "SET_IS_LOADING";

export const SET_MODAL_STATE_SELECT_COMPANY = "SET_MODAL_STATE_SELECT_COMPANY";
export const SET_MODAL_STATE_BULK_PHOTO_DOWNLOAD = "SET_MODAL_STATE_BULK_PHOTO_DOWNLOAD";
export const SET_MODAL_STATE_VIDEO_ORDER = "SET_MODAL_STATE_VIDEO_ORDER";
export const SET_MODAL_STATE_VIDEO_SHARE = "SET_MODAL_STATE_VIDEO_SHARE";
export const SET_MODAL_STATE_VIDEO_PLAYER = "SET_MODAL_STATE_VIDEO_PLAYER";
export const SET_MODAL_STATE_PHOTO_SHARE = "SET_MODAL_STATE_PHOTO_SHARE";
export const SET_MODAL_STATE_USER_DETAILS = "SET_MODAL_STATE_USER_DETAILS";
export const SET_MODAL_STATE_CHANGE_PASSWORD =
  "SET_MODAL_STATE_CHANGE_PASSWORD";
export const SET_MODAL_STATE_BOOKMARK = "SET_MODAL_STATE_BOOKMARK";
export const SET_MODAL_STATE_LANGUAGE_SELECTOR =
  "SET_MODAL_STATE_LANGUAGE_SELECTOR";
export const SET_MODAL_STATE_COMPANY = "SET_MODAL_STATE_COMPANY";

export const SET_USERFORM_ID = "SET_USERFORM_ID";
export const SET_USERFORM_FULLNAME = "SET_USERFORM_FULLNAME";
export const SET_USERFORM_EMAIL = "SET_USERFORM_EMAIL";
export const SET_USERFORM_ISACTIVE = "SET_USERFORM_ISACTIVE";
export const SET_PAYMENTFORM_CAMERA_COUNT = "SET_PAYMENTFORM_CAMERA_COUNT";
export const SET_PAYMENTFORM_ISACTIVE = "SET_PAYMENTFORM_ISACTIVE";
export const SET_PAYMENTFORM_SELECTED_PACKAGE_ID =
  "SET_PAYMENTFORM_SELECTED_PACKAGE_ID";
export const SET_PAYMENTFORM_SELECTED_PACKAGE_TYPE =
  "SET_PAYMENTFORM_SELECTED_PACKAGE_TYPE";
export const SET_USERFORM_ROLES = "SET_USERFORM_ROLES";
export const SET_USERFORM_CAMERAS = "SET_USERFORM_CAMERAS";
export const SET_USERFORM_PROJECTS = "SET_USERFORM_PROJECTS";

export const SET_TOAST_STATE = "SET_TOAST_STATE";
export const SET_TOAST_MESSAGE = "SET_TOAST_MESSAGE";
export const SET_TOAST_TYPE = "SET_TOAST_TYPE";

export const SET_CURRENT_LOGIN_FORM = "SET_CURRENT_LOGIN_FORM";
export const SET_TIMELINEVIEW_PHOTOCOMPONENT = "SET_TIMELINEVIEW_PHOTOMODE";

export const mutations = {
  [SET_CURRENT_PHOTO](state, photo) {
    if (photo.dziUrl == "") {
      /* photo.dziUrl = "https://natilon-dzi.s3.eu-central-1.amazonaws.com/natilon-media-eu/310/dzi/2023-06-14/NTL_20230614_174240.dzi"
      photo.file = "174240"
      photo.timestamp = 1686753760 */
      return;
    }
    state.currentPhoto = photo;
  },
  [SET_IS_LOADING](state, status) {
    state.isLoading = status;
  },
  [SET_SELECTED_DATE](state, date) {
    state.selectedDate = date;
  },
  [SET_DATE_INTERVAL](state, interval) {
    state.currentInterval = interval;
  },
  [SET_IS_AUTHENTICATED](state, status) {
    state.user.isAuthenticated = status;
  },
  [SET_USER_ID](state, id) {
    state.user.id = id;
  },
  [SET_USER_FULLNAME](state, name) {
    state.user.fullName = name;
  },
  [SET_USER_EMAIL](state, email) {
    state.user.email = email;
  },
  [SET_USER_LANGUAGE](state, language) {
    state.user.language = language;
  },
  [SET_USER_TIMEZONE](state, timezone) {
    state.user.timezone = timezone;
  },
  [SET_USER_PERMISSONS](state, permissions) {
    state.user.permissions = permissions;
  },
  [SET_COMPANIES](state, payload) {
    state.companies = payload;
  },
  [SET_SELECTED_COMPANY](state, id) {
    let arr = state.companies.map((c) => {
      c.isSelected = c.id == id;

      return c;
    });
    state.companies = arr;
  },
  [SELECT_PROJECT](state, id) {
    let arr = state.projects.map((p) => {
      if (p.id == id) {
        p.isSelected = true;
      } else {
        p.isSelected = false;
      }
      return p;
    });
    state.projects = arr;
  },
  [SET_PROJECTS](state, payload) {
    state.projects = payload;
  },
  [SELECT_CAMERA](state, id) {
    let arr = state.cameras.map((cam) => {
      if (cam.id == id) {
        cam.isSelected = true;
      } else {
        cam.isSelected = false;
      }
      return cam;
    });

    state.cameras = arr;
  },
  [SELECT_FIRST_CAMERA](state, id) {
    let firstCamera = true;
    let arr = state.cameras.map((cam) => {
      if (id == cam.projectId) {
        cam.isSelected = firstCamera;
        firstCamera = false;
      } else {
        cam.isSelected = false;
      }
      return cam;
    });

    state.cameras = arr;
  },
  [SET_CAMERAS](state, payload) {
    state.cameras = payload;
  },
  [SET_TIMELINEVIEW_PHOTOCOMPONENT](state, payload) {
    state.timelineViewPhotoComponent = payload;
  },
  [SET_MODAL_STATE_SELECT_COMPANY](state, modal_state) {
    state.modals.companySelect.isActive = modal_state;
  },
  [SET_MODAL_STATE_VIDEO_ORDER](state, modal_state) {
    state.modals.videoOrder.isActive = modal_state;
  },
  [SET_MODAL_STATE_BULK_PHOTO_DOWNLOAD](state, modal_state) {
    state.modals.bulkPhotoDownload.isActive = modal_state;
  },
  [SET_MODAL_STATE_VIDEO_SHARE](state, modal_state) {
    state.modals.videoShare.isActive = modal_state;
  },
  [SET_MODAL_STATE_VIDEO_PLAYER](state, modal_state) {
    state.modals.videoPlayer.isActive = modal_state;
  },
  [SET_MODAL_STATE_PHOTO_SHARE](state, modal_state) {
    state.modals.photoShare.isActive = modal_state;
  },
  [SET_MODAL_STATE_USER_DETAILS](state, modal_state) {
    state.modals.userDetails.isActive = modal_state;
  },
  [SET_MODAL_STATE_CHANGE_PASSWORD](state, modal_state) {
    state.modals.changePassword.isActive = modal_state;
  },
  [SET_MODAL_STATE_BOOKMARK](state, modal_state) {
    state.modals.bookmarkForm.isActive = modal_state;
  },
  [SET_MODAL_STATE_LANGUAGE_SELECTOR](state, modal_state) {
    state.modals.languageSelector.isActive = modal_state;
  },
  [SET_USERFORM_ID](state, id) {
    state.userForm.id = id;
  },
  [SET_USERFORM_FULLNAME](state, fullName) {
    state.userForm.fullName = fullName;
  },
  [SET_USERFORM_EMAIL](state, email) {
    state.userForm.email = email;
  },
  [SET_USERFORM_ISACTIVE](state, bool) {
    state.userForm.isActive = bool;
  },
  [SET_USERFORM_ROLES](state, roles) {
    state.userForm.roles = roles;
  },
  [SET_USERFORM_CAMERAS](state, cameras) {
    state.userForm.cameras = cameras;
  },
  [SET_USERFORM_PROJECTS](state, projects) {
    state.userForm.projects = projects;
  },
  [SET_USER_PROJECTS](state, projects) {
    state.user.projects = projects;
  },
  [SET_SELECTED_SIDE](state, side) {
    state.selectedSide = side;
  },
  [SET_COMPARISON_PHOTO](state, photo) {
    if (photo.dziUrl == "") {
      photo = state.currentPhoto;
    }
    state.comparisonPhoto = photo;
  },
  [SET_MODAL_STATE_COMPANY](state, isActive) {
    state.modals.companyForm.isActive = isActive;
  },
  [SET_TOAST_STATE](state, isActive) {
    state.toast.isActive = isActive;
  },
  [SET_TOAST_MESSAGE](state, message) {
    state.toast.message = message;
  },
  [SET_TOAST_TYPE](state, type) {
    state.toast.type = type;
  },
  [SET_PAYMENTFORM_CAMERA_COUNT](state, count) {
    state.paymentForm.cameraCount = count;
  },
  [SET_CURRENT_LOGIN_FORM](state, form) {
    state.login.currentForm = form;
  },
  [SET_PAYMENTFORM_ISACTIVE](state, bool) {
    state.paymentForm.isActive = bool;
  },
  [SET_PAYMENTFORM_SELECTED_PACKAGE_ID](state, id) {
    state.paymentForm.selectedPackageId = id;
  },
  [SET_PAYMENTFORM_SELECTED_PACKAGE_TYPE](state, selectedPackageType) {
    state.paymentForm.selectedPackageType = selectedPackageType;
  },
};
