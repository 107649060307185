<template>
    <div class="video">
      
      <section class="section has-background-white" style="min-height: 100vh">
        <h1 class="title">{{ $t("bulkPhotoDownloads.list.title") }}</h1>
        <div class="field is-grouped">
          <p class="control">
            <button
              title="Yenile"
              :class="['button', 'is-link', isLoading ? 'is-loading' : '']"
              @click="loadBulkPhotoDownloads"
            >
              <span class="icon"><i class="fas fa-sync"></i></span>
            </button>
          </p>
          <p class="control">
            <button
              :title="$t('bulkPhotoDownloads.list.create')"
              class="button is-link"
              @click="openVideoModal"
            >
              <span class="icon"><i class="fas fa-plus"></i></span>
            </button>
          </p>
        </div>
        <hr />
        <table class="table is-fullwidth is-bordered is-striped is-hoverable">
          <thead>
            <th>{{ $t("bulkPhotoDownloads.list.requestDate") }}</th>
            <th>{{ $t("bulkPhotoDownloads.list.startDate") }}</th>
            <th>{{ $t("bulkPhotoDownloads.list.endDate") }}</th>
            <th>{{ $t("bulkPhotoDownloads.list.statusTitle") }}</th>
            <th>{{ $t("bulkPhotoDownloads.list.downloadedCount") }}</th>
            <th>{{ $t("bulkPhotoDownloads.list.link") }}</th>
          </thead>
          <tbody v-if="bulkPhotoDownloads.length && !isLoading">
            <tr v-for="bulkPhotoDownload in bulkPhotoDownloads" :key="bulkPhotoDownload.id">
              <td>{{ bulkPhotoDownload.created_at}}</td>
              <td>{{ bulkPhotoDownload.start_date}}</td>
              <td>{{ bulkPhotoDownload.end_date}}</td>
              <td v-if="bulkPhotoDownload.status=='WAITING'">{{ $t("bulkPhotoDownloads.list.status.WAITING") }}</td>
              <td v-if="bulkPhotoDownload.status=='PROCESSING'">{{ $t("bulkPhotoDownloads.list.status.PROCESSING") }}</td>
              <td v-if="bulkPhotoDownload.status=='COMPLETED'">{{ $t("bulkPhotoDownloads.list.status.COMPLETED") }}</td>
              <td>{{ bulkPhotoDownload.user_download_count}}</td>
              <td><a v-if="bulkPhotoDownload.user_download_count<3 && bulkPhotoDownload.is_zip_file_deleted === false && bulkPhotoDownload.status === 'COMPLETED'" :href="bulkPhotoDownload.link" target="_blank">{{ $t("bulkPhotoDownloads.list.download") }}</a></td>
            </tr>
          </tbody>
        </table>
      </section>
      <BulkPhotoDownloadRequest/>
    </div>
  </template>
  <style scoped></style>
  
  <script>
  
  import {mapMutations, mapState } from "vuex";
  import { convertDate } from "../plugins/date.js";
  import BulkPhotoDownloadRequest from "../components/BulkPhotoDownloadRequest.vue";
  
  export default {
    name: "BulkPhotoDownload",
    data() {
      return {
        bulkPhotoDownloads: [],
        isLoading:false,
      };
    },
    computed: {
      ...mapState({
      isModalActive: (state) => state.modals.bulkPhotoDownload.isActive,
    }),
    },
    components: {
      BulkPhotoDownloadRequest
    },
    watch: {
      isModalActive(newValue) {
        if (newValue == false) {
          this.loadBulkPhotoDownloads();
        }
      }
    },
    methods: {
      ...mapMutations([
        "SET_MODAL_STATE_BULK_PHOTO_DOWNLOAD"
      ]),
      
      openVideoModal() {
        this.SET_MODAL_STATE_BULK_PHOTO_DOWNLOAD(true);
      },
      loadBulkPhotoDownloads() {
        this.isLoading = true;
        this.$http
          .get("bulk-photo-download?page=1&size=10", {
          })
          .then((res) => {
            if (res.data !== null) {
              this.bulkPhotoDownloads = res.data.data;
              for (var i=0;i<this.bulkPhotoDownloads.length;i++) {
                this.bulkPhotoDownloads[i].created_at = convertDate(this.bulkPhotoDownloads[i].created_at);
                this.bulkPhotoDownloads[i].start_date = convertDate(this.bulkPhotoDownloads[i].start_date);
                this.bulkPhotoDownloads[i].end_date = convertDate(this.bulkPhotoDownloads[i].end_date);
              }
            }  
            this.isLoading = false;
          });
      },
    },
    mounted() {
      this.loadBulkPhotoDownloads();
    },
  };
  </script>
  