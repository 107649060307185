<template>
  <div>
    <h1 class="title has-text-white">{{ $t("forgot_password.title") }}</h1>

    <div class="box" action="">
      <p
        class="notification"
        v-if="status === ''"
        v-html="$i18n.t('forgot_password.description')"
      ></p>
      <p
        class="notification is-warning"
        v-else-if="status === 'mail_error'"
        v-html="$i18n.t('forgot_password.mail_error')"
      ></p>
      <p
        class="notification is-danger"
        v-else-if="status === 'account_error'"
        v-html="$i18n.t('forgot_password.account_error')"
      ></p>
      <p
        class="notification is-success"
        v-else-if="status === 'success'"
        v-html="$i18n.t('forgot_password.success')"
      ></p>
      <div v-if="!status">
        <div class="field">
          <p class="control has-icons-left">
            <input class="input" type="email" v-model="username" />
            <span class="icon is-small is-left">
              <i class="fas fa-envelope"></i>
            </span>
          </p>
        </div>
        <div class="level">
          <p class="control level-left">
            <a
              class="button is-text is-small"
              href="#"
              @click="$router.push('/')"
            >
              {{ $t("forgot_password.back_to_login") }}
            </a>
          </p>
          <p class="control level-right">
            <button
              :class="['button', 'is-warning', isLoading ? 'is-loading' : '']"
              @click="reset_handler"
            >
              {{ $t("forgot_password.reset") }}
            </button>
          </p>
        </div>
      </div>
      <div class="has-text-centered" v-else>
        <p class="control">
          <a class="button is-text" href="#" @click="status = ''">
            {{ $t("general.back") }}
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapActions } from "vuex";
// import router from '@/router/';
//
export default {
  data() {
    return {
      username: process.env.VUE_APP_DEMO_USERNAME || "",
      status: "",
      isLoading: false,
    };
  },
  name: "ForgotPassword",
  methods: {
    ...mapActions(["backToLogin", "resetPassword"]),
    reset_handler(e) {
      this.isLoading = true;
      e.preventDefault();
      this.resetPassword(this.username)
        .then((response) => {
          let data = response.data;
          if (data.mailSent && data.passwordUpdated) {
            this.status = "success";
          } else {
            this.status = "mail_error";
          }
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e, "Fail!");
          this.status = "account_error";
          // if (e.response.status === 404) {
          // }
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(10px);
}
</style>
