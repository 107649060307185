<template>
  <div :class="['modal', isActive ? 'is-active' : '']">
    <div class="modal-background" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t("selectCompany.title") }}</p>
        <button
          class="delete"
          aria-label="close"
          @click="SET_MODAL_STATE_SELECT_COMPANY(false)"
        />
      </header>
      <section class="modal-card-body">
        <Panel
          :header-text="$t('selectCompany.pleaseSelectCompany')"
          :items="otherCompanies"
          panel-theme="success"
          :message-text-if-no-items="$t('selectCompany.noOtherCompanies')"
          displayField="name"
          @select-item="selectCompany"
        />
      </section>
      <footer class="modal-card-foot" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import Panel from "@/components/Panel.vue";

export default {
  components: {
    Panel,
  },
  computed: {
    ...mapState({
      isActive: (state) => state.modals.companySelect.isActive,
      company: (state) => state.selectedCompany,
      companies: (state) => state.companies,
    }),
    ...mapGetters([
      "selectedCompany",
      "otherCompanies",
      "projectCameras",
      "companyProjects",
    ]),
  },
  methods: {
    ...mapMutations([
      "SET_SELECTED_COMPANY",
      "SELECT_PROJECT",
      "SELECT_CAMERA",
      "SET_MODAL_STATE_SELECT_COMPANY",
    ]),
    selectCompany(company) {
      let currentCompany = this.selectedCompany;
      this.SET_SELECTED_COMPANY(company.id);
      if (!this.companyProjects.length) {
        this.$showToastWithType(this.$i18n.t("selectCompany.noProject"),"error");
        this.SET_SELECTED_COMPANY(currentCompany.id);
        return;
      }

      this.SELECT_PROJECT(this.companyProjects[0].id);
      this.SELECT_CAMERA(this.projectCameras[0].id);
      this.SET_MODAL_STATE_SELECT_COMPANY(false);
    },
  },
};
</script>

<style></style>
