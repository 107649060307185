<template>
  <section class="section" style="min-height: 100vh">
    <div class="level">
      <div class="level-left">
        <h1 class="title">{{ $t("video.list.title") }}</h1>
      </div>
      <div class="level-right">
        <div class="field is-grouped">
          <p class="control">
            <button
              title="Yenile"
              :class="['button', 'is-link', isLoading ? 'is-loading' : '']"
              @click="loadVideos"
            >
              <span class="icon"><i class="fas fa-sync"></i></span>
            </button>
          </p>
          <p class="control">
            <button
              :title="$t('video.list.create')"
              class="button is-link"
              @click="openVideoModal"
            >
              <span class="icon"><i class="fas fa-plus"></i></span>
            </button>
          </p>
        </div>
      </div>
    </div>
    <table class="table is-fullwidth is-bordered is-striped is-hoverable mt-5">
      <thead>
        <th>ID</th>
        <th>{{ $t("video.list.orderDate") }}</th>
        <th>{{ $t("video.list.statusTitle") }}</th>
        <th>{{ $t("video.list.startDate") }}</th>
        <th>{{ $t("video.list.endDate") }}</th>
        <th>{{ $t("video.list.timeWindow") }}</th>
        <th>{{ $t("video.list.resolution") }}</th>
        <th>{{ $t("video.list.operations") }}</th>
      </thead>
      <tbody v-if="videos.length && !isLoading">
        <tr v-for="video in videos" :key="video.id">
          <td>{{ videos.indexOf(video) + 1 }}</td>
          <td>{{ video.order_date | format_date }}</td>
          <td>{{ $t("video.list.status." + video.status) }}</td>
          <td>{{ video.start_date | format_date }}</td>
          <td>{{ video.end_date | format_date }}</td>
          <td>{{ video.start_hour }}:00 - {{ video.end_hour }}:00</td>
          <td>{{ video.resolution | resolution }}</td>
          <td>
            <div class="field is-grouped">
              <p v-if="video.status === 'completed'" class="control">
                <button
                  :title="$t('video.list.play')"
                  class="button is-small is-link is-outlined"
                  @click="play(video.id)"
                >
                  <span class="icon is-small">
                    <i class="fas fa-play"></i>
                  </span>
                </button>
              </p>
              <p v-if="video.status === 'completed'" class="control">
                <button
                  :title="$t('video.list.share')"
                  class="button is-small is-link is-outlined"
                  @click="download(video.id)"
                >
                  <span class="icon is-small">
                    <i class="fas fa-download"></i>
                  </span>
                </button>
              </p>
              <p v-if="video.status === 'completed'" class="control">
                <button
                  :title="$t('video.list.share')"
                  class="button is-small is-link is-outlined"
                  @click="share(video.id)"
                >
                  <span class="icon is-small">
                    <i class="fas fa-external-link-alt"></i>
                  </span>
                </button>
              </p>
              <p v-if="video.status === 'completed'" class="control">
                <button
                  :title="$t('video.list.delete')"
                  class="button is-small is-danger is-outlined"
                  @click="remove(video.id)"
                >
                  <span class="icon is-small">
                    <i class="fas fa-trash"></i>
                  </span>
                </button>
              </p>
              <p v-if="video.status === 'queued'" class="control">
                <button
                  :title="$t('video.list.cancel')"
                  class="button is-small is-danger is-outlined"
                  @click="cancel(video.id)"
                >
                  <span class="icon is-small">
                    <i class="fas fa-times"></i>
                  </span>
                </button>
              </p>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="isLoading">
        <tr>
          <td></td>
          <td class="has-text-centered" colspan="6">
            {{ $t("video.list.loading") }}
          </td>
          <td></td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td></td>
          <td class="has-text-centered" colspan="6">
            {{ $t("video.list.noData") }}
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <video-form />
    <video-player :src="videoLink" />
    <video-share :src="videoId" />
  </section>
</template>

<script>
import VideoForm from "@/components/VideoForm.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import VideoShare from "@/components/VideoShare.vue";
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  name: "Video",
  data() {
    return {
      videos: [],
      isLoading: false,
      videoLink: null,
      videoId: null,
    };
  },
  components: {
    VideoForm,
    VideoPlayer,
    VideoShare,
  },
  computed: {
    ...mapGetters(["selectedCamera"]),
    ...mapState({
      videoOrderModal: (state) => state.modals.videoOrder,
      videoShareModal: (state) => state.modals.videoShare,
      user: (state) => state.user,
    }),
  },
  watch: {
    selectedCamera: function () {
      this.loadVideos();
    },
    videoOrderModal: function () {
      if (!this.videoOrderModal.isActive) {
        this.loadVideos();
      }
    },
  },
  filters: {
    resolution: function (res) {
      let resolutions = {
        hd: "720p",
        full_hd: "1080p",
        "4k": "4K",
        "8k": "8K",
        default: res,
      };

      return resolutions[res] || resolutions["default"];
    },
    // status: function (sta) {
    //   let status = {
    //     queued: "video.list.status.queued",
    //     in_progress: "video.list.status.inProgress",
    //     completed: "video.list.status.completed",
    //     default: "video.list.status.waiting",
    //   };
    //   return status[sta] || status["default"];
    // },
    format_date: function (date) {
      return new Intl.DateTimeFormat("tr").format(Date.parse(date));
    },
  },
  methods: {
    ...mapMutations([
      "SET_MODAL_STATE_VIDEO_ORDER",
      "SET_MODAL_STATE_VIDEO_PLAYER",
      "SET_MODAL_STATE_VIDEO_SHARE",
    ]),
    play(id) {
      if (!this.user.permissions.includes("play_video")) {
        this.$showToastWithType(
          this.$i18n.t("message.error.noFunctionPermission"),
          "error"
        );
        return;
      }
      let playButton = event.target.closest("button");
      playButton.classList.add("is-loading");

      this.$http
        .get("play-video/", {
          params: {
            cid: this.selectedCamera.id,
            op: "play_video",
            id,
          },
        })
        .then((res) => {
          this.videoLink = res.data.url;
          this.SET_MODAL_STATE_VIDEO_PLAYER(true);
          playButton.classList.remove("is-loading");
        });
    },
    share(id) {
      if (!this.user.permissions.includes(this.videoShareModal.permission)) {
        this.$showToastWithType(
          this.$i18n.t("message.error.noFunctionPermission"),
          "error"
        );
        return;
      }
      this.videoId = id;
      this.SET_MODAL_STATE_VIDEO_SHARE(true);
    },
    download(id) {
      if (!this.user.permissions.includes("download_video")) {
        this.$showToastWithType(
          this.$i18n.t("message.error.noFunctionPermission"),
          "error"
        );
        return;
      }
      this.$http
        .get("download-video/", {
          params: {
            cid: this.selectedCamera.id,
            op: "download_video",
            id,
          },
        })
        .then((res) => {
          window.open(res.data.url, "_blank");
        });
    },
    remove(id) {
      if (!this.user.permissions.includes("delete_video")) {
        this.$showToastWithType(
          this.$i18n.t("message.error.noFunctionPermission"),
          "error"
        );
        return;
      }
      if (!confirm(this.$i18n.t("video.message.confirmDelete"))) return;

      var formData = new FormData();
      formData.append("op", "delete_video");
      formData.append("id", id);
      formData.append("cid", this.selectedCamera.id);

      this.$http
        .post("delete-video/", formData)
        .then((res) => {
          if (res.status === 204) {
            this.loadVideos();
          }
        })
        .catch(() => {
          this.$showToastWithType(
            this.$i18n.t("video.message.deleteError"),
            "error"
          );
        });
    },
    cancel(id) {
      if (!this.user.permissions.includes("cancel_video")) {
        this.$showToastWithType(
          this.$i18n.t("message.error.noFunctionPermission"),
          "error"
        );
        return;
      }
      if (!confirm(this.$i18n.t("video.message.confirmCancelOrder"))) return;
      //"Bu iş emri listeden silinecektir. Emin misiniz?"
      var formData = new FormData();
      formData.append("op", "cancel_video");
      formData.append("id", id);
      formData.append("cid", this.selectedCamera.id);

      this.$http.post("cancel-video/", formData).then((res) => {
        if (res.status === 204) {
          this.loadVideos();
        } else if (res.status === 404) {
          this.$showToastWithType(
            this.$i18n.t("message.error.contactSupport"),
            "error"
          );
        }
      });
    },
    openVideoModal() {
      if (!this.user.permissions.includes("order_video")) {
        this.$showToastWithType(
          this.$i18n.t("message.error.noFunctionPermission"),
          "error"
        );
        return;
      }
      this.SET_MODAL_STATE_VIDEO_ORDER(true);
    },
    loadVideos() {
      this.isLoading = true;
      if (!this.selectedCamera) {
        this.isLoading = false;
        return;
      }
      this.$http
        .get("list-videos/", {
          params: {
            op: "list_videos",
            cid: this.selectedCamera.id,
          },
        })
        .then((res) => {
          let videos = [];
          if (res.data !== null) {
            videos = res.data;
          }

          this.videos = videos;
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.loadVideos();
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(10px);
}
</style>
