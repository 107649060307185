<template>
  <div :class="['modal', isActive ? 'is-active' : '']">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t("video.player.title") }}</p>
        <button
          class="delete"
          aria-label="close"
          @click="SET_MODAL_STATE_VIDEO_PLAYER(false)"
        ></button>
      </header>
      <section class="modal-card-body">
        <video controls :src="src"></video>
      </section>
      <footer class="modal-card-foot"></footer>
    </div>
  </div>
</template>
<style scoped>
.modal-card-body {
  min-height: 540px;
}
video {
  width: 100%;
}
</style>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  props: ["src"],
  data() {
    return {};
  },
  computed: {
    ...mapState({
      isActive: (state) => state.modals.videoPlayer.isActive,
      // companies: (state) => state.companies,
    }),
  },
  methods: {
    ...mapMutations([
      // "SET_SELECTED_COMPANY",
      // "SELECT_PROJECT",
      // "SELECT_CAMERA",
      "SET_MODAL_STATE_VIDEO_PLAYER",
    ]),
  },
};
</script>

<style></style>
