<template>
  <div class="hero-body is-relative has-background-black">
    <transition name="fade">
      <div class="is-overlay has-background-black center" v-if="isLoading">
        <div class="modal-content">
          <svg
            width="20%"
            style="margin: auto 40%"
            version="1.1"
            id="L7"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enable-background="new 0 0 100 100"
            xml:space="preserve"
          >
            <path
              fill="#3464E0"
              d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
      c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="4s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
            <path
              fill="#fff"
              d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
      c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="-360 50 50"
                repeatCount="indefinite"
              />
            </path>
            <path
              fill="#00AFFB"
              d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
      L82,35.7z"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="2s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      </div>
    </transition>
    <p class="buttons" id="toolbarDiv">
      <button id="home" class="button is-black">
        <span class="icon is-small">
          <i class="fas fa-home"></i>
        </span>
      </button>
      <button id="full-page" class="button is-black">
        <span class="icon is-small">
          <i class="fas fa-expand-alt"></i>
        </span>
      </button>
    </p>
    <div
      id="comparison-viewport"
      :class="['m-image-slider__viewer', isLoading ? 'blur' : '']"
      class=""
    >
      <div v-show="sliderHandleVisibility" class="m-image-slider__handle">
        <div class="m-image-slider__handle__divider"></div>
        <div class="m-image-slider__handle__circle">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
          >
            <path fill="#FFF" d="M13 21l-5-5 5-5m6 0l5 5-5 5" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped></style>
<script>
import OpenSeadragon from "openseadragon";
import { mapState } from "vuex";

export default {
  name: "ComparisonViewport",
  // props: ["selectedPhotoURL", "size"],
  data() {
    return {
      viewer: null,
      viewerElement: null,
      handleElement: null,
      middle: null,
      leftRec: null,
      rightRec: null,
      leftImage: null,
      rightImage: null,
      imageData: {
        leftImage: "",
        rightImage: "",
        isSliderZoomed: true,
      },
      oldSpringX: 0.5,
      containerWidth: 0,
      dragWidth: 0,
      minLeft: 0,
      maxLeft: 0,
      xPosition: 0,
    };
  },
  computed: {
    ...mapState([
      "currentPhoto",
      "isLoading",
      "selectedSide",
      "comparisonPhoto",
    ]),
    sliderHandleVisibility() {
      return this.comparisonPhoto.timestamp && this.currentPhoto.timestamp;
    },
  },
  watch: {
    clientHeight: function () {
      this.resizeViewPort();
    },
  },
  methods: {
    testRemove() {
      this.viewer.world.removeItem(this.leftImage);
    },
    resizeViewPort: function () {
      let timeline = 150;
      let navbar = 56;
      let totalHeight = document.documentElement.clientHeight;

      document.querySelector("#comparison-viewport").style.height =
        totalHeight - (timeline + navbar + 20) + "px";

      document.querySelector("#comparison-viewport").style.width =
        document.documentElement.clientWidth + "px";
    },
    imagesLoaded: function () {
      if (this.leftImage && this.rightImage) {
        this.leftRect.height = this.leftImage.getContentSize().y;
        this.rightRect.height = this.rightImage.getContentSize().y;
        this.imagesClip();
        this.initClip();
      }
    },

    initViewer: function () {
      this.viewerElement = document.getElementById("comparison-viewport");
      this.handleElement = document.querySelector(".m-image-slider__handle");

      this.leftRect = new OpenSeadragon.Rect(0, 0, 0, 0);
      this.rightRect = new OpenSeadragon.Rect(0, 0, 0, 0);

      this.viewer = OpenSeadragon({
        element: this.viewerElement,
        // xmlns: "http://schemas.microsoft.com/deepzoom/2008",
        // prefixUrl: "//openseadragon.github.io/openseadragon/images/",
        zoomPerClick: this.imageData.isSliderZoomable ? 1.3 : 1, // 2.0
        showZoomControl: this.imageData.isSliderZoomable,
        showFullPageControl: true,
        showRotationControl: false,
        showSequenceControl: false,
        toolbar: "toolbarDiv",
        homeButton: "home",
        fullPageButton: "full-page",
        // preserveViewport: "preserveViewport",
        visibilityRatio: 1,
        constrainDuringPan: true,
        showNavigator: true,
      });

      this.middle = new OpenSeadragon.Point(
        this.viewerElement.clientWidth / 2,
        this.viewerElement.clientHeight / 2
      );

      this.viewer.addHandler("animation-start", this.imagesClip);
      this.viewer.addHandler("animation", this.imagesClipAggressive);
      this.viewer.world.removeAll();

      this.viewer.addTiledImage({
        tileSource: this.currentPhoto.dziUrl,
        success: (event) => {
          this.rightImage = event.item;
          this.imagesLoaded();
        },
      });
      if (this.comparisonPhoto.dziUrl) {
        this.viewer.addTiledImage({
          tileSource: this.comparisonPhoto.dziUrl,
          success: (event) => {
            this.leftImage = event.item;
            this.imagesLoaded();
          },
        });
      }
    },
    imagesClipAggressive: function () {
      if (!this.rightImage || !this.leftImage) {
        window.setTimeout(this.imagesClipAggressive, 200);
        return;
      }
      let newSpringX, deltaSpringX;

      newSpringX = this.viewer.viewport.centerSpringX.current.value;
      deltaSpringX = newSpringX - this.oldSpringX;
      this.oldSpringX = newSpringX;

      let fixedMiddle = this.viewer.viewport.viewerElementToViewportCoordinates(
        this.middle
      );
      fixedMiddle.x += deltaSpringX;

      let rox = this.rightImage.viewportToImageCoordinates(fixedMiddle).x;
      let lox = this.leftImage.viewportToImageCoordinates(fixedMiddle).x;

      this.imagesClipShared(rox, lox);
    },

    imagesClip: function () {
      if (!this.rightImage || !this.leftImage) {
        window.setTimeout(this.imagesClip, 200);
        return;
      }

      let rox = this.rightImage.viewerElementToImageCoordinates(this.middle).x;
      let lox = this.leftImage.viewerElementToImageCoordinates(this.middle).x;

      this.imagesClipShared(rox, lox);
    },

    imagesClipShared: function (rox, lox) {
      this.rightRect.x = rox;
      this.rightRect.width = this.rightImage.getContentSize().x - rox;

      this.leftRect.width = lox;

      this.leftImage.setClip(this.leftRect);
      this.rightImage.setClip(this.rightRect);
    },
    updateMiddle: function (offset) {
      this.middle.x = offset;
    },

    updateContainerDimensions: function () {
      this.containerWidth = this.viewerElement.offsetWidth;
      this.containerOffset =
        this.viewerElement.getBoundingClientRect().left + window.scrollX;
      this.minLeft = this.containerOffset + 10;
      this.maxLeft =
        this.containerOffset + this.containerWidth - this.dragWidth - 10;

      // Spoof the mouse events
      let offset =
        this.handleElement.getBoundingClientRect().left +
        window.scrollX +
        this.dragWidth / 2;
      let event;

      // Bind the drag event
      event = new Event("mousedown");
      event.pageX = offset;

      this.handleElement.dispatchEvent(event);

      // Execute the drag event
      event = new Event("mousemove");
      event.pageX = offset;

      this.viewerElement.dispatchEvent(event);

      // Unbind the drag event
      this.handleElement.dispatchEvent(new Event("mouseup"));
    },
    handleTouchStart: function (event) {
      this.handleStartShared(event.targetTouches[0].pageX);
    },

    handleMouseDown: function (event) {
      this.handleStartShared(event.pageX);
    },
    trackDragShared: function (pageX) {
      let leftValue = pageX + this.xPosition - this.dragWidth;

      // Constrain the draggable element to move inside its container
      leftValue = Math.max(leftValue, this.minLeft);
      leftValue = Math.min(leftValue, this.maxLeft);

      let widthPixel = leftValue + this.dragWidth / 2 - this.containerOffset;
      let widthFraction = widthPixel / this.containerWidth;
      let widthPercent = widthFraction * 100 + "%";

      this.handleElement.style.left = widthPercent;

      this.updateMiddle(widthPixel);
      this.imagesClip();
    },
    trackDragMouse: function (event) {
      this.trackDragShared(event.pageX);
    },

    trackDragTouch: function (event) {
      this.trackDragShared(event.changedTouches[0].pageX);
    },
    unbindTrackDrag: function () {
      this.viewerElement.removeEventListener("mousemove", this.trackDragMouse);
      this.viewerElement.removeEventListener("touchmove", this.trackDragTouch);
    },
    handleStartShared: function (pageX) {
      this.xPosition =
        this.handleElement.getBoundingClientRect().left +
        window.scrollX +
        this.dragWidth -
        pageX;

      this.viewerElement.addEventListener("mousemove", this.trackDragMouse);
      this.viewerElement.addEventListener("touchmove", this.trackDragTouch);

      document.addEventListener("mouseup", this.unbindTrackDrag, {
        once: true,
      });
      document.addEventListener("touchend", this.unbindTrackDrag, {
        once: true,
      });

      event.preventDefault();
    },
    initClip: function () {
      this.updateContainerDimensions();

      window.addEventListener("resize", this.updateContainerDimensions);

      this.handleElement.addEventListener("mousedown", this.handleMouseDown);
      this.handleElement.addEventListener("touchstart", this.handleTouchStart);
    },
  },
  mounted() {
    if (this.viewer !== null) return;

    window.addEventListener("resize", () => {
      if (this.$route.name === "Timeline") {
        this.resizeViewPort();
      }
    });

    this.resizeViewPort();

    this.initViewer();

    this.$root.$on("timeline-item-selected", (side) => {
      let tileSource =
        side === "left"
          ? this.comparisonPhoto.dziUrl
          : this.currentPhoto.dziUrl;
      if (this[side + "Image"] !== null) {
        this.viewer.world.removeItem(this[side + "Image"]);
      }
      this.viewer.addTiledImage({
        tileSource,
        success: (event) => {
          this[side + "Image"] = event.item;
          this.imagesLoaded();
        },
        // error: (event) => {
        // },
      });
    });
  },
};
</script>

<style scoped>
#toolbarDiv {
  z-index: 29;
  position: absolute !important;
  top: 0px;
  left: 0px;
  opacity: 0.3;
  padding: 15px;
  transition: opacity 1s;
}

#toolbarDiv:hover {
  opacity: 1;
}

.hero-body {
  padding: 0.5rem;
}
.fade-enter-active {
  opacity: 0.7;
  transition: opacity 2s ease;
}
.fade-leave-active {
  opacity: 0;
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.blur {
  filter: blur(10px);
  transition: filter 2s ease;
}
.center {
  z-index: 25;
  display: flex;
  justify-content: center;
  align-items: center;
}
.m-image-slider__viewer {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.m-image-slider__handle {
  position: absolute;

  /* center the element */
  left: 50%;
  top: 50%;
}

/* overflow is hidden by .m-image-slider__viewer */
.m-image-slider__handle__divider {
  position: absolute;
  left: 50%;
  top: 50%;
  background-color: white;
  width: 3px;
  height: 150vh;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.m-image-slider__handle__circle {
  position: absolute;

  height: 45px;
  width: 45px;

  /* center the element */
  left: 50%;
  top: 50%;

  margin-left: -23px;
  margin-top: -23px;

  border-radius: 50%;

  background: gray !important;
  cursor: move;

  box-shadow: 0 0 0 6px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 0, 0, 0.6),
    inset 0 1px 0 rgba(255, 255, 255, 0.3);
  z-index: 3;
}

.m-image-slider__handle svg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hero-body {
  padding: 0.5rem;
}
</style>
