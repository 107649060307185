<template>
    <div class="modal is-active" key="camera-modal">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ camera.id ? $t("camera.update") : $t("camera.create") }}
          </p>
          <button
            class="delete"
            aria-label="close"
            @click="$emit('close')"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="field">
            <label class="label">{{ $t("project.project") }}</label>
            <div class="control">
              <span class="select" style="width: 100%">
                <select v-model="camera.project_id" style="width: 100%">
                  <option
                    v-for="project in projects"
                    :key="project.id"
                    :value="project.id"
                  >
                    {{ project.name }}
                  </option>
                </select>
              </span>
            </div>
          </div>
          <div class="field">
            <label class="label">{{ $t("camera.name") }}</label>
            <div class="control">
              <input class="input" type="text" v-model="camera.name" required />
            </div>
            <p
              class="help is-danger"
              v-if="camera.name == '' && formValidation == false"
            >
              {{ $t("camera.createFormRequirements.name") }}
            </p>
          </div>
          <div class="field">
            <label class="label">{{ $t("project.timezone") }}</label>
            <div class="control">
              <span class="select" style="width: 100%">
                <select v-model="camera.timezone" style="width: 100%">
                  <option
                    v-for="timezone in timezones"
                    :key="timezone"
                    :value="timezone"
                  >
                    {{ timezone }}
                  </option>
                </select>
              </span>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label"> Secret Key </label>
                <p class="control">
                  <input class="input" type="text" v-model="camera.properties.hikvision_secret_key" required />
                </p>
              </div>
  
              <p
                class="help is-danger"
                v-if="(camera.properties.hikvision_secret_key == null || camera.properties.hikvision_secret_key == 0) && formValidation == false"
              >
                {{ $t("camera.createFormRequirements.hikvision.secret_key") }}
              </p>
            </div>
            <div class="column is-3">
              <div class="field">
                <label class="label"> App Key </label>
                <p class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="camera.properties.hikvision_app_key"
                    required
                  />
                </p>
                <p
                  class="help is-danger"
                  v-if="(camera.properties.hikvision_app_key == null || camera.properties.hikvision_app_key == '') && formValidation == false"
                >
                  {{ $t("camera.createFormRequirements.hikvision.app_key") }}
                </p>
              </div>
            </div>
            <div class="column is-3">
              <div class="field">
                <label class="label">{{ $t("camera.channel") }}</label>
                <p class="control">
                  <input
                    class="input"
                    type="number"
                    v-model="camera.properties.hikvision_channel_no"
                    required
                  />
                </p>
                <p
                  class="help is-danger"
                  v-if="(camera.properties.hikvision_channel_no == null || camera.properties.hikvision_channel_no == '') && formValidation == false"
                >
                  {{ $t("camera.createFormRequirements.channel") }}
                </p>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">{{ $t("camera.serial_number") }}</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="camera.properties.hikvision_device_serial"
                    required
                  />
                </div>
                <p
                  class="help is-danger"
                  v-if="(camera.properties.hikvision_device_serial == null || camera.properties.hikvision_device_serial == '') && formValidation == false"
                >
                  {{ $t("camera.createFormRequirements.serial_number") }}
                </p>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <div class="field is-grouped">
            <p class="control">
              <button
                :class="['button', 'is-success', isLoading ? 'is-loading' : '']"
                @click="createOrUpdate"
              >
                {{ camera.id ? $t("camera.update") : $t("camera.create") }}
              </button>
            </p>
            <p class="control">
            <button
              class="button"
              :disabled="checkConnectionLoading"
              @click="checkConnection"
            >
              {{ $t("camera.checkOnvifConnButtonTitle") }}
            </button>
          </p>
          </div>
        </footer>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from "vuex";
  import { timezones } from "@/assets/timezones.js";
  export default {
    props: {
      camera: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        formValidation: true,
        isLoading: false,
        checkConnectionLoading: false,
        timezones: timezones,
      };
    },
    computed: {
      ...mapState({
        cameras: (state) => state.cameras,
        projects: (state) => state.projects,
      }),
    },
    methods: {
      ...mapActions(["refreshState"]),
      async createOrUpdate() {
        if (this.validateForm() == false) {
          this.formValidation = false;
          return;
        }
        
        this.isLoading = true;
        let formData = new FormData();
  
        formData.append("name", this.camera.name);
        formData.append("project_id", this.camera.project_id);
        formData.append("timezone", this.camera.timezone);
        formData.append("version",3)
      
        const properties = {
          hikvision_app_key: this.camera.properties.hikvision_app_key,
          hikvision_secret_key: this.camera.properties.hikvision_secret_key,
          hikvision_channel_no: parseInt(this.camera.properties.hikvision_channel_no),
          hikvision_device_serial:this.camera.properties.hikvision_device_serial
        };
        formData.append("properties",JSON.stringify(properties));
  
        let response = null;
  
        if (this.camera.id == 0 || this.camera.id == null) {
          formData.append("op", "create_camera");
          response = await this.$http.post("camera", formData);
          this.camera.id = response.data.id;
          if (response.status == 201) {
            this.$toast.success(this.$t("camera.createSuccess"));
          }
        } else if (this.camera.id > 0) {
          formData.append("op", "update_camera");
          formData.append("id", this.camera.id);
          response = await this.$http.put("camera", formData);
          if (response.status == 200) {
            this.$toast.success(this.$t("camera.updateSuccess"));
          }   
        }

        this.refreshState();
        this.isLoading = false;
      },
      checkConnection() {
        if (this.checkConnectionLoading == true) {
          return;
        }
        if (this.validateForm() == false) {
          this.formValidation = false;
          return;
        }
        let formData = new FormData();
        const properties = {
          hikvision_app_key: this.camera.properties.hikvision_app_key,
          hikvision_secret_key: this.camera.properties.hikvision_secret_key,
          hikvision_channel_no: parseInt(this.camera.properties.hikvision_channel_no),
          hikvision_device_serial:this.camera.properties.hikvision_device_serial
        };
        formData.append("properties",JSON.stringify(properties));
        formData.append("version", 3);

        this.$http.post("check-connection", formData).then(() => {
          this.$showToastWithType(
            this.$t("camera.cameraConnectionSuccess"),
            "success"
          );
        });
      },
      validateForm() {
        if (
          this.camera.project_id == "" ||
          this.camera.name == "" ||
          this.camera.properties.hikvision_secret_key == "" || this.camera.properties.hikvision_secret_key == null || 
          this.camera.properties.hikvision_app_key == "" || this.camera.properties.hikvision_app_key == null ||
          this.camera.properties.hikvision_channel_no == "" || this.camera.properties.hikvision_channel_no == null ||
          this.camera.properties.hikvision_device_serial == "" || this.camera.properties.hikvision_device_serial == null
        ) {
          return false;
        }
        return true;
      },
    },
  };
  </script>
  
  <style></style>
  