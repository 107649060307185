<template>
  <div
    v-if="isShowCameraSelector && otherCameras.length > 0"
    class="navbar-item has-dropdown is-hoverable"
  >
    <a
      class="navbar-link has-text-weight-semibold"
      href="#"
      @click="expandMenu"
      >{{ cameraName }}</a
    >
    <div class="navbar-dropdown has-background-link">
      <a
        class="navbar-item"
        href="#"
        v-for="camera in otherCameras"
        :key="camera.id"
        @click="selectCamera(camera.id)"
        >{{ camera.name }}</a
      >
    </div>
  </div>
  <div v-else-if="isShowCameraSelector" class="navbar-item">
    {{ cameraName }}
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters(["otherCameras", "selectedCamera"]),
    currentRoute() {
      return this.$route.name;
    },
    isShowCameraSelector() {
      return (
        this.currentRoute !== "UserList" &&
        this.currentRoute !== "CameraList" &&
        this.currentRoute !== "ProjectList"
      );
    },
    cameraName() {
      return this.selectedCamera?.name || "-";
    },
  },
  methods: {
    ...mapMutations(["SELECT_CAMERA"]),
    selectCamera(id) {
      this.SELECT_CAMERA(id);
      this.closeMenu();
    },
    expandMenu(event) {
      event.target.closest(".has-dropdown").classList.toggle("is-active");
    },
    closeMenu() {
      document.querySelector(".navbar-menu").classList.remove("is-active");
      const $navbarBurgers = Array.prototype.slice.call(
        document.querySelectorAll(".navbar-burger"),
        0
      );

      if ($navbarBurgers.length > 0) {
        $navbarBurgers.forEach((el) => {
          el.classList.toggle("is-active");
        });
      }
      document
        .querySelectorAll(".navbar-item.has-dropdown.is-active")
        .forEach((el) => {
          el.classList.remove("is-active");
        });
    },
  },
};
</script>

<style></style>
