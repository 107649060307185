var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"title has-text-white"},[_vm._v(_vm._s(_vm.$t("login.login")))]),_c('form',{staticClass:"box",attrs:{"action":""}},[_c('div',{staticClass:"field username"},[_c('p',{staticClass:"control has-icons-left"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],class:['input', _vm.userInputClass],attrs:{"tabindex":"1","type":"email","placeholder":_vm.$t('login.email')},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing)return;_vm.username=$event.target.value}}}),_vm._m(0)])]),_c('div',{staticClass:"field password is-relative"},[_c('p',{staticClass:"control has-icons-left"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],class:['input', _vm.passwordInputClass],attrs:{"tabindex":"2","type":"password","placeholder":_vm.$t('login.password')},domProps:{"value":(_vm.password)},on:{"focus":function($event){_vm.isForgotVisible = false},"blur":function($event){_vm.isForgotVisible = true},"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_vm._m(1)]),_c('transition',{attrs:{"name":"fade"}},[(_vm.isForgotVisible)?_c('button',{staticClass:"button forgot",attrs:{"tabindex":"5"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/recovery')}}},[_vm._v(" "+_vm._s(_vm.$t("login.forgotPassword"))+" ")]):_vm._e()])],1),_c('div',{staticClass:"field"},[_c('label',{staticClass:"checkbox",attrs:{"for":"remember-me"}},[_c('input',{attrs:{"tabindex":"3","type":"checkbox","id":"remember-me"}}),_vm._v(" "+_vm._s(_vm.$t("login.rememberMe"))+" ")])]),_c('p',{staticClass:"control"},[_c('button',{class:[
          'button',
          'is-info',
          'is-fullwidth',
          _vm.inProgress ? 'is-loading' : '',
        ],attrs:{"tabindex":"4"},on:{"click":_vm.login_handler}},[_vm._v(" "+_vm._s(_vm.$t("login.login"))+" ")]),_c('a',{staticClass:"button mt-3 is-info is-outlined is-fullwidth",staticStyle:{"background-color":"white"},on:{"click":_vm.sigh_with_google_button}},[_vm._m(2),_c('span',{staticStyle:{"color":"#3e8fd0"}},[_vm._v(" "+_vm._s(_vm.$t("login.sign_with_google"))+" ")])])])]),_c('p',{staticClass:"content pt-5"},[_c('a',{staticClass:"has-text-info",attrs:{"href":"#"},on:{"click":function($event){return _vm.$router.push('/register')}}},[_vm._v(_vm._s(_vm.$t("login.no_account")))])]),_c('p',{staticClass:"content"},[_c('a',{staticClass:"has-text-info",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t("login.issues_with_login")))])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon is-small is-left"},[_c('i',{staticClass:"fas fa-envelope"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon is-small is-left"},[_c('i',{staticClass:"fas fa-lock"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/images/google.256x256.png")}})])
}]

export { render, staticRenderFns }