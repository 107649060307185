<template>
  <div :class="['modal', isActive ? 'is-active' : '']">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t("video.form.title") }}</p>
        <button
          class="delete"
          aria-label="close"
          @click="SET_MODAL_STATE_VIDEO_ORDER(false)"
        ></button>
      </header>
      <section class="modal-card-body">
        <p class="notification">
          {{ $t("video.form.notification") }}
        </p>
        <div class="columns is-multiline">
          <div class="column is-half">
            <div class="field">
              <label class="label">{{ $t("video.form.startDate") }}</label>
              <div class="control">
                <Datepicker
                  v-model="startDate"
                  input-class="input"
                  wrapper-class="calendar-wrapper"
                  calendar-button-icon="fas fa-calendar"
                  monday-first
                  format="dd.MM.yyyy"
                  :language="tr"
                ></Datepicker>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">{{ $t("video.form.endDate") }}</label>
              <div class="control">
                <Datepicker
                  v-model="endDate"
                  input-class="input"
                  wrapper-class="calendar-wrapper"
                  calendar-button-icon="fas fa-calendar"
                  monday-first
                  format="dd.MM.yyyy"
                  :language="tr"
                ></Datepicker>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">{{ $t("video.form.startTime") }}</label>
              <div class="control">
                <select v-model="startHour">
                  <option
                    v-for="x in 24"
                    :key="x - 1"
                    :value="('0' + (x - 1)).slice(-2)"
                  >
                    {{ ("0" + (x - 1)).slice(-2) + ":00" }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">{{ $t("video.form.endTime") }}</label>
              <div class="control">
                <select v-model="endHour">
                  <option
                    v-for="x in 24"
                    :key="x - 1"
                    :value="('0' + (x - 1)).slice(-2)"
                  >
                    {{ ("0" + (x - 1)).slice(-2) + ":59" }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">{{ $t("video.form.resolution") }}</label>
              <div class="control">
                <select v-model="resolution">
                  <option
                    v-for="res in resolutions"
                    :key="res.value"
                    :value="res.value"
                  >
                    {{ res.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">{{ $t("video.form.removeBlur") }}</label>
              <div class="control">
                <input type="checkbox" class="checkbox" v-model="removeBlur" />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <p v-if="removeBlur" class="notification is-info is-light">
          {{ $t("video.form.removeBlurNotification") }}
        </p>
      </section>
      <footer class="modal-card-foot">
        <div class="field is-grouped">
          <p class="control">
            <button class="button is-text" @click="SET_MODAL_STATE_VIDEO_ORDER(false)">
              {{ $t("video.form.cancel") }}
            </button>
          </p>
          <p class="control">
            <button
              :class="['button', 'is-success', isLoading ? 'is-loading' : '']"
              @click="createTimelapse"
            >
              {{ $t("video.form.create") }}
            </button>
          </p>
        </div>
      </footer>
    </div>
  </div>
</template>
<style scoped>
.modal-card-body {
  min-height: 540px;
}
</style>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";

const today = new Date();
export default {
  data() {
    return {
      isLoading: false,
      startDate: new Date(today.getFullYear(), today.getMonth(), 1),
      endDate: today,
      startHour: "09",
      endHour: "17",
      removeBlur: false,
      resolution: "hd",
      tr,
      resolutions: [
        {
          label: "720p",
          value: "hd",
        },
        {
          label: "1080p",
          value: "full_hd",
        },
        {
          label: "4K",
          value: "4k",
        },
        {
          label: "8K",
          value: "8k",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      isActive: (state) => state.modals.videoOrder.isActive,
      // companies: (state) => state.companies,
    }),
    ...mapGetters(["selectedCamera"]),
  },
  components: {
    Datepicker,
  },
  methods: {
    ...mapMutations(["SET_MODAL_STATE_VIDEO_ORDER"]),
    createTimelapse: function () {
      var formData = new FormData();
      formData.append("start_date", this.startDate.getTime() / 1000);
      formData.append("end_date", this.endDate.getTime() / 1000);
      formData.append("start_hour", this.startHour);
      formData.append("end_hour", this.endHour);
      formData.append("resolution", this.resolution);
      formData.append("remove_blur", this.removeBlur);
      formData.append("cid", this.selectedCamera.id);

      this.isLoading = true;

      this.$http.post("order-video/", formData).then(() => {
        this.isLoading = false;
        this.reset();
        this.SET_MODAL_STATE_VIDEO_ORDER(false);
      });
    },
    reset: function () {
      this.startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      this.endDate = today;
      this.startHour = "09";
      this.endHour = "17";
      this.resolution = "hd";
      this.removeBlur = false;
    },
  },
};
</script>

<style></style>
