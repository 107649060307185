<template>
  <div class="home">
    <section class="hero is-fullheight">
      <div class="hero-head">
        <Navbar />
      </div>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
      <div class="hero-foot has-background-white">
        <Timeline />
      </div>
    </section>
  </div>
</template>

<script>
import Navbar from "@/components/NavBar.vue";
import Timeline from "@/components/Timeline";

export default {
  name: "Home",
  components: {
    Navbar,
    Timeline,
  },
};
</script>
