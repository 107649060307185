<template>
    <div>
      <Navbar />
      <section class="section has-background-white" style="min-height: 100vh;">
        <div class="card">
        <span class="title">{{$t('order.orders')}}</span>
      
        <table style="margin-top: 15px;" class="table is-fullwidth is-bordered is-striped is-hoverable">
          <thead>
            <th>{{$t('order.package_name')}}</th>
            <th>{{$t('order.created_at')}}</th>
            <th>{{$t('order.status')}}</th>
            <th>{{$t('order.amount')}}</th>
            <th>{{$t('order.currency')}}</th>
          </thead>
          <tbody v-if="orders.length">
            <tr v-for="order in orders" :key="order.id">
              <td>{{ order.package.name }}</td>
              <td>{{ order.created_at }}</td>
              <td>{{ order.status }}</td>
              <td>{{ order.amount }}</td>
              <td>{{ order.currency }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td></td>
              <td class="has-text-centered" colspan="6">{{ $t("order.not_found") }}</td>
            </tr>
          </tbody>
        </table>
        </div>
      </section>  
     
    </div>
  </template>  
  <script>
  import Navbar from "@/components/NavBar.vue";
  
  export default {
    name: "Orders",
    data() {
      return {
        orders: [],
      };
    },
    components: {
    Navbar
    },
    methods: {
      convertDate(timestamp) {
        const dateObject = new Date(timestamp);
        const formattedTime = dateObject.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        const formattedDate = dateObject.toLocaleDateString([], { day: '2-digit', month: '2-digit', year: 'numeric' });
        return `${formattedTime} ${formattedDate}`;
      },
    },
    mounted() {
      
      this.$http.get("orders?page=1&size=10").then((res) => {
            
            setTimeout(() => {
              this.orders = res.data.data;
              for (var i=0;i<this.orders.length;i++) {
                this.orders[i].created_at = this.convertDate(this.orders[i].created_at);

                if (this.orders[i].status == 'success') {
                  this.orders[i].status = this.$t("order.success")
                }

                if (this.orders[i].status == 'failed') {
                  this.orders[i].status = this.$t("order.failed") + ": " + this.orders[i].failed_description
                }
              }
            }, 3000);
      });
    },
  };
  </script>
