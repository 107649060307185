import Vue from "vue";
import store from "@/store";
import VueRouter from "vue-router";
import Timeline from "../views/Timeline.vue";
import Login from "../views/Login.vue";
import List from "../views/List.vue";
import CameraSettings from "../views/CameraSettings.vue";
import Contract from "../views/Contract.vue";
import Orders from "../views/Orders.vue";
import Invoices from "../views/Invoices.vue";
import Payments from "../views/Payments.vue";
import CreditCards from "../views/CreditCards.vue";
import Subscription from "../views/Subscription.vue";
import LoginForm from "@/components/LoginForm.vue";
import RegisterForm from "@/components/RegisterForm.vue";
import EmailValidationForm from "@/components/EmailValidationForm.vue";
import PasswordRecoveryForm from "@/components/PasswordRecoveryForm.vue";

import PhotoViewport from "@/components/PhotoViewport.vue";
import ComparisonViewport from "@/components/ComparisonViewport.vue";
import SyncViewport from "@/components/SyncViewport.vue";

import FileList from "@/components/FileList.vue";
import VideoList from "@/components/VideoList.vue";
import BookmarkList from "@/components/BookmarkList.vue";
import UserList from "@/components/UserList.vue";
import CameraList from "@/components/CameraList.vue";
import ProjectList from "@/components/ProjectList.vue";
import CompanyList from "@/components/CompanyList.vue";
import BulkPhotoDownload from "../views/BulkPhotoDownload.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    children: [
      {
        path: "",
        name: "LoginForm",
        component: LoginForm,
        meta: {
          guest: true,
        },
      },
      {
        path: "/login",
        name: "LoginForm",
        component: LoginForm,
        meta: {
          guest: true,
        },
      },
      {
        path: "register",
        name: "RegisterForm",
        component: RegisterForm,
        meta: {
          guest: true,
        },
      },
      {
        path: "validate",
        name: "EmailValidationForm",
        component: EmailValidationForm,
        meta: {
          guest: true,
        },
      },
      {
        path: "recovery",
        name: "PasswordRecoveryForm",
        component: PasswordRecoveryForm,
        meta: {
          guest: true,
        },
      },
    ],
  },
  {
    path: "/timeline",
    name: "Timeline",
    component: Timeline,
    children: [
      {
        path: "",
        name: "Photo",
        component: PhotoViewport,
        meta: {
          requiresAuth: true,
          permission: "display_timeline",
        },
      },
      {
        path: "/comparison",
        name: "Comparison",
        component: ComparisonViewport,
        // meta: {
        //   requiresAuth: true,
        //   permission: "display_comparison",
        // },
      },
      {
        path: "/sync",
        name: "Sync",
        component: SyncViewport,
        // meta: {
        //   requiresAuth: true,
        //   permission: "display_comparison",
        // },
      },
    ],
  },

  {
    path: "/files",
    name: "Files",
    component: List,
    children: [
      {
        path: "",
        name: "FileList",
        component: FileList,
        meta: {
          requiresAuth: true,
          permission: "list_photos",
        },
      },
      {
        path: "/video",
        name: "Video",
        component: VideoList,
        meta: {
          requiresAuth: true,
          permission: "list_videos",
        },
      },
      {
        path: "/bulk-photo-download",
        name: "BulkPhotoDownload",
        component: BulkPhotoDownload,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/users",
        name: "UserList",
        component: UserList,
        meta: {
          requiresAuth: true,
          permission: "list_users",
        },
      },
      {
        path: "/bookmarks",
        name: "Bookmarks",
        component: BookmarkList,
        meta: {
          requiresAuth: true,
          permission: "list_bookmarks",
        },
      },
      {
        path: "/cameras",
        name: "CameraList",
        component: CameraList,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/companies",
        name: "CompanyList",
        component: CompanyList,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/projects",
        name: "ProjectList",
        component: ProjectList,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },

  // {
  //   path: "/comparison",
  //   name: "Comparison",
  //   component: ComparisonView,
  //   // meta: {
  //   //   requiresAuth: true,
  //   //   permission: "display_comparison",
  //   // },
  // },
  // {
  //   path: "/sync",
  //   name: "Sync",
  //   component: SyncView,
  //   // meta: {
  //   //   requiresAuth: true,
  //   //   permission: "display_comparison",
  //   // },
  // },
  {
    path: "/settings",
    name: "CameraSettings",
    component: CameraSettings,
    meta: {
      requiresAuth: true,
      permission: "change_camera_settings",
    },
  },
  {
    path: "/contract",
    name: "Contracts",
    component: Contract,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/payments",
    name: "Payments",
    component: Payments,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/invoices",
    name: "Invoices",
    component: Invoices,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/credit-cards",
    name: "CreditCards",
    component: CreditCards,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/subscription",
    name: "Subscription",
    component: Subscription,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("SET_TOAST_STATE", false);
  if (to.matched.some((record) => record.meta.permission)) {
    if (!store.state.user.permissions.includes(to.meta.permission)) {
      this.$showToastWithType(
        this.$i18n.t("message.error.noPagePermission"),
        "error"
      );
      return false;
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    var jwt = sessionStorage.getItem("jwt");
    if (jwt == null) {
      next({
        path: "/",
        params: {
          nextUrl: to.fullPath,
        },
      });
      return;
    }
    var jwtData = parseJwt(jwt);
    if (
      !store.state.user.isAuthenticated ||
      Math.floor(Date.now() / 1000) > jwtData.exp
    ) {
      next({
        path: "/",
        params: {
          nextUrl: to.fullPath,
        },
      });
    } else {
      next();
    }
  } else {
    next();
  }

  if (to.path === "/validate" && !to.query.email) {
    next({
      path: "/",
      params: {
        nextUrl: to.fullPath,
      },
    });
    return;
  }
});

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export default router;
