<template>
  <div>
    <div class="field">
      <label class="label">{{ $t("user.fullName") }}</label>
      <div class="control">
        <input class="input" v-model="user.fullName" />
      </div>
    </div>
    <div class="field">
      <label class="label">{{ $t("user.email") }}</label>
      <div class="control">
        <input class="input" v-model="user.email" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState({
      user: (state) => state.userForm,
    }),
  },
  methods: {
    ...mapMutations([
      "SET_USERFORM_ID",
      "SET_USERFORM_FULLNAME",
      "SET_USERFORM_ISACTIVE",
      "SET_USERFORM_EMAIL",
      "SET_USERFORM_PERMISSIONS",
    ]),
  },
};
</script>
