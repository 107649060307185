import router from "@/router/";
import LogRocket from "logrocket";

import {
  SET_PHOTO_VIEWPORT_HEIGHT,
  SET_IS_AUTHENTICATED,
  SET_USER_ID,
  SET_USER_FULLNAME,
  SET_USER_EMAIL,
  SET_USER_PERMISSONS,
  SET_COMPANIES,
  SET_CAMERAS,
  SELECT_FIRST_CAMERA,
  SET_PROJECTS,
  SELECT_PROJECT,
  SET_CURRENT_PHOTO,
  SET_COMPARISON_PHOTO,
  SET_SELECTED_DATE,
  SET_DATE_INTERVAL,
  SET_TOAST_STATE,
  SET_TOAST_MESSAGE,
} from "@/store/mutations.js";

function resetState({ commit }) {
  commit(SET_IS_AUTHENTICATED, false);
  commit(SET_USER_ID, "");
  commit(SET_USER_FULLNAME, "");
  commit(SET_USER_EMAIL, "");
  commit(SET_USER_PERMISSONS, []);
  commit(SET_COMPANIES, []);
  commit(SET_CAMERAS, []);
  commit(SET_PROJECTS, []);
  commit(SET_CURRENT_PHOTO, {
    timestamp: null,
    dzi: null,
    file: "",
    bookmark: {
      id: null,
      title: "",
      type: "personal",
    },
  });
  commit(SET_COMPARISON_PHOTO, {
    timestamp: null,
    dzi: null,
    file: "",
    bookmark: {
      id: null,
      title: "",
      type: "personal",
    },
  });
  commit(SET_DATE_INTERVAL, null);
  commit(SET_SELECTED_DATE, null);
}
export const actions = {
  showToast({ commit }, message) {
    commit(SET_TOAST_STATE, true);
    commit(SET_TOAST_MESSAGE, message);
    setTimeout(() => {
      commit(SET_TOAST_STATE, false);
    }, 1500);
  },
  resizeViewport({ commit }, height) {
    commit(SET_PHOTO_VIEWPORT_HEIGHT, height);
  },
  login({ commit }, formData) {
    resetState({ commit });
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("login/", formData)
        .then((response) => {
          let data = response.data;

          if (response.status > 299) {
            reject(response);
          }
          var selectedCompany = data.companies[0];
          if (selectedCompany != null) {
            data.companies[0].isSelected = true;
          }

          var selectedProject = data.projects.find(
            (c) => selectedCompany.id == c.company_id
          );
          if (selectedProject != null) {
            for (let i = 0; i < data.projects.length; i++) {
              if (data.projects[i].id == selectedProject.id) {
                data.projects[i].isSelected = true;
              } else {
                data.projects[i].isSelected = false;
              }
            }
          }

          var selectedCamera = data.cameras.find(
            (c) => selectedProject.id == c.project_id
          );
          if (selectedCamera != null) {
            for (let i = 0; i < data.cameras.length; i++) {
              if (data.cameras[i].id == selectedCamera.id) {
                data.cameras[i].isSelected = true;
              } else {
                data.cameras[i].isSelected = false;
              }
            }
          }

          commit(SET_IS_AUTHENTICATED, true);
          commit(SET_USER_ID, data.id);
          commit(SET_USER_FULLNAME, data.fullName);
          commit(SET_USER_EMAIL, data.email);
          commit(SET_PROJECTS, data.projects);
          commit(SET_COMPANIES, data.companies);
          commit(SET_CAMERAS, data.cameras);
          commit(SET_USER_PERMISSONS, data.permissions);

          sessionStorage.setItem("jwt", data.token);

          LogRocket.identify(data.id, {
            name: data.full_name,
            email: data.email,
          });

          router.push("/timeline");
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  googleRegister({ commit }, formData) {
    resetState({ commit });
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("register/", formData)
        .then((response) => {
          let data = response.data;
      
          if (response.status > 299) {
            reject(response)
          }
          var selectedCompany = data.companies[0];
          if (selectedCompany != null) {
            data.companies[0].isSelected = true;
          }
          
        
          var selectedProject = data.projects.find((c) => selectedCompany.id == c.company_id);
          if (selectedProject != null) {
            for (let i =0;i<data.projects.length;i++) {
              if (data.projects[i].id == selectedProject.id) {
                data.projects[i].isSelected = true
              }else{
                data.projects[i].isSelected = false
              }
            }
          }
      
          var selectedCamera = data.cameras.find((c) => selectedProject.id == c.project_id);
          if (selectedCamera != null) {
            for (let i =0;i<data.cameras.length;i++) {
              if (data.cameras[i].id == selectedCamera.id) {
                data.cameras[i].isSelected = true
              }else{
                data.cameras[i].isSelected = false
              }
            }
          }
          

        
          
          commit(SET_IS_AUTHENTICATED, true);
          commit(SET_USER_ID, data.id);
          commit(SET_USER_FULLNAME, data.fullName);
          commit(SET_USER_EMAIL, data.email);
          commit(SET_PROJECTS, data.projects);
          commit(SET_COMPANIES, data.companies);
          commit(SET_CAMERAS, data.cameras);
          commit(SET_USER_PERMISSONS, data.permissions);

        
          sessionStorage.setItem("jwt",data.token);

          LogRocket.identify(data.id, {
            name: data.full_name,
            email: data.email,
          });

          router.push("/timeline");
          resolve();
        })
        .catch((err) => {
          reject(err)
        });
    });
  },
  refreshState({ commit }) {
    this._vm.$http
      .get("refresh-state/", {
        params: {
          op: "list_companies",
        },
      })
      .then((response) => {
        let data = response.data;

        var selectedCompany = data.companies[0];
        if (selectedCompany != null) {
          data.companies[0].isSelected = true;
        }

        var selectedProject = data.projects.find(
          (c) => selectedCompany.id == c.company_id
        );

        if (selectedProject != null) {
          for (let i = 0; i < data.projects.length; i++) {
            if (data.projects[i].id == selectedProject.id) {
              data.projects[i].isSelected = true;
            } else {
              data.projects[i].isSelected = false;
            }
          }
        }

        var selectedCamera = data.cameras.find(
          (c) => selectedProject.id == c.project_id
        );
        if (selectedCamera != null) {
          for (let i = 0; i < data.cameras.length; i++) {
            if (data.cameras[i].id == selectedCamera.id) {
              data.cameras[i].isSelected = true;
            } else {
              data.cameras[i].isSelected = false;
            }
          }
        }

        commit(SET_USER_FULLNAME, data.fullName);
        commit(SET_USER_EMAIL, data.email);
        commit(SET_PROJECTS, data.projects);
        commit(SET_COMPANIES, data.companies);
        commit(SET_CAMERAS, data.cameras);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  resetPassword({ commit }, email) {
    commit(SET_IS_AUTHENTICATED, false);
    return new Promise((resolve, reject) => {
      var formData = new FormData();
      formData.append("email", email);
      this._vm.$http
        .post("forgot-password", formData)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  logout({ commit }) {
    resetState({ commit });
    router.push("/");
  },
  backToLogin() {
    if (router.currentRoute.path === "/") {
      return;
    }
    router.push("/");
  },
  forgot() {
    if (router.currentRoute.path === "/iforgot") {
      return;
    }
    router.push("/iforgot");
  },
  openFileListPage() {
    if (router.currentRoute.path === "/files") {
      return;
    }
    router.push("/files");
  },
  openVideoPage() {
    if (router.currentRoute.path === "/video") {
      return;
    }
    router.push("/video");
  },
  openUsersPage() {
    if (router.currentRoute.path === "/users") {
      return;
    }
    router.push("/users");
  },
  openTimelinePage() {
    if (router.currentRoute.path === "/timeline") {
      return;
    }
    router.push("/timeline");
  },
  openComparisonPage() {
    if (router.currentRoute.path === "/comparison") {
      return;
    }
    router.push("/comparison");
  },
  openSyncComparisonPage() {
    if (router.currentRoute.path === "/sync") {
      return;
    }
    router.push("/sync");
  },
  selectProject({ commit }, id) {
    commit(SET_CURRENT_PHOTO, {
      timestamp: null,
      dzi: null,
      file: "",
      bookmark: {
        id: null,
        title: "",
        type: "personal",
      },
    });
    commit(SELECT_PROJECT, id);
    commit(SELECT_FIRST_CAMERA, id);
  },
  download({ state, getters }) {
    if (!state.user.permissions.includes("download_photo")) {
      this.$showToastWithType(
        this.$t("message.error.noDownloadPermission"),
        "error"
      );
      return;
    }
    this._vm.$http
      .get("download-photo/", {
        params: {
          op: "download_photo",
          cid: getters.selectedCamera.id,
          t: state.currentPhoto.timestamp,
          d: 1,
        },
      })
      .then((response) => {
        window.open(response.data.url, "_blank");
      });
  },
};
