<template>
  <div>
    <div class="level notification">
      <div class="level-item" v-if="user.isActive">
        <p v-html="$t('user.operations.status.active')"></p>
      </div>
      <div class="level-item" v-else>
        <p v-html="$t('user.operations.status.inactive')"></p>
      </div>
      <div class="level-item">
        <button
          :class="[
            'button',
            user.isActive ? '' : 'is-info',
            buttons.toggleStatus.isLoading ? 'is-loading' : '',
          ]"
          v-html="
            user.isActive
              ? $i18n.t('user.operations.deactivate')
              : $i18n.t('user.operations.activate')
          "
          @click="toggleStatus"
        ></button>
      </div>
    </div>
    <div class="level notification">
      <div
        class="level-item"
        v-html="$t('user.operations.password.resetText')"
      ></div>
      <div class="level-item">
        <button
          :class="[
            'button',
            'is-warning',
            buttons.resetPassword.isLoading ? 'is-loading' : '',
          ]"
          @click="resetPassword"
        >
          {{ $t("user.operations.password.resetButton") }}
        </button>
      </div>
    </div>
    <div class="level notification">
      <div class="level-item">
        {{ $t("user.operations.removeText") }}
      </div>
      <div class="level-item">
        <button
          :class="[
            'button',
            'is-danger',
            buttons.delete.isLoading ? 'is-loading' : '',
          ]"
          @click="deleteUser"
        >
          {{ $t("user.operations.removeButton") }}
        </button>
      </div>
    </div>
  </div>
</template>
<style scoped>
.level-item {
  flex-shrink: 1;
  padding-right: 1em;
}
</style>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      buttons: {
        toggleStatus: {
          isLoading: false,
        },
        resetPassword: {
          isLoading: false,
        },
        delete: {
          isLoading: false,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["selectedCompany"]),
    ...mapState({
      user: (state) => state.userForm,
    }),
  },
  methods: {
    ...mapMutations(["SET_USERFORM_ISACTIVE"]),
    deleteUser: function () {
      //"Bu kullanıcı organizasyonunuzdan çıkartılacaktır.\n\nEmin misiniz?"
      if (!confirm(this.$t("user.operations.removeConfirmText"))) return;

      var formData = new FormData();
      formData.append("cid", this.selectedCompany.id);
      formData.append("id", this.user.id);
      formData.append("op", "remove_user");

      this.buttons.delete.isLoading = true;

      this.$http.post("remove-user/", formData).then((response) => {
        this.buttons.delete.isLoading = false;
        if (response.status === 204) {
          this.$emit("changed");
          this.$showToastWithType(this.$i18n.t("user.operations.removeSuccessText","success"));
          this.close();
        } else {
          this.$showToastWithType(this.$i18n.t("user.operations.removeErrorText"),"error");
        }
      });
    },
    resetPassword: function () {
      if (!confirm(this.$i18n.t("user.operations.password.resetConfirmText")))
        return;

      var formData = new FormData();
      formData.append("cid", this.selectedCompany.id);
      formData.append("id", this.user.id);
      formData.append("op", "reset_user_password");

      this.buttons.resetPassword.isLoading = true;

      this.$http.post("reset-user-password/", formData).then((response) => {
        this.buttons.resetPassword.isLoading = false;

        if (response.status !== 200) {
          this.$showToastWithType(this.$i18n.t("user.operations.password.resetErrorText"),"error");
          return;
        }
        this.$showToastWithType(this.$i18n.t("user.operations.password.resetSuccessText"),"success");
      });
    },
    toggleStatus: function () {
      let operation = this.user.isActive ? "deactivate_user" : "activate_user";

      var formData = new FormData();
      formData.append("cid", this.selectedCompany.id);
      formData.append("id", this.user.id);
      formData.append("op", operation);
      formData.append("isActive", this.user.isActive);

      let toggleMessage = this.user.isActive
        ? this.$i18n.t("user.operations.status.deactivateConfirmText")
        : this.$i18n.t("user.operations.status.activateConfirmText");
      toggleMessage += this.$i18n.t("user.operations.status.confirmText");

      if (!confirm(toggleMessage)) {
        return;
      }

      this.buttons.toggleStatus.isLoading = true;

      this.$http.post(this.user.isActive ? "deactivate-user" : "activate-user", formData).then((response) => {
        this.buttons.toggleStatus.isLoading = false;
        if (response.status !== 204) {
          this.$showToastWithType(this.$i18n.t("user.operations.status.changeErrorText"),"error");
          return;
        }
        this.$emit("changed");
        this.SET_USERFORM_ISACTIVE(!this.user.isActive);
      });
    },
  },
};
</script>
