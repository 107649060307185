<template>
  <section class="section" style="min-height: 100vh">
    <h1 class="title">{{ $t("bookmark.list.title") }}</h1>
    <h1 :class="['subtitle']">
      {{
        $tc("bookmark.list.photosListed", bookmarks.length, {
          numberOfPhotos: bookmarks.length,
        })
      }}
    </h1>
    <div class="field is-grouped">
      <p class="control">
        <span class="select">
          <select v-model="selectedType">
            <option value="all">{{ $t("bookmark.types.all") }}</option>
            <option value="personal">
              {{ $t("bookmark.types.personal") }}
            </option>
            <option value="general">
              {{ $t("bookmark.types.general") }}
            </option>
          </select>
        </span>
      </p>
      <p class="control">
        <Datepicker
          v-model="startDate"
          input-class="input"
          wrapper-class="calendar-wrapper"
          calendar-button-icon="fas fa-calendar"
          monday-first
          format="dd.MM.yyyy"
          :language="tr"
          :disabled-dates="disabledDates"
        ></Datepicker>
      </p>
      <p class="control">
        <Datepicker
          v-model="endDate"
          input-class="input"
          wrapper-class="calendar-wrapper"
          calendar-button-icon="fas fa-calendar"
          monday-first
          format="dd.MM.yyyy"
          :language="tr"
          :disabled-dates="disabledDates"
        ></Datepicker>
      </p>
      <p class="control">
        <button class="button is-primary" @click="search">
          <span class="icon">
            <i class="fas fa-search"></i>
          </span>
          <span>{{ $t("bookmark.list.search") }}</span>
        </button>
      </p>
    </div>
    <table class="table is-bordered is-striped is-hoverable">
      <thead></thead>
      <tbody>
        <tr v-for="bookmark in bookmarks" :key="bookmark.id">
          <td>
            {{ bookmark.id }}
          </td>
          <td
            :class="[
              bookmark.type === 'personal'
                ? 'has-text-danger'
                : 'has-text-link',
            ]"
          >
            <span class="icon">
              <i class="fas fa-bookmark"></i>
            </span>
            {{ bookmark.title }}
          </td>
          <td>
            {{ bookmark.timestamp | formatTimestampToDate }}
          </td>
          <td>
            {{ bookmark.user_name }}
          </td>
          <td>
            <div class="buttons has-addons">
              <button
                class="button is-small"
                @click="downloadBookmark(bookmark)"
              >
                <span class="icon">
                  <i class="fas fa-download"></i>
                </span>
              </button>
              <button class="button is-small" @click="shareBookmark(bookmark)">
                <span class="icon">
                  <i class="fas fa-share-alt"></i>
                </span>
              </button>
              <button class="button is-small" @click="editBookmark(bookmark)">
                <span class="icon">
                  <i class="fas fa-edit"></i>
                </span>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";
import { mapMutations, mapActions } from "vuex";

export default {
  name: "BookmarkList",
  components: {
    Datepicker,
  },
  data() {
    return {
      bookmarks: [],
      selectedType: "all",
      startDate: "",
      endDate: "",
      tr: tr,
      disabledDates: {
        from: new Date(),
      },
    };
  },

  computed: {
    ...mapState({
      selectedDate: (state) => state.selectedDate,
      bookmarkModal: (state) => state.modals.bookmarkForm.isActive,
      currentPhoto: (state) => state.currentPhoto,
    }),
    ...mapGetters(["selectedCamera"]),
  },
  filters: {
    formatTimestampToDate: function (value) {
      let d = new Date(value * 1000);
      let day = ("0" + d.getDate()).slice(-2);
      let month = ("0" + (d.getMonth() + 1)).slice(-2);
      let hour = ("0" + d.getHours()).slice(-2);
      let minute = ("0" + d.getMinutes()).slice(-2);
      let second = ("0" + d.getSeconds()).slice(-2);
      return `${day}.${month}.${d.getFullYear()} ${hour}:${minute}:${second}`;
    },
  },
  mounted() {
    let d = new Date();
    d.setDate(1);
    this.startDate = d;
    this.endDate = new Date();
    this.fetchBookmarks();
  },
  methods: {
    ...mapMutations([
      "SET_MODAL_STATE_PHOTO_SHARE",
      "SET_MODAL_STATE_BOOKMARK",
      "SET_CURRENT_PHOTO",
    ]),
    ...mapActions(["download"]),
    search() {
      this.fetchBookmarks();
    },
    fetchBookmarks() {
      this.bookmarks = [];

      let start = this.startDate;
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);
      start = Math.floor(start.getTime() / 1000);

      let end = this.endDate;
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);
      end = Math.floor(end.getTime() / 1000);

      this.$http
        .get("bookmark/", {
          params: {
            op: "list_bookmarks",
            cid: this.selectedCamera.id,
            start,
            end,
            type: this.selectedType,
          },
        })
        .then((res) => {
          console.log(res.data);
          this.bookmarks = res.data;
        });
    },
    shareBookmark(bookmark) {
      this.SET_CURRENT_PHOTO({
        timestamp: bookmark.timestamp,
        dzi: null,
        file: "",
        bookmark: {
          id: 0,
          title: "",
          type: "personal",
        },
      });
      this.SET_MODAL_STATE_PHOTO_SHARE(true);
    },
    editBookmark(bookmark) {
      this.SET_CURRENT_PHOTO({
        timestamp: bookmark.timestamp,
        dzi: null,
        file: bookmark.filename,
        bookmark: {
          id: bookmark.id,
          title: bookmark.title,
          type: bookmark.type,
        },
      });

      this.SET_MODAL_STATE_BOOKMARK(true);
    },
    downloadBookmark(bookmark) {
      this.SET_CURRENT_PHOTO({
        timestamp: bookmark.timestamp,
        dzi: null,
        file: bookmark.filename,
        bookmark: {
          id: bookmark.id,
          title: bookmark.title,
          type: bookmark.type,
        },
      });

      this.download();
    },
  },
  watch: {
    bookmarkModal: function () {
      this.fetchBookmarks();
    },
    selectedType: function () {
      this.fetchBookmarks();
    },
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(10px);
}
</style>
