<template>
  <div id="timeline">
    <div class="menu field has-addons">
      <p class="control">
        <button class="button is-" @click="move(0.2)">
          <span class="icon is-">
            <i class="fas fa-caret-left"></i>
          </span>
        </button>
      </p>
      <p class="control">
        <button class="button is-" @click="zoom(-0.2)">
          <span class="icon is-">
            <i class="fas fa-search-plus"></i>
          </span>
        </button>
      </p>
      <p class="control">
        <button class="button is-" @click="zoom(0.2)">
          <span class="icon is-">
            <i class="fas fa-search-minus"></i>
          </span>
        </button>
      </p>
      <p class="control">
        <button class="button" @click="move(-0.2)">
          <span class="icon">
            <i class="fas fa-caret-right"></i>
          </span>
        </button>
      </p>
    </div>
    <timeline-tool-bar />
  </div>
</template>
<script>
import { Timeline, DataSet } from "vis-timeline/standalone";
import { mapState, mapGetters, mapMutations } from "vuex";
import TimelineToolBar from "./TimelineToolBar.vue";

var currentDate = new Date();
var end = currentDate.getTime() + 12 * 60 * 60 * 1000;
var start = currentDate.getTime() - 36 * 60 * 60 * 1000;

var range_timer;

export default {
  name: "VueTimeline",
  data() {
    return {
      options: {
        height: 150,
        stack: false,
        start: start,
        end: end,
        min: new Date(2012, 0, 1), // lower limit of visible range
        max: end,
        zoomMin: 1000 * 60 * 60 * 12, // one day in milliseconds
        zoomMax: 1000 * 60 * 60 * 24 * 31 * 36,
        locale: "en",
      },
      timeline: {},
      items: {},
      itemsResponseData: [],
      bookmarks: [],
    };
  },
  components: {
    TimelineToolBar,
  },
  watch: {
    selectedSide: function () {
      if (this.selectedSide === "left") {
        this.timeline.setSelection(this.comparisonPhoto.timestamp);
      } else {
        this.timeline.setSelection(this.currentPhoto.timestamp);
      }
    },
    async selectedCamera() {
      this.clearData();
      await this.getTimelineItems();

      let timestamp =
        this.itemsResponseData[this.itemsResponseData.length - 1].id;
      await this.setCurrentPhoto(timestamp);
    },
    bookmarkModal: async function () {
      if (!this.bookmarkModal) {
        await this.getBookmarks();
        this.clearTimelineItemClasses();
        this.markTimelineBookmarks();
      }
    },
  },
  computed: {
    ...mapState([
      "selectedDate",
      "currentInterval",
      "currentPhoto",
      "isLoading",
      "selectedSide",
      "comparisonPhoto",
    ]),
    ...mapState({
      bookmarkModal: (state) => state.modals.bookmarkForm.isActive,
    }),
    ...mapGetters(["selectedCamera", "selectedProject"]),
    // watchProjects() {
    //   return this.$store.state.projects; // Replace with your Vuex module and variable names
    // },
    currentPhotoDate() {
      if (this.currentPhoto.timestamp) {
        return new Intl.DateTimeFormat("tr-TR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(this.currentPhoto.timestamp * 1000));
      }
      return "Select a photo";
    },
    comparisonPhotoDate() {
      if (this.comparisonPhoto.timestamp) {
        return new Intl.DateTimeFormat("tr-TR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(this.comparisonPhoto.timestamp * 1000));
      }
      return "Select a photo";
    },
  },
  methods: {
    ...mapMutations([
      "SET_IS_LOADING",
      "SET_CURRENT_PHOTO",
      "SET_COMPARISON_PHOTO",
      "SET_DATE_INTERVAL",
      "SET_SELECTED_SIDE",
      "SET_SELECTED_DATE",
    ]),
    clearData() {
      this.SET_CURRENT_PHOTO({
        timestamp: null,
        dziUrl: "",
        file: "",
        bookmark: {
          id: null,
          title: "",
          type: "personal",
        },
      });
      this.SET_COMPARISON_PHOTO({
        timestamp: null,
        dziUrl: "",
        file: "",
        bookmark: {
          id: null,
          title: "",
          type: "personal",
        },
      });
      this.items.clear();
      this.bookmarks = [];
    },
    selectSide(side) {
      this.SET_SELECTED_SIDE(side);
    },
    formatDate(date) {
      var dd = date.getDate(),
        mm = date.getMonth() + 1,
        yyyy = date.getFullYear();

      dd = dd < 10 ? "0" + dd : dd;
      mm = mm < 10 ? "0" + mm : mm;

      return yyyy + "-" + mm + "-" + dd;
    },
    async getTimelineItems() {
      let properties = this.timeline.getWindow();

      if (!this.selectedCamera) {
        return;
      }

      let start = this.formatDate(properties.start);
      let end = this.formatDate(properties.end);

      let response = await this.$http.get(`timeline/`, {
        params: {
          op: "display_timeline",
          cid: this.selectedCamera.id,
          start,
          end,
        },
      });
      this.items.clear();
      this.items.add(response.data);
      this.itemsResponseData = response.data;
      if (!response.data) return;
    },
    clearTimelineItemClasses() {
      this.items.forEach((item) => {
        this.items.update({
          id: item.id,
          className: "",
          title: "",
        });
      });
    },
    markTimelineBookmarks() {
      if (this.bookmarks.length > 0) {
        this.bookmarks.forEach((bm) => {
          let start = new Date(bm.timestamp * 1000);

          this.items.update({
            id: parseInt(bm.timestamp),
            type: "point",
            start: start,
            title: bm.title,
            className: "bookmark-" + bm.type,
          });
        });
      }
    },
    async getBookmarks() {
      let props = this.timeline.getWindow();
      let start = new Date(props.start);
      let end = new Date(props.end);
      start = Math.floor(start.getTime() / 1000);
      end = Math.floor(end.getTime() / 1000);

      let res = await this.$http.get("bookmark/", {
        params: {
          cid: this.selectedCamera.id,
          start,
          end,
          type: "all",
          op: "list_bookmarks",
        },
      });
      this.bookmarks = res.data;
    },
    getInterval() {
      let range = this.timeline.getWindow();
      let interval = range.end - range.start;

      return interval;
    },
    move(percentage) {
      var range = this.timeline.getWindow();
      var interval = range.end - range.start;
      this.SET_DATE_INTERVAL(interval);

      this.timeline.setWindow({
        start: range.start.valueOf() - interval * percentage,
        end: range.end.valueOf() - interval * percentage,
      });
    },
    zoom(percentage) {
      var range = this.timeline.getWindow();
      var interval = range.end - range.start;

      this.timeline.setWindow({
        start: range.start.valueOf() - interval * percentage,
        end: range.end.valueOf() + interval * percentage,
      });

      range = this.timeline.getWindow();
      interval = range.end - range.start;

      this.SET_DATE_INTERVAL(interval);
    },
    async getTileMeta(timestamp) {
      this.SET_IS_LOADING(true);
      let response = await this.$http.get("dzi/", {
        params: {
          op: "view_tiles",
          cid: this.selectedCamera.id,
          t: timestamp,
        },
      });
      return response.data;
    },
    async setCurrentPhoto(timestamp) {
      let photo = await this.getTileMeta(timestamp);

      let payload = {
        timestamp,
        dziUrl: photo.dzi.url,
        file: photo.file,
        bookmark: this.bookmarkSingleTimelineItem(photo.bookmark),
      };

      this.SET_CURRENT_PHOTO(payload);
      this.SET_COMPARISON_PHOTO(payload);
      this.timeline.setSelection(timestamp);
    },
    loadTiles(photo, timestamp) {
      if (photo == null || photo.file == "") {
        return;
      }

      let payload = {
        timestamp,
        dziUrl: photo.dzi.url,
        file: photo.file,
        bookmark: this.bookmarkSingleTimelineItem(photo.bookmark),
      };

      if (this.$route.name === "Comparison" || this.$route.name === "Sync") {
        if (this.selectedSide === "left") {
          this.SET_COMPARISON_PHOTO(payload);
        } else {
          this.SET_CURRENT_PHOTO(payload);
        }
        this.$root.$emit("timeline-item-selected", this.selectedSide);
      } else {
        this.SET_CURRENT_PHOTO(payload);
      }
      setTimeout(() => {
        this.SET_IS_LOADING(false);
      }, 1000);
    },
    async markSelectedTimelineItem() {
      let timestamp = this.currentPhoto.timestamp;
      if (
        (this.$route.name === "Comparison" || this.$route.name === "Sync") &&
        this.selectedSide === "left"
      ) {
        timestamp = this.comparisonPhoto.timestamp;
      }
      if (timestamp === null) {
        let tl = Object.keys(this.timeline.itemSet.items).sort();
        timestamp = tl[tl.length - 1];
      }
      this.timeline.setSelection(timestamp);
      return timestamp;
    },
    moveTimelineToSelectedDate() {
      this.timeline.setWindow({
        start: this.selectedDate,
        end: this.selectedDate.valueOf() + this.currentInterval,
      });
    },
    focusTimeline(timestamp) {
      this.timeline.focus(timestamp, {
        zoom: false,
        animation: {
          duration: 2000,
          easingFunction: "easeOutCubic",
        },
      });
    },
    bookmarkSingleTimelineItem(bm) {
      let bookmark;

      if (bm) {
        bookmark = {
          id: bm.id,
          title: bm.title,
          type: bm.type,
        };
      } else {
        bookmark = {
          id: 0,
          title: "",
          type: "personal",
        };
      }
      return bookmark;
    },
  },
  mounted() {
    this.items = new DataSet();
    var groups = this.groups;
    this.timeline = new Timeline(this.$el, this.items, groups, this.options);

    let interval = this.options.end - this.options.start;

    this.SET_DATE_INTERVAL(interval);

    this.timeline.on("select", async (properties) => {
      if (!properties.items.length) return;
      let timestamp = properties.items[0];
      this.focusTimeline(timestamp);

      let photo = await this.getTileMeta(timestamp);
      this.loadTiles(photo, timestamp);
    });

    this.items.on("add", async () => {
      let timestamp = await this.markSelectedTimelineItem();

      if (timestamp != this.currentPhoto.timestamp) {
        let tileData = await this.getTileMeta(timestamp);
        this.loadTiles(tileData, timestamp);
      }
    });

    this.timeline.on("rangechanged", (properties) => {
      let mid = new Date(
        (properties.start.getTime() + properties.end.getTime()) / 2
      );
      this.SET_SELECTED_DATE(mid);

      clearTimeout(range_timer);
      range_timer = setTimeout(() => {
        this.getTimelineItems();
      }, 1000);
    });
    this.$root.$on("date-changed", () => {
      this.moveTimelineToSelectedDate();
    });
  },
};
</script>
<style>
#timeline .menu {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 29;
  opacity: 0.5;
  transition: opacity 1s;
}

#timeline .vis-current-time {
  position: absolute;
  top: 0px;
  height: 100%;
  width: 5px;
  box-shadow: rgb(85, 85, 85) 1px 0px 10px;
}
#timeline .vis-item.vis-dot.bookmark-personal {
  color: white;
  background-color: hsl(348, 100%, 61%);
  border-color: hsl(348, 100%, 61%);
}
#timeline .vis-item.vis-dot.bookmark-general {
  color: white;
  background-color: hsl(217, 71%, 53%);
  border-color: hsl(217, 71%, 53%);
}
#timeline .menu:hover {
  opacity: 1;
}

#timeline .vis-text {
  font-size: small;
}

#timeline .vis-grid.vis-even {
  background: #fafafa;
}

#timeline .vis-text.vis-major {
  font-weight: bold;
}

#timeline .vis-item.vis-dot {
  border-radius: 3px;
  height: 35px;
  cursor: pointer;
}
#timeline .vis-item-content {
  margin-left: 0px !important;
  padding: 0px !important;
}
</style>
