<template>
  <div class="hero-body is-relative has-background-black">
    <transition name="fade">
      <Loading v-if="isLoading" />
    </transition>
    <div id="viewport1" class="viewport"></div>
    <div id="viewport2" class="viewport"></div>
  </div>
</template>
<style scoped></style>
<script>
import OpenSeadragon from "openseadragon";
import Loading from "@/components/Loading.vue";
import { mapState, mapMutations } from "vuex";

export default {
  name: "ComparisonViewport",
  // props: ["selectedPhotoURL", "size"],
  components: {
    Loading,
  },
  data() {
    return {
      viewer1: null,
      viewer2: null,
      leftImage: null,
      rightImage: null,
      viewer1Leading: false,
      viewer2Leading: false,
    };
  },
  computed: {
    ...mapState([
      "currentPhoto",
      "isLoading",
      "selectedSide",
      "comparisonPhoto",
    ]),
  },
  watch: {
    clientHeight: function () {
      this.resizeViewPort();
    },
  },
  methods: {
    ...mapMutations(["SET_SELECTED_SIDE"]),
    resizeViewPort: function () {
      let timeline = 150;
      let navbar = 56;
      let totalHeight = document.documentElement.clientHeight;

      document.querySelector("#viewport1").style.height =
        totalHeight - (timeline + navbar + 20) + "px";
      document.querySelector("#viewport2").style.height =
        totalHeight - (timeline + navbar + 20) + "px";

      // document.querySelector("#comparison-viewport").style.width =
      //   document.documentElement.clientWidth + "px";
    },

    initViewer: function () {
      let viewer1 = document.getElementById("viewport1");
      let viewer2 = document.getElementById("viewport2");

      this.viewer1 = OpenSeadragon({
        element: viewer1,
        // xmlns: "http://schemas.microsoft.com/deepzoom/2008",
        // prefixUrl: "//openseadragon.github.io/openseadragon/images/",
        // zoomPerClick: this.imageData.isSliderZoomable ? 1.3 : 1, // 2.0
        showFullPageControl: false,
        showRotationControl: false,
        showSequenceControl: false,
        showZoomControl: false,
        showHomeControl: false,
        // toolbar: "toolbarDiv",
        // homeButton: "home",
        // fullPageButton: "full-page",
        preserveViewport: "preserveViewport",
        constrainDuringPan: true,
        visibilityRatio: 1,
        // showNavigator: true,
      });

      this.viewer2 = OpenSeadragon({
        element: viewer2,
        // xmlns: "http://schemas.microsoft.com/deepzoom/2008",
        // prefixUrl: "//openseadragon.github.io/openseadragon/images/",
        // zoomPerClick: this.imageData.isSliderZoomable ? 1.3 : 1, // 2.0
        // showZoomControl: this.imageData.isSliderZoomable,
        showFullPageControl: false,
        showRotationControl: false,
        showSequenceControl: false,
        showZoomControl: false,
        showHomeControl: false,
        // toolbar: "toolbarDiv",
        // homeButton: "home",
        // fullPageButton: "full-page",
        preserveViewport: "preserveViewport",
        constrainDuringPan: true,
        visibilityRatio: 1,
        showNavigator: true,
      });

      if (this.currentPhoto.dziUrl) {
        this.viewer2.addTiledImage({
          tileSource: this.currentPhoto.dziUrl,
          success: (event) => {
            this.rightImage = event.item;
          },
        });
      }
      if (this.comparisonPhoto.dziUrl) {
        this.viewer1.addTiledImage({
          tileSource: this.comparisonPhoto.dziUrl,
          success: (event) => {
            this.leftImage = event.item;
          },
        });
      }
    },
    viewer1Handler: function () {
      // console.log("viewer 1 is leading: ", this.viewer1Leading);
      if (this.viewer2Leading) {
        return;
      }
      this.SET_SELECTED_SIDE("left");

      this.viewer1Leading = true;
      this.viewer2.viewport.zoomTo(this.viewer1.viewport.getZoom());
      this.viewer2.viewport.panTo(this.viewer1.viewport.getCenter());
      this.viewer1Leading = false;
    },
    viewer2Handler: function () {
      if (this.viewer1Leading) {
        return;
      }

      this.SET_SELECTED_SIDE("right");

      this.viewer2Leading = true;
      this.viewer1.viewport.zoomTo(this.viewer2.viewport.getZoom());
      this.viewer1.viewport.panTo(this.viewer2.viewport.getCenter());
      this.viewer2Leading = false;
    },
  },
  mounted() {
    // if (this.viewer1 !== null) return;
    //
    window.addEventListener("resize", () => {
      if (this.$route.name === "Timeline") {
        this.resizeViewPort();
      }
    });

    this.resizeViewPort();

    this.initViewer();

    this.viewer1.addHandler("zoom", this.viewer1Handler);
    this.viewer2.addHandler("zoom", this.viewer2Handler);
    this.viewer1.addHandler("pan", this.viewer1Handler);
    this.viewer2.addHandler("pan", this.viewer2Handler);

    this.$root.$on("timeline-item-selected", (side) => {
      let tileSource =
        side === "left"
          ? this.comparisonPhoto.dziUrl
          : this.currentPhoto.dziUrl;
      let viewer = side === "left" ? this.viewer1 : this.viewer2;
      viewer.addTiledImage({
        tileSource,
        success: (event) => {
          this[side + "Image"] = event.item;
        },
      });
    });
  },
};
</script>

<style scoped>
#viewport1 {
  width: 50%;
  float: left;
}
#viewport2 {
  width: 50%;
  float: left;
}
#toolbarDiv {
  z-index: 29;
  position: absolute !important;
  top: 0px;
  left: 0px;
  opacity: 0.3;
  padding: 15px;
  transition: opacity 1s;
}

#toolbarDiv:hover {
  opacity: 1;
}

.hero-body {
  padding: 0.5rem;
}
.fade-enter-active {
  opacity: 0.7;
  transition: opacity 2s ease;
}
.fade-leave-active {
  opacity: 0;
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.blur {
  filter: blur(10px);
  transition: filter 2s ease;
}
.center {
  z-index: 25;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-body {
  padding: 0.5rem;
}
</style>
