var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section",staticStyle:{"min-height":"100vh"}},[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("file.list.title")))])]),_c('div',{staticClass:"level-right"},[_c('button',{class:['button', 'is-link', _vm.isLoading ? 'is-loading' : ''],attrs:{"title":_vm.$t('file.list.refresh')},on:{"click":_vm.loadFiles}},[_vm._m(0)])])]),_c('h1',{class:[
      'has-text-grey-dark',
      'subtitle',
      _vm.files.length ? '' : 'is-invisible',
    ]},[_vm._v(" "+_vm._s(_vm.$tc("file.list.subtitle", _vm.files.length, { count: _vm.files.length }))+" ")]),_c('table',{staticClass:"table is-bordered is-striped is-hoverable is-fullwidth mt-5"},[_c('thead',[_c('th',{staticClass:"has-text-centered",attrs:{"colspan":"2"}}),_c('th',[_vm._v(_vm._s(_vm.$t("file.list.date")))]),_c('th',[_vm._v(_vm._s(_vm.$t("file.list.time")))]),_c('th',[_vm._v(_vm._s(_vm.$t("file.list.operations")))])]),(_vm.files.length && !_vm.isLoading)?_c('tbody',_vm._l((_vm.files),function(file,i){return _c('tr',{key:file.file},[_c('td',[_vm._v(_vm._s(i + 1))]),_c('td',[_c('p',{staticClass:"control"},[_c('button',{class:[
                'button',
                'is-white',
                file.bookmark.type === 'personal'
                  ? 'has-text-danger'
                  : 'has-text-info',
              ],attrs:{"title":file.bookmark.title ? file.bookmark.title : 'Bookmark'},on:{"click":function($event){return _vm.openBookmarkModal(file)}}},[_c('span',{staticClass:"icon"},[_c('i',{class:[file.bookmark.id ? 'fas' : 'far', 'fa-bookmark']})])])])]),_c('td',[_vm._v(_vm._s(_vm.formattedDate))]),_c('td',[_vm._v(_vm._s(_vm._f("time")(file.file)))]),_c('td',[_c('div',{staticClass:"field has-addons"},[_c('p',{staticClass:"control"},[_c('button',{staticClass:"button",on:{"click":function($event){return _vm.downloadPhoto(file.file)}}},[_vm._m(1,true)])]),_c('p',{staticClass:"control"},[_c('button',{staticClass:"button",on:{"click":function($event){return _vm.share(file.file)}}},[_vm._m(2,true)])]),_vm._m(3,true),_vm._m(4,true)])])])}),0):(_vm.isLoading)?_c('tbody',[_c('tr',[_c('td',{staticClass:"has-text-centered",attrs:{"colspan":"5"}},[_vm._v(" "+_vm._s(_vm.$t("file.list.loading"))+" ")])])]):_c('tbody',[_c('tr',[_c('td',{staticClass:"has-text-centered",attrs:{"colspan":"5"}},[_vm._v(" "+_vm._s(_vm.$t("file.list.empty"))+" ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-sync"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-download"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-share-alt"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"control"},[_c('button',{staticClass:"button has-text-link",attrs:{"disabled":"","title":"Yakında..."}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"far fa-sticky-note"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"control"},[_c('button',{staticClass:"button",attrs:{"disabled":"","title":"Yakında..."}},[_c('span',{staticClass:"icon has-text-danger"},[_c('i',{staticClass:"fas fa-trash"})])])])
}]

export { render, staticRenderFns }