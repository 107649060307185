<template>
  <div class="navbar-item has-dropdown is-hoverable">
    <div
      class="navbar-link has-text-weight-semibold is-flex is-flex-direction-column is-justify-content-flex-start"
      @click="expandMenu"
    >
      <div class="is-size-6">
        {{ fullName }}
      </div>
      <div class="is-size-7 has-text-grey-lighter" style="width: 100%">
        Admin
      </div>
    </div>

    <div class="navbar-dropdown is-right has-background-link">
      <a class="navbar-item" @click="showModalUserProfile = true" href="#">{{ $t("menu.profile") }}</a>
      <a class="navbar-item" @click="openUsersPage">{{ $t("menu.users") }}</a>

      <a class="navbar-item" @click="openCameras">{{ $t("menu.cameras") }}</a>
      <a class="navbar-item" @click="openSubscription">{{ $t("menu.subscription") }}</a>
      <a class="navbar-item" @click="openProjects">{{ $t("menu.projects") }}</a>
      <a class="navbar-item" @click="openContracts">{{ $t("menu.contracts") }}</a>
      <a class="navbar-item" @click="openInvoices">{{ $t("menu.invoices") }}</a>
      <a class="navbar-item" @click="openPayments">{{ $t("menu.payments") }}</a>
      <a class="navbar-item" @click="openCreditCards">{{ $t("menu.creditCards") }}</a>
      <a @click="openBookmarksPage" class="navbar-item">
        {{ $t("menu.bookmarks") }}
      </a>
      <a class="navbar-item" @click="logout" href="#">
        {{ $t("menu.logout") }}
      </a>
      <a
        v-if="this.companies.length > 1"
        class="navbar-item"
        @click="openSelectCompanyModal"
      >
        {{ $t("menu.selectCompany") }}
      </a>
      <a class="navbar-divider"></a>
      <a class="navbar-item" @click="SET_MODAL_STATE_LANGUAGE_SELECTOR(true)">
        <span class="icon">
          <i class="fas fa-globe"></i>
        </span>
        <span>{{ $t("menu.language") }}</span>
      </a>
    </div>
    <transition name="fade">
      <ModalUserProfile
        v-if="showModalUserProfile"
        @close="showModalUserProfile = false"
      />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      showModalUserProfile: false,
    };
  },
  computed: {
    ...mapState(["user", "companies"]),
    fullName() {
      return this.user.fullName;
    },
    initials() {
      return this.user.fullName
        .split(" ")
        .map((word) => word[0])
        .join("");
    },
  },
  components: {
    ModalUserProfile: () => import("@/components/ModalUserProfile.vue"),
  },
  methods: {
    ...mapActions(["logout", "openUsersPage"]),
    ...mapMutations([
      "SET_MODAL_STATE_CHANGE_PASSWORD",
      "SET_MODAL_STATE_SELECT_COMPANY",
      "SET_MODAL_STATE_LANGUAGE_SELECTOR",
    ]),
    openProfileSettings() {
      this.closeMenu();
      this.$router.push({ name: "ProfileSettings" });
    },
    openBookmarksPage() {
      if (this.currentRoute === "Bookmarks") {
        this.closeMenu();
        return;
      }
      this.$router.push({ name: "Bookmarks" });
    },
    openChangePasswordModal() {
      this.SET_MODAL_STATE_CHANGE_PASSWORD(true);
      this.closeMenu();
    },
    openSelectCompanyModal() {
      this.SET_MODAL_STATE_SELECT_COMPANY(true);
      this.closeMenu();
    },

    openCameraSettingsPage() {
      this.closeMenu();
      this.$router.push({ name: "CameraSettings" });
    },
    openCameras() {
      this.closeMenu();
      this.$router.push({ name: "CameraList" });
    },
    openProjects() {
      this.closeMenu();
      this.$router.push({ name: "ProjectList" });
    },
    openCompanies() {
      this.closeMenu();
      this.$router.push({ name: "CompanyList" });
    },
    openSubscription() {
      this.closeMenu();
      this.$router.push({ name: "Subscription" });
    },
    openContracts() {
      this.closeMenu();
      this.$router.push({ name: "Contracts" });
    },
    openInvoices() {
      this.closeMenu();
      this.$router.push({ name: "Invoices" });
    },
    openPayments() {
      this.closeMenu();
      this.$router.push({ name: "Payments" });
    },
    openCreditCards() {
      this.closeMenu();
      this.$router.push({ name: "CreditCards" });
    },

    expandMenu(event) {
      event.target.closest(".has-dropdown").classList.toggle("is-active");
    },
    closeMenu() {
      document.querySelector(".navbar-menu").classList.remove("is-active");
      const $navbarBurgers = Array.prototype.slice.call(
        document.querySelectorAll(".navbar-burger"),
        0
      );

      if ($navbarBurgers.length > 0) {
        $navbarBurgers.forEach((el) => {
          el.classList.toggle("is-active");
        });
      }
      document
        .querySelectorAll(".navbar-item.has-dropdown.is-active")
        .forEach((el) => {
          el.classList.remove("is-active");
        });
    },
  },
};
</script>

<style></style>
