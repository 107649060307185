var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"level notification"},[(_vm.user.isActive)?_c('div',{staticClass:"level-item"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('user.operations.status.active'))}})]):_c('div',{staticClass:"level-item"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('user.operations.status.inactive'))}})]),_c('div',{staticClass:"level-item"},[_c('button',{class:[
          'button',
          _vm.user.isActive ? '' : 'is-info',
          _vm.buttons.toggleStatus.isLoading ? 'is-loading' : '',
        ],domProps:{"innerHTML":_vm._s(
          _vm.user.isActive
            ? _vm.$i18n.t('user.operations.deactivate')
            : _vm.$i18n.t('user.operations.activate')
        )},on:{"click":_vm.toggleStatus}})])]),_c('div',{staticClass:"level notification"},[_c('div',{staticClass:"level-item",domProps:{"innerHTML":_vm._s(_vm.$t('user.operations.password.resetText'))}}),_c('div',{staticClass:"level-item"},[_c('button',{class:[
          'button',
          'is-warning',
          _vm.buttons.resetPassword.isLoading ? 'is-loading' : '',
        ],on:{"click":_vm.resetPassword}},[_vm._v(" "+_vm._s(_vm.$t("user.operations.password.resetButton"))+" ")])])]),_c('div',{staticClass:"level notification"},[_c('div',{staticClass:"level-item"},[_vm._v(" "+_vm._s(_vm.$t("user.operations.removeText"))+" ")]),_c('div',{staticClass:"level-item"},[_c('button',{class:[
          'button',
          'is-danger',
          _vm.buttons.delete.isLoading ? 'is-loading' : '',
        ],on:{"click":_vm.deleteUser}},[_vm._v(" "+_vm._s(_vm.$t("user.operations.removeButton"))+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }