<template>
  <section class="section" style="min-height: 100vh">
    <div class="level">
      <div class="level-left">
        <h1 class="title">{{ $t("file.list.title") }}</h1>
      </div>
      <div class="level-right">
        <button
          :title="$t('file.list.refresh')"
          :class="['button', 'is-link', isLoading ? 'is-loading' : '']"
          @click="loadFiles"
        >
          <span class="icon"><i class="fas fa-sync"></i></span>
        </button>
      </div>
    </div>
    <h1
      :class="[
        'has-text-grey-dark',
        'subtitle',
        files.length ? '' : 'is-invisible',
      ]"
    >
      {{ $tc("file.list.subtitle", files.length, { count: files.length }) }}
    </h1>

    <table class="table is-bordered is-striped is-hoverable is-fullwidth mt-5">
      <thead>
        <th colspan="2" class="has-text-centered"></th>
        <th>{{ $t("file.list.date") }}</th>
        <th>{{ $t("file.list.time") }}</th>
        <th>{{ $t("file.list.operations") }}</th>
      </thead>
      <tbody v-if="files.length && !isLoading">
        <tr v-for="(file, i) in files" :key="file.file">
          <td>{{ i + 1 }}</td>
          <td>
            <p class="control">
              <button
                :class="[
                  'button',
                  'is-white',
                  file.bookmark.type === 'personal'
                    ? 'has-text-danger'
                    : 'has-text-info',
                ]"
                :title="file.bookmark.title ? file.bookmark.title : 'Bookmark'"
                @click="openBookmarkModal(file)"
              >
                <span class="icon"
                  ><i
                    :class="[file.bookmark.id ? 'fas' : 'far', 'fa-bookmark']"
                  ></i
                ></span>
              </button>
            </p>
          </td>
          <td>{{ formattedDate }}</td>
          <td>{{ file.file | time }}</td>
          <td>
            <div class="field has-addons">
              <p class="control">
                <button class="button" @click="downloadPhoto(file.file)">
                  <span class="icon"><i class="fas fa-download"></i></span>
                </button>
              </p>
              <p class="control">
                <button class="button" @click="share(file.file)">
                  <span class="icon"><i class="fas fa-share-alt"></i></span>
                </button>
              </p>
              <p class="control">
                <button
                  class="button has-text-link"
                  disabled
                  title="Yakında..."
                >
                  <span class="icon"><i class="far fa-sticky-note"></i></span>
                </button>
              </p>
              <p class="control">
                <button class="button" disabled title="Yakında...">
                  <span class="icon has-text-danger"
                    ><i class="fas fa-trash"></i
                  ></span>
                </button>
              </p>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="isLoading">
        <tr>
          <td class="has-text-centered" colspan="5">
            {{ $t("file.list.loading") }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td class="has-text-centered" colspan="5">
            {{ $t("file.list.empty") }}
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      files: [],
      isLoading: false,
      showAllBookmarks: false,
    };
  },
  name: "FileList",
  computed: {
    ...mapState({
      user: (state) => state.user,
      selectedDate: (state) => state.selectedDate,
      bookmarkModal: (state) => state.modals.bookmarkForm,
      shareModal: (state) => state.modals.photoShare,
    }),
    ...mapGetters(["selectedCamera"]),
    formattedDate: function () {
      let d = new Date(this.selectedDate);
      let day = ("0" + d.getDate()).slice(-2);
      let month = ("0" + (d.getMonth() + 1)).slice(-2);
      return `${day}.${month}.${d.getFullYear()}`;
    },
    bookmarkModalisActive: function () {
      return this.bookmarkModal.isActive;
    },
  },
  filters: {
    time: function (file) {
      return file.slice(0, 2) + ":" + file.slice(2, 4) + ":" + file.slice(4, 6);
    },
  },
  methods: {
    ...mapMutations([
      "SET_MODAL_STATE_PHOTO_SHARE",
      "SET_CURRENT_PHOTO",
      "SET_MODAL_STATE_BOOKMARK",
    ]),
    ...mapActions(["download"]),
    openBookmarkModal(file) {
      if (!this.user.permissions.includes(this.bookmarkModal.permission)) {
        this.$showToastWithType(
          this.$i18n.t("message.error.noFunctionPermission"),
          "error"
        );
        return;
      }
      this.SET_CURRENT_PHOTO({
        timestamp: this.getTimestamp(file.file),
        file: file.file,
        dzi: null,
        bookmark: {
          id: file.bookmark.id,
          type: file.bookmark.type,
          title: file.bookmark.title,
        },
      });
      this.SET_MODAL_STATE_BOOKMARK(true);
    },
    getTimestamp(time) {
      let d = new Date(this.selectedDate);
      d.setHours(time.slice(0, 2));
      d.setMinutes(time.slice(2, 4));
      d.setSeconds(time.slice(4, 6));
      return d.getTime() / 1000;
    },

    downloadPhoto(time) {
      this.SET_CURRENT_PHOTO({
        timestamp: this.getTimestamp(time),
        dzi: null,
        file: "",
        bookmark: {
          id: 0,
          title: "",
          type: "personal",
        },
      });

      this.download();
    },
    share(time) {
      if (!this.user.permissions.includes(this.shareModal.permission)) {
        this.$showToastWithType(
          this.$i18n.t("message.error.noSharePermission"),
          "error"
        );
        return;
      }
      this.SET_CURRENT_PHOTO({
        timestamp: this.getTimestamp(time),
        dzi: null,
        file: "",
        bookmark: {
          id: 0,
          title: "",
          type: "personal",
        },
      });

      this.SET_MODAL_STATE_PHOTO_SHARE(true);
    },
    loadFiles() {
      if (!this.selectedCamera) {
        console.log("No camera selected");
        return;
      }
      this.files = [];
      this.isLoading = true;
      let d = new Date(this.selectedDate);
      let day = ("0" + d.getDate()).slice(-2);
      let month = ("0" + (d.getMonth() + 1)).slice(-2);
      let date = `${d.getFullYear()}/${month}/${day}`;

      this.$http
        .get("list-photos/", {
          params: {
            op: "list_photos",
            cid: this.selectedCamera.id,
            date,
          },
        })
        .then(async (res) => {
          res.data.forEach((file) => {
            let photo = {
              file: file,
              timestamp: this.getTimestamp(file),
              bookmark: {
                id: 0,
                title: "",
                type: "personal",
              },
            };
            this.files.push(photo);
          });
          this.isLoading = false;
          await this.loadBookmarks();
        })
        .catch((e) => {
          this.isLoading = false;
          console.log(e);
        });
    },
    getOffset() {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const timeZoneName = Intl.DateTimeFormat("ia", {
        timeZoneName: "short",
        timeZone,
      })
        .formatToParts()
        .find((i) => i.type === "timeZoneName").value;
      const offset = timeZoneName.slice(3);
      if (!offset) return 0;

      const matchData = offset.match(/([+-])(\d+)(?::(\d+))?/);
      if (!matchData) throw `cannot parse timezone name: ${timeZoneName}`;

      const [, sign, hour, minute] = matchData;
      let result = parseInt(hour) * 60;
      if (sign === "+") result *= -1;
      if (minute) result += parseInt(minute);

      return result;
    },
    getCurrentDateTimeInTimezone(timeString) {
      var timeStringParsed = timeString.toString().match(/.{1,2}/g);
      var now = new Date(this.selectedDate);

      now.setUTCHours(parseInt(timeStringParsed[0]));
      now.setUTCMinutes(parseInt(timeStringParsed[1]));
      now.setUTCSeconds(parseInt(timeStringParsed[2]));

      var offsetMilliseconds = -180 * 60 * 1000;
      var newDate = new Date(now.getTime() + offsetMilliseconds);
      var formattedDate = newDate.toISOString();
      var convertedDate = this.convertTZ(
        formattedDate,
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );
      return (
        convertedDate.getHours().toString().padStart(2, "0") +
        ":" +
        convertedDate.getMinutes().toString().padStart(2, "0") +
        ":" +
        convertedDate.getSeconds().toString().padStart(2, "0")
      );
    },
    convertTZ(date, tzString) {
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          { timeZone: tzString }
        )
      );
    },
    loadBookmarks() {
      this.bookmarks = [];
      let start = new Date(this.selectedDate);
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);
      start = Math.floor(start.getTime() / 1000);
      let end = start + 86400;
      let type = "all";
      this.$http
        .get("bookmark/", {
          params: {
            op: "list_bookmarks",
            cid: this.selectedCamera.id,
            start,
            end,
            type,
          },
        })
        .then((res) => {
          this.files.filter((file) => {
            file.bookmark.id = 0;
            file.bookmark.title = "";
            file.bookmark.type = "personal";
          });
          if (!res.data) return;
          res.data.forEach((bookmark) => {
            this.files.find((file) => {
              if (file.file == bookmark.filename) {
                file.bookmark = bookmark;
              }
            });
          });
          this.bookmarks = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  watch: {
    selectedDate: function () {
      this.loadFiles();
    },
    selectedCamera: function () {
      this.loadFiles();
    },
    bookmarkModalisActive: function () {
      this.loadBookmarks();
    },
  },
  mounted() {
    this.loadFiles();
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(10px);
}
</style>
