<template>
    <div>
      <Navbar />
      <section class="section has-background-white" style="min-height: 100vh;">
        <div class="card">
            <span class="title">{{$t('contract.current_contract')}}</span>
            <p class="description">{{$t('contract.current_plan')}}: {{currentContract.package_name}}</p>
            <p class="description">{{$t('contract.status')}}: {{currentContract.status}}</p>
            <p class="description">{{$t('contract.start_date')}}: {{currentContract.start_date}}</p>
            <p v-if="currentContract.end_date" class="description">{{$t('contract.end_date')}}: {{currentContract.end_date}}</p>
            
        </div>
        <section class="section has-background-white">
          <div class="card">
          <span class="title">{{$t('contract.contracts')}}</span>
        
          <table style="margin-top: 15px;" class="table is-fullwidth is-bordered is-striped is-hoverable">
            <thead>
              <th>{{$t('contract.package_name')}}</th>
              <th>{{$t('contract.start_date')}}</th>
              <th>{{$t('contract.end_date')}}</th>
              <th>{{$t('contract.amount')}}</th>
              <th>{{$t('contract.currency')}}</th>
            </thead>
            <tbody v-if="contracts.length && !isLoading">
              <tr v-for="contract in contracts" :key="contract.id">
                <td>{{ contract.package_name }}</td>
                <td>{{ contract.start_date }}</td>
                <td>{{ contract.end_date }}</td>
                <td>{{ contract.amount }}</td>
                <td>{{ contract.currency }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td></td>
                <td class="has-text-centered" colspan="6">{{ $t("contract.not_found") }}</td>
              </tr>
            </tbody>
          </table>
          </div>
        </section> 
      </section>   
     
    </div>
  </template>  
  <script>
  import Navbar from "@/components/NavBar.vue";
  import { mapState, mapActions, mapGetters} from "vuex";
  
  export default {
    name: "Contract",
    data() {
      return {
        currentContract:{},
        contracts: [],
        isLoading: false,
      };
    },
    components: {
    Navbar
    },
    computed: {
    ...mapGetters(["selectedCompany"]),  
      ...mapState({
        user: (state) => state.user,
        companies: (state) => state.companies,
      }),
    },
    methods: {
      ...mapActions(["refreshState"]),
      openForm(id,name) {
        this.companyId = id;
        this.companyName = name;
        this.isFormActive = true;
      },
      closeForm(){
        this.isFormActive = false;
      },
      createOrUpdate(){
        this.isLoading = true;
        let formData = new FormData();
        if (this.companyId==0) {
          formData.append("op", "create_company");
          formData.append("name", this.companyName);
          this.$http.post("company",formData);
        }
        if (this.companyId>0) {
          formData.append("op", "update_company");
          formData.append("id", this.companyId);
          formData.append("name", this.companyName);
          this.$http.put("company",formData);
        }
        this.refreshState();
        this.isLoading = false;
        this.closeForm();
      },
      remove(id) {
        this.isLoading = true
        this.$http
          .delete("company",{
            params: {
              op: "delete_company",
              id: id,
            },
          })
           .finally(() => {
            this.refreshState();
            this.isLoading = false;
          });
      },
      convertDate(timestamp) {
        const dateObject = new Date(timestamp);
        const formattedTime = dateObject.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        const formattedDate = dateObject.toLocaleDateString([], { day: '2-digit', month: '2-digit', year: 'numeric' });
        return `${formattedTime} ${formattedDate}`;
      },
    },
    mounted() {
      this.companyName = "";
      this.companyId = 0;
      this.isFormActive = false;

      this.$http.get("current-contract").then((res) => {
            this.currentContract = res.data;
            this.currentContract.start_date = this.convertDate(this.currentContract.start_date);
            this.currentContract.end_date = this.convertDate(this.currentContract.end_date);
            if (this.currentContract.end_date.Valid) {
              this.currentContract.end_date = this.convertDate(this.currentContract.end_date);
            }else{
              this.currentContract.end_date = ""
            }
          
            console.log(this.currentContract.status);
            if (this.currentContract.status == 'active') {
              this.currentContract.status = this.$t('contract.active_description');
            }
            if (this.currentContract.status == 'expired') {
              this.currentContract.status = this.$t('contract.expired_description');
            }
            if (this.currentContract.status == 'over_due_payment') {
              this.currentContract.status = this.$t('contract.over_due_payment_description');
            }
      });

      this.$http.get("contracts?page=1&size=10").then((res) => {
            this.contracts = res.data.data;
            for (var i=0;i<this.contracts.length;i++) {
              this.contracts[i].start_date = this.convertDate(this.contracts[i].start_date);
              if (this.contracts[i].end_date.Valid) {
                this.contracts[i].end_date = this.convertDate(this.contracts[i].end_date.Time);
              }else {
                this.contracts[i].end_date = ""
              } 
            }
      });
    },
  };
  </script>
