<template>
  <section class="section" style="min-height: 100vh">
    <div class="level">
      <div class="level-left">
        <h1 class="title">{{ $t("company.companyListPageHeader") }}</h1>
      </div>
      <div class="level-right">
        <div class="field is-grouped">
          <p class="control">
            <button
              :title="$t('company.create')"
              class="button is-link"
              @click="openForm(0, '')"
            >
              <span class="icon"><i class="fas fa-plus"></i></span>
            </button>
          </p>
        </div>
      </div>
    </div>
    <table class="table is-fullwidth is-bordered is-striped is-hoverable mt-5">
      <thead>
        <th>{{ $t("company.name") }}</th>
        <th></th>
      </thead>
      <tbody v-if="companies.length && !isLoading">
        <tr v-for="company in companies" :key="company.id">
          <td>{{ company.name }}</td>
          <td>
            <div class="field is-grouped">
              <p class="control">
                <button
                  :title="$t('company.edit')"
                  class="button is-small is-link is-outlined"
                  @click="openForm(company.id, company.name)"
                >
                  <span class="icon is-small">
                    <i class="fas fa-edit"></i>
                  </span>
                </button>
              </p>
              <p class="control">
                <button
                  :title="$t('company.delete')"
                  class="button is-small is-danger is-outlined"
                  @click="remove(company.id)"
                >
                  <span class="icon is-small">
                    <i class="fas fa-trash"></i>
                  </span>
                </button>
              </p>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="isLoading">
        <tr>
          <td></td>
          <td class="has-text-centered" colspan="6">
            {{ $t("company.listLoading") }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td></td>
          <td class="has-text-centered" colspan="6">
            {{ $t("company.noData") }}
          </td>
        </tr>
      </tbody>
    </table>
    <div :class="['modal', isFormActive ? 'is-active' : '']">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            <slot name="title">
              {{ companyId ? $t("company.update") : $t("company.create") }}
            </slot>
          </p>
          <button
            class="delete"
            aria-label="close"
            @click="isFormActive = false"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="field">
            <label class="label">{{ $t("company.name") }}</label>
            <div class="control">
              <input class="input" type="text" v-model="companyName" required />
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <div class="field is-grouped">
            <p class="control">
              <button
                :class="['button', 'is-success', isLoading ? 'is-loading' : '']"
                @click="createOrUpdate"
              >
                {{ companyId ? $t("company.update") : $t("company.create") }}
              </button>
            </p>
          </div>
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "CompanyList",
  data() {
    return {
      companyName: "",
      companyId: 0,
      isFormActive: false,
      page: 1,
      size: 10,
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      companies: (state) => state.companies,
    }),
  },
  methods: {
    ...mapActions(["refreshState"]),
    openForm(id, name) {
      this.companyId = id;
      this.companyName = name;
      this.isFormActive = true;
    },
    closeForm() {
      this.isFormActive = false;
    },
    createOrUpdate() {
      this.isLoading = true;
      let formData = new FormData();
      if (this.companyId == 0) {
        formData.append("op", "create_company");
        formData.append("name", this.companyName);
        this.$http.post("company", formData);
      }
      if (this.companyId > 0) {
        formData.append("op", "update_company");
        formData.append("id", this.companyId);
        formData.append("name", this.companyName);
        this.$http.put("company", formData);
      }
      setTimeout(() => {
        this.refreshState();
        this.isLoading = false;
        this.closeForm();
      }, "1000");
    },
    remove(id) {
      this.isLoading = true;
      this.$http
        .delete("company", {
          params: {
            op: "delete_company",
            id: id,
          },
        })
        .finally(() => {
          this.refreshState();
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.companyName = "";
    this.companyId = 0;
    this.isFormActive = false;
  },
};
</script>
