<template>
  <div class="modal is-active" key="camera-modal">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <slot name="title">
            {{ camera.id ? $t("camera.update") : $t("camera.create") }}
          </slot>
        </p>
        <button
          class="delete"
          aria-label="close"
          @click="$emit('close')"
        ></button>
      </header>
      <section class="modal-card-body">
        <div class="field">
          <label class="label">{{ $t("project.project") }}</label>
          <div class="control">
            <span class="select" style="width: 100%">
              <select v-model="camera.project_id" style="width: 100%">
                <option
                  v-for="project in projects"
                  :key="project.id"
                  :value="project.id"
                >
                  {{ project.name }}
                </option>
              </select>
            </span>
          </div>
        </div>
        <div class="field">
          <label class="label">{{ $t("camera.name") }}</label>
          <div class="control">
            <input class="input" type="text" v-model="camera.name" required />
          </div>
          <p
            class="help is-danger"
            v-if="camera.name == '' && formValidation == false"
          >
            {{ $t("camera.createFormRequirements.name") }}
          </p>
        </div>
        <div class="field">
          <label class="label">{{ $t("project.timezone") }}</label>
          <div class="control">
            <span class="select" style="width: 100%">
              <select v-model="camera.timezone" style="width: 100%">
                <option
                  v-for="timezone in timezones"
                  :key="timezone"
                  :value="timezone"
                >
                  {{ timezone }}
                </option>
              </select>
            </span>
          </div>
        </div>

        <div class="field">
          <label class="label">{{ $t("camera.serial_number") }}</label>
          <div class="control">
            <input
              class="input"
              type="text"
              v-model="camera.serial_number"
              required
            />
          </div>
          <p
            class="help is-danger"
            v-if="camera.serial_number == '' && formValidation == false"
          >
            {{ $t("camera.createFormRequirements.serial_number") }}
          </p>
        </div>
      </section>
      <footer class="modal-card-foot">
        <div class="field is-grouped">
          <p class="control">
            <button
              :class="['button', 'is-success', isLoading ? 'is-loading' : '']"
              @click="createOrUpdate"
            >
              {{ camera.id ? $t("camera.update") : $t("camera.create") }}
            </button>
          </p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { timezones } from "@/assets/timezones.js";
export default {
  props: {
    camera: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isFormActive: false,
      formValidation: true,
      isLoading: false,
      // camera: {
      //   id: null,
      //   name: "",
      //   project_id: null,
      //   serial_number: "",
      //   timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      // },
      timezones: timezones,
    };
  },
  computed: {
    ...mapState({
      cameras: (state) => state.cameras,
      projects: (state) => state.projects,
    }),
  },
  methods: {
    ...mapActions(["refreshState"]),
    async createOrUpdate() {
      if (this.validateForm() == false) {
        this.formValidation = false;
        return;
      }
      this.isLoading = true;
      let formData = new FormData();

      formData.append("name", this.camera.name);
      formData.append("serial_number", this.camera.serial_number);
      formData.append("project_id", this.camera.project_id);
      formData.append("timezone", this.camera.timezone);
      formData.append("version", 1);

      const properties = {
        serial_number: this.camera.serial_number,
      };
      formData.append("properties",JSON.stringify(properties));



      let response = null;

      if (this.camera.id == 0 || this.camera.id == null) {
        formData.append("op", "create_camera");
        response = await this.$http.post("camera", formData);
        this.camera.id = response.data.id;
        if (response.status == 200) {
          this.$toast.success(this.$t("camera.createSuccess"));
        }
      } else if (this.camera.id > 0) {
        formData.append("op", "update_camera");
        formData.append("id", this.camera.id);
        response = await this.$http.put("camera", formData);
        if (response.status == 200) {
          this.$toast.success(this.$t("camera.updateSuccess"));
        }
      }
    
      this.refreshState();
      this.isLoading = false;
    },
    validateForm() {
      if (
        this.camera.project_id == "" ||
        this.camera.name == "" ||
        this.camera.serial_number == ""
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style></style>
