<template>
  <div class="modal is-active" key="camera-modal">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ $t("camera.selectCameraType") }}
        </p>
        <button
          class="delete"
          aria-label="close"
          @click="$emit('close')"
        ></button>
      </header>
      <div class="modal-card-body">
        <div class="columns is-centered">
          <div
            class="column has-text-centered is-flex is-flex-direction-column"
          >
            <div class="box">
              <figure class="image">
                <img src="../assets/images/natilon-pro-x.png" />
              </figure>
            </div>
            <button
              class="button is-large is-info"
              @click="$emit('switchForm', 'CameraSettingsPro')"
            >
              Natilon Pro
            </button>
          </div>
          <div
            class="column has-text-centered is-flex is-flex-direction-column"
          >
            <div class="box is-flex-grow-2">
              <figure class="image">
                <img src="../assets/images/onvif-camera.png" />
              </figure>
            </div>
            <button
              class="button is-large is-info"
              @click="$emit('switchForm', 'CameraSettingsOnvif')"
            >
              Onvif Camera
            </button>
          </div>
          <div
            class="column has-text-centered is-flex is-flex-direction-column"
          >
            <div class="box is-flex-grow-2 is-flex is-justify-content-center is-align-items-center">
              <figure class="image">
                <img src="../assets/images/hikvision_logo.png" />
              </figure>
            </div>
            <button
              class="button is-large is-info"
              @click="$emit('switchForm', 'CameraSettingsHikvision')"
            >
              Hikvision
            </button>
          </div>
        </div>
      </div>
      <footer class="modal-card-foot"></footer>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>

</style>
