<template>
    <div :class="['modal', isActive ? 'is-active' : '']">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t("bulkPhotoDownloads.form.title") }}</p>
          <button
            class="delete"
            aria-label="close"
            @click="SET_MODAL_STATE_BULK_PHOTO_DOWNLOAD(false)"
          ></button>
        </header>
        <section class="modal-card-body">
          <p class="notification">
            {{ $t("video.form.notification") }}
          </p>
          <div class="columns is-multiline">
            <div class="column is-half">
              <div class="field">
                <label class="label">{{ $t("video.form.startDate") }}</label>
                <div class="control">
                  <Datepicker
                    v-model="startDate"
                    input-class="input"
                    wrapper-class="calendar-wrapper"
                    calendar-button-icon="fas fa-calendar"
                    monday-first
                    format="dd.MM.yyyy"
                    :language="tr"
                  ></Datepicker>
                </div>
              </div>
            </div>
            <div class="column is-half">
              <div class="field">
                <label class="label">{{ $t("video.form.endDate") }}</label>
                <div class="control">
                  <Datepicker
                    v-model="endDate"
                    input-class="input"
                    wrapper-class="calendar-wrapper"
                    calendar-button-icon="fas fa-calendar"
                    monday-first
                    format="dd.MM.yyyy"
                    :language="tr"
                  ></Datepicker>
                </div>
              </div>
            </div>
            
            

            <div class="column is-half">
              <label class="label">{{ $t("menu.cameras") }}</label>
              <div class="control">
                <span class="select" style="width: 100%">
                  <select v-model="cameraId" style="width: 100%">
                    <option
                      v-for="camera in cameras"
                      :key="camera.id"
                      :value="camera.id"
                    >
                      {{ camera.name }} - {{ camera.project.name }}
                    </option>
                  </select>
                </span>
              </div>
            </div>


            </div>
         
        </section>
        <footer class="modal-card-foot">
          <div class="field is-grouped">
            <p class="control">
              <button class="button is-text" @click="SET_MODAL_STATE_BULK_PHOTO_DOWNLOAD(false)">
                {{ $t("video.form.cancel") }}
              </button>
            </p>
            <p class="control">
              <button
                :class="['button', 'is-success', isLoading ? 'is-loading' : '']"
                @click="create"
              >
                {{ $t("video.form.create") }}
              </button>
            </p>
          </div>
        </footer>
      </div>
    </div>
  </template>
  <style scoped>
  .modal-card-body {
    min-height: 540px;
  }
  </style>
  
  <script>
  import { mapState, mapMutations } from "vuex";
  import Datepicker from "vuejs-datepicker";

  import { tr } from "vuejs-datepicker/dist/locale";


  
  const today = new Date();
  export default {
    name: "bulkPhotoDownloadRequest",
    data() {
      return {
        isLoading: false,
        startDate: new Date(today.getFullYear(), today.getMonth(), 1),
        endDate: today,
        cameraId:0,
        
        tr
      };
    },
    computed: {
      ...mapState({
      isActive: (state) => state.modals.bulkPhotoDownload.isActive,
      cameras: (state) => state.cameras,
    }),
    },
    components: {
      Datepicker
    },

    
    mounted: function() {
      if (this.cameras.length > 0) {
        this.cameraId = this.cameras[0].id
      }

      console.log(this.cameras[0])
    },

    methods: {
      ...mapMutations(["SET_MODAL_STATE_BULK_PHOTO_DOWNLOAD"]),
      create: function () {
        var formData = new FormData();
        formData.append("start_date", this.startDate.toISOString().split('.')[0] + "Z");
        formData.append("end_date", this.endDate.toISOString().split('.')[0] + "Z");
        formData.append("camera_id", this.cameraId);
  
        this.isLoading = true;
  
        this.$http.post("bulk-photo-download/", formData).then(() => {
          this.isLoading = false;
          this.reset();
          this.SET_MODAL_STATE_BULK_PHOTO_DOWNLOAD(false);
        });
      },
      reset: function () {
        this.startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        this.endDate = today;
      },
    },
  };
  </script>
  
  <style></style>
  