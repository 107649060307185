export const getters = {
  selectedCompany: (state) => {
    return state.companies.find((company) => company.isSelected);
  },
  otherCompanies: (state) => {
    return state.companies.filter((company) => !company.isSelected);
  },
  companyProjects: (state, getters) => {
    return state.projects.filter(
      (p) => getters.selectedCompany.id == p.company_id
    );
  },
  selectedProject: (state, getters) => {
    return getters.companyProjects.find((p) => p.isSelected);
  },
  otherProjects: (state, getters) => {
    return getters.companyProjects.filter((p) => !p.isSelected);
  },
  projectCameras: (state, getters) => {
   return  state.cameras.filter(
    (c) => getters.selectedProject.id == c.project_id
  );
  },
  selectedCamera: (state, getters) => {
    return getters.projectCameras.find((c) => c.isSelected);
  },
  otherCameras: (state, getters) => {
    return getters.projectCameras.filter((c) => !c.isSelected);
  },
  firstCamera: (state, getters) => {
    return getters.projectCameras[0];
  },
};
