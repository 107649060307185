<template>
  <div class="hero-foot">
    <div class="container p-5">
      <nav class="level">
        <div class="level-left">
          <div class="level-item has-text-grey-lighter">
            2023 &copy; Natilon
          </div>
          <a class="level-item" href="">Privacy Policy</a>
          <a class="level-item" href="">Terms of Service</a>
        </div>
        <div class="level-right">
          <a
            class="level-item"
            href="https://www.instagram.com/natilontimelapse"
          >
            <span class="icon">
              <i class="fab fa-instagram"></i>
            </span>
          </a>
          <a class="level-item" href="https://x.com/natilonpro">
            <span class="icon">
              <i class="fab fa-twitter"></i>
            </span>
          </a>
          <a class="level-item" href="https://www.linkedin.com/company/natilon">
            <span class="icon">
              <i class="fab fa-linkedin"></i>
            </span>
          </a>
          <a
            class="level-item"
            href="https://www.youtube.com/@natilontimelapse"
          >
            <span class="icon">
              <i class="fab fa-youtube"></i>
            </span>
          </a>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
a:hover {
  color: white;
}
.level-left .level-item {
  border-right: 1px solid #3464e0;
  padding-right: 1rem;
}
</style>
