<template>
  <div>
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item" href="#" @click="openTimelinePage">
          <img src="../assets/images/natilon_symbol.svg" height="45" />
        </a>
        <div class="navbar-item is-hidden-desktop has-family-secondary">
          {{ selectedCompany.name }}
        </div>
        <a
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="natilonNavbar"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div id="natilonNavbar" class="navbar-menu">
        <div class="navbar-start">
          <div
            class="navbar-item is-size-5 is-hidden-touch has-family-secondary has-text-weight-bold"
          >
            {{ selectedCompany.name }}
          </div>
          <div
            v-if="isShowDatepicker"
            class="navbar-item is-size-4 is-hidden-desktop"
            style="margin: auto"
          >
            <div class="field">
              <p class="control has-icons-left is-expanded">
                <Datepicker
                  v-model="selectedDate"
                  input-class="input"
                  wrapper-class="calendar-wrapper"
                  calendar-button-icon="fas fa-calendar"
                  monday-first
                  format="dd.MM.yyyy"
                  :language="language"
                  :disabled-dates="disabledDates"
                  @selected="closeMenu"
                ></Datepicker>
                <span class="icon is-small is-left">
                  <i class="fas fa-calendar-alt"></i>
                </span>
              </p>
            </div>
          </div>
          <ProjectSelector />
          <CameraSelector />
          <MobileMenu />
          <div
            v-if="isShowDatepicker"
            class="navbar-item is-size-4 is-hidden-touch"
            style="margin: auto"
          >
            <div class="field">
              <p class="control has-icons-left">
                <Datepicker
                  v-model="selectedDate"
                  input-class="input"
                  wrapper-class="calendar-wrapper"
                  calendar-button-icon="fas fa-calendar"
                  monday-first
                  format="dd.MM.yyyy"
                  :language="language"
                  :disabled-dates="disabledDates"
                ></Datepicker>
                <span class="icon is-small is-left">
                  <i class="fas fa-calendar-alt"></i>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="navbar-end">
          <ViewButtonGroup />
          <DesktopMenu />
        </div>
      </div>
    </nav>
    <CompanySelect />
    <PhotoShare />
    <BookmarkForm />
    <LanguageSelector />
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { tr, en } from "vuejs-datepicker/dist/locale";
import { mapGetters, mapMutations, mapActions } from "vuex";
import CompanySelect from "@/components/CompanySelect";
import CameraSelector from "@/components/navbar/CameraSelector";
import ProjectSelector from "@/components/navbar/ProjectSelector";
import ViewButtonGroup from "@/components/navbar/ViewButtonGroup";
import MobileMenu from "@/components/navbar/MobileMenu";
import DesktopMenu from "@/components/navbar/DesktopMenu";
import PhotoShare from "@/components/navbar/PhotoShare";
import BookmarkForm from "@/components/BookmarkForm";
import LanguageSelector from "@/components/LanguageSelector";

export default {
  name: "Home",
  data() {
    var disabledDate = new Date();
    disabledDate.setHours(23, 59, 59);
    return {
      language: tr,
      disabledDates: {
        from: disabledDate,
      },
      photoShareLink: null,
    };
  },
  components: {
    Datepicker,
    CompanySelect,
    PhotoShare,
    BookmarkForm,
    CameraSelector,
    ProjectSelector,
    ViewButtonGroup,
    MobileMenu,
    DesktopMenu,
    LanguageSelector,
  },
  watch: {
    userLanguage(newVal) {
      if (newVal == "tr") {
        this.language = tr;
      } else {
        this.language = en;
      }
    },
  },
  computed: {
    ...mapGetters([
      "selectedCompany",
      // "selectedCamera",
      // "selectedProject",
      // "otherProjects",
      // "projectCameras",
      // "otherCameras",
    ]),

    currentRoute() {
      return this.$route.name;
    },
    userLanguage: function () {
      return this.$store.state.user.language;
    },
    selectedDate: {
      get() {
        return this.$store.state.selectedDate;
      },
      set(value) {
        this.SET_SELECTED_DATE(value);
        this.$root.$emit("date-changed", value);
      },
    },
    isShowDatepicker() {
      return (
        this.currentRoute !== "Video" &&
        this.currentRoute !== "UserList" &&
        this.currentRoute !== "Bookmarks" &&
        this.currentRoute !== "CameraSettings" &&
        this.currentRoute !== "CameraList" &&
        this.currentRoute !== "ProjectList" &&
        this.currentRoute !== "Users"
      );
    },
    isShowCameraSelector() {
      return this.currentRoute !== "UserList";
    },
  },

  methods: {
    ...mapMutations([
      "SET_SELECTED_DATE",
      "SELECT_COMPANY",
      "SET_MODAL_STATE_SELECT_COMPANY",
      "SET_MODAL_STATE_CHANGE_PASSWORD",
    ]),
    ...mapActions(["openTimelinePage"]),
    openSelectCompanyModal() {
      this.SET_MODAL_STATE_SELECT_COMPANY(true);
    },
    closeMenu() {
      document.querySelector(".navbar-menu").classList.remove("is-active");
      const $navbarBurgers = Array.prototype.slice.call(
        document.querySelectorAll(".navbar-burger"),
        0
      );

      if ($navbarBurgers.length > 0) {
        $navbarBurgers.forEach((el) => {
          el.classList.toggle("is-active");
        });
      }
      document
        .querySelectorAll(".navbar-item.has-dropdown.is-active")
        .forEach((el) => {
          el.classList.remove("is-active");
        });
      this.$root.$emit("date-changed", this.selectedDate);
    },
  },
  mounted() {
    if (this.userLanguage == "tr") {
      this.language = tr;
    } else {
      this.language = en;
    }

    if (this.$store.state.selectedDate === null) {
      this.SET_SELECTED_DATE(new Date());
    }

    const $navbarBurgers = Array.prototype.slice.call(
      document.querySelectorAll(".navbar-burger"),
      0
    );

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
      // Add a click event on each of them
      $navbarBurgers.forEach((el) => {
        el.addEventListener("click", () => {
          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const $target = document.getElementById(target);

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle("is-active");
          $target.classList.toggle("is-active");
        });
      });
    }
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(10px);
}

.navbar {
  background: #000068 !important;
}
.navbar-burger span,
.navbar-brand .navbar-item,
.navbar-end .navbar-item,
.navbar-start .navbar-item {
  color: #fff;
}
.navbar-item .navbar-dropdown {
  border-top: 3px solid #4acefc;
}
.navbar-item.has-dropdown,
.navbar-item.has-dropdown .navbar-link {
  color: #fff;
  background-color: #000068;
}
.navbar-item.has-dropdown:hover .navbar-link,
.navbar-item.has-dropdown:focus .navbar-link,
.navbar-item.has-dropdown.is-active .navbar-link {
  background-color: #3464e0 !important;
}

.navbar-item.has-dropdown:hover .navbar-link:focus .navbar-link {
  background-color: #3464e0;
}
.navbar-item.has-dropdown:hover .navbar-link:not(.is-arrowless)::after {
  border-color: #fff;
}
.calendar-wrapper {
  color: black;
}
@media only screen and (max-width: 1023px) {
  .navbar-menu {
    background-color: #3464e0 !important;
  }
  .navbar-menu.is-active {
    position: absolute;
    width: 100%;
    opacity: 0.98;
  }
  .navbar-dropdown .navbar-item {
    display: none;
  }
  .navbar-item.has-dropdown.is-active .navbar-dropdown .navbar-item {
    display: block;
  }
  .is-hidden-desktop .navbar-item:hover {
    background: #fff !important;
    color: #000068 !important;
  }
}
</style>
