<template>
  <div>
    <h1 class="title has-text-white">{{ $t("register.register") }}</h1>
    <form class="box" action="">
      <p class="notification" v-html="$t('register.registerNotification')"></p>
      <div class="field">
        <p class="control has-icons-left">
          <input
            :class="['input', fullNameInputClass]"
            type="text"
            v-model="fullName"
            :placeholder="$t('register.fullName')"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-user"></i>
          </span>
        </p>
      </div>
      <div class="field">
        <p class="control has-icons-left">
          <input
            :class="['input', emailInputClass]"
            type="email"
            v-model="email"
            :placeholder="$t('login.email')"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-envelope"></i>
          </span>
        </p>
      </div>
      <div class="field">
        <p class="control has-icons-left">
          <input
            :class="['input', passwordInputClass]"
            type="password"
            v-model="password"
            :placeholder="$t('login.password')"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-lock"></i>
          </span>
        </p>
      </div>
      <div class="field">
        <p class="control has-icons-left">
          <input
            :class="['input', passwordInputClass]"
            type="password"
            v-model="passwordConfirmation"
            :placeholder="$t('login.passwordConfirmation')"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-lock"></i>
          </span>
        </p>
      </div>
      <div :class="['conditions', 'notification', conditionsClass]">
        <div class="field pt-5">
          <label class="checkbox" for="agree-terms">
            <input
              type="checkbox"
              class="mr-2"
              id="agree-terms"
              v-model="agreeTerms"
            />
            <span v-html="$t('register.agree')"></span>
          </label>
        </div>
        <div class="field pb-5">
          <label class="checkbox" for="newsletter-optin">
            <input
              type="checkbox"
              id="newsletter-optin"
              v-model="newsletterOptIn"
            />
            {{ $t("register.newsletter") }}
          </label>
        </div>
      </div>
      <p class="control">
        <button
          :class="[
            'button',
            'is-fullwidth',
            'is-success',
            inProgress ? 'is-loading' : '',
          ]"
          @click="registerHandler"
        >
          {{ $t("register.register") }}
        </button>
      </p>
      <a
          style="background-color: white"
          class="button mt-3 is-info is-outlined is-fullwidth"
          @click="register_with_google_button"
        >
          <span class="icon">
            <img src="../assets/images/google.256x256.png" />
          </span>
          <span style="color:#3e8fd0">
            {{ $t("register.register_with_google") }}
          </span>
        </a>
    </form>
  </div>
</template>

<script>
import router from "@/router/";
import { mapActions, mapState } from "vuex";
const timeOut = 3000;

export default {
  data() {
    return {
      fullName: "",
      password: "",
      passwordConfirmation: "",
      email: "",
      fullNameInputClass: "",
      passwordInputClass: "",
      emailInputClass: "",
      failed: false,
      inProgress: false,
      conditionsClass: "",
      agreeTerms: false,
      newsletterOptIn: false,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  name: "Register",
  methods: {
    ...mapActions(["showToast","googleRegister"]),
    async registerHandler(e) {
      e.preventDefault();
      if (!this.fullName) {
        this.$showToastWithType(this.$t("register.fullNameError"), "error");
        this.fullNameInputClass = "is-danger";
        setTimeout(() => {
          this.fullNameInputClass = "";
        }, timeOut);
        return;
      }

      if (!this.email) {
        this.$showToastWithType(this.$t("login.userNameError"), "error");
        this.emailInputClass = "is-danger";
        setTimeout(() => {
          this.emailInputClass = "";
        }, timeOut);
        return;
      }

      if (!this.password || !this.validatePassword(this.password)) {
        this.$showToastWithType(this.$t("login.passwordError"), "error");
        this.passwordInputClass = "is-danger";
        setTimeout(() => {
          this.passwordInputClass = "";
        }, timeOut);
        return;
      }

      if (!this.agreeTerms) {
        this.$showToastWithType(this.$t("register.mustAgreeTerms"), "error");
        this.conditionsClass = "is-danger";
        setTimeout(() => {
          this.conditionsClass = "";
        }, timeOut);
        return;
      }

      var formData = new FormData();
      formData.append("fullName", this.fullName);
      formData.append("password", this.password);
      formData.append("email", this.email);
      formData.append("newsletter", this.newsletterOptIn);

      this.inProgress = true;

      this.$http
        .post("register", formData)
        .then((response) => {
          if (response?.status == 201) {
            this.$showToast(this.$t("register.successMessage"), "info");
            router.push("/validate?email=" + this.email);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.status) {
            this.$showToast(this.$t("register.errorMessage"));
          }
        })
        .finally(() => {
          this.inProgress = false;
        });
    },

    validatePassword(password) {
      if (this.passwordConfirmation != password) {
        return false;
      }
      var pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;
      return pattern.test(password);
    },
    login_handler() {
      router.push("/");
    },
    google_register(authCode) {
          var formData = new FormData();
          formData.append("oauthCode", authCode);
          formData.append("isOauthLogin", "true");
          this.inProgress = true;

          this.googleRegister(formData)
          .then((response) => {
            if (response?.status == 201 ) {
              this.$showToast(this.$t("register.successMessage"),"info")
            } 
          })
          .catch((err) => {
            console.log(err);
            if (err.status) {
              this.$showToast(this.$t("register.errorMessage"))
            }
          })
          .finally(() => {
            this.inProgress = false;
          });
    },
    register_with_google_button(){
      var state = (Math.random() + 1).toString(36).substring(7);
      sessionStorage.setItem("auth0State", state)

      var auth0Url = process.env.VUE_APP_AUTH0_URL 
      var clientId = process.env.VUE_APP_AUTH0_CLIENT_ID
      var redirectUri = process.env.VUE_APP_URL + "/register"
  
      var queryParams = {
        response_type: "code",
        client_id: clientId,
        redirect_uri: redirectUri,
        scope: "openid profile email",
        state: state
      };

      window.location.href = auth0Url+"?"+new URLSearchParams(queryParams).toString()
    }
  },
  mounted (){
    sessionStorage.removeItem("jwt");
    let uri = window.location.search.substring(1); 
    let params = new URLSearchParams(uri);
    var code = params.get("code")
    var state = params.get("state")
    if (code != null && state != null && state.toString() == sessionStorage.getItem("auth0State")) {
      this.google_register(code)
    }
  },
};
</script>
