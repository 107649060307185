<template>
  <div :class="['modal', isActive ? 'is-active' : '']">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t("user.form.title") }}</p>
        <button class="delete" aria-label="close" @click="close"></button>
      </header>
      <section class="modal-card-body">
        <div class="tabs is-boxed">
          <ul>
            <li
              v-for="(tab, index) in availableTabs"
              :key="index"
              :class="tab.isSelected ? 'is-active' : ''"
            >
              <a @click="selectTab(index)">
                <span>{{ $t(tab.title) }}</span>
              </a>
            </li>
          </ul>
        </div>
        <GeneralTab v-if="selectedTab == 0" />
        <ProjectPermissions v-if="selectedTab == 1" />
        <CameraPermissions v-if="selectedTab == 2" />
        <Roles v-if="selectedTab == 3" />
        <OperationsTab v-if="selectedTab == 4" />
      </section>
      <footer class="modal-card-foot">
        <div class="field is-grouped" v-if="selectedTab !== 'Yönetim'">
          <p class="control">
            <button class="button is-text" @click="reset">
              {{ $t("user.form.reset") }}
            </button>
          </p>
          <p class="control">
            <button
              :class="[
                'button',
                'is-success',
                buttons.save.isLoading ? 'is-loading' : '',
              ]"
              @click="saveDetails"
              :disabled="!formReady"
            >
              {{ $t("user.form.save") }}
            </button>
          </p>
        </div>
      </footer>
    </div>
  </div>
</template>
<style scoped>
.modal-card-body {
  min-height: 540px;
}
label.checkbox:not(:last-child) {
  margin-right: 30px;
}
</style>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import GeneralTab from "./tabs/General.vue";
import ProjectPermissions from "./tabs/ProjectPermissions.vue";
import Roles from "./tabs/Roles.vue";
import CameraPermissions from "./tabs/CameraPermissions.vue";
import OperationsTab from "./tabs/Operations.vue";

export default {
  components: {
    GeneralTab,
    Roles,
    ProjectPermissions,
    CameraPermissions,
    OperationsTab,
  },
  data() {
    return {
      tabs: [
        {
          id: 0,
          title: "user.form.tabs.general.title",
          isSelected: true,
          permisson: "user_details",
        },
        {
          id: 1,
          title: "user.form.tabs.projectPermissions.title",
          isSelected: false,
          permisson: "user_details",
        },
        {
          id: 2,
          title: "user.form.tabs.cameraPermissions.title",
          isSelected: false,
          permisson: "user_details",
        },
        {
          id: 3,
          title: "user.form.tabs.roles.title",
          isSelected: false,
          permisson: "user_details",
        },
        {
          id: 4,
          title: "user.form.tabs.operations.title",
          isSelected: false,
          permisson: "reset_user_password",
        },
      ],
      buttons: {
        save: {
          isLoading: false,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["selectedCompany"]),
    selectedTab() {
      for (let tab of this.tabs) {
        if (tab.isSelected) {
          return tab.id;
        }
      }

      return false;
    },
    ...mapState({
      permissions: (state) => state.user.permissions,
      user: (state) => state.userForm,
      isActive: (state) => state.modals.userDetails.isActive,
      formReady: (state) => {
        return (
          state.userForm.fullName.length > 3 &&
          state.userForm.email.indexOf("@") > 0 &&
          state.userForm.email.indexOf(".") > 0 &&
          state.userForm.email.length > 5
        );
      },
    }),
    availableTabs() {
      let tabs = [];
      for (let tab of this.tabs) {
        if (this.permissions.includes(tab.permisson)) {
          tabs.push(tab);
        }
      }

      return tabs;
    },
  },
  methods: {
    ...mapMutations([
      "SET_MODAL_STATE_USER_DETAILS",
      "SET_USERFORM_ID",
      "SET_USERFORM_FULLNAME",
      "SET_USERFORM_ISACTIVE",
      "SET_USERFORM_EMAIL",
      "SET_USERFORM_PERMISSIONS",
    ]),
    saveDetails: function () {
      var formData = new FormData();
      formData.append("cid", this.selectedCompany.id);

      formData.append("id", this.user.id);
      formData.append("fullName", this.user.fullName);
      formData.append("email", this.user.email);
      formData.append("isActive", this.user.isActive);
      formData.append("permissions", JSON.stringify(this.user.permissions));
      formData.append("functionalPermissions", this.user.functionalPermissions);

      let isUpdate = this.user.id;

      formData.append("op", isUpdate ? "update_user" : "create_user");

      this.buttons.save.isLoading = true;
      this.$http
        .post(isUpdate ? "update-user" : "create-user", formData)
        .then((response) => {
          this.buttons.save.isLoading = false;
          if (isUpdate) {
            if (response.status === 200) {
              this.$emit("changed");
              this.$showToastWithType(this.$t("user.userUpdated"),"success");
            }
          } else {
            if (response.status === 201) {
              this.$emit("changed");
              this.$showToastWithType(this.$t("user.userCreated"),"success");
              this.close();
            }
          }
        })
        .finally(() => {
          this.buttons.save.isLoading = false;
        });
    },
    reset: function () {
      this.SET_USERFORM_ID(null);
      this.SET_USERFORM_FULLNAME("");
      this.SET_USERFORM_EMAIL("");
      this.SET_USERFORM_ISACTIVE(true);
      this.SET_USERFORM_PERMISSIONS(null);
    },
    selectTab(i) {
      if (this.user.id == null && i > 0) {
        this.$showToastWithType(this.$t("user.pleaseSaveUser"),"info");
        return;
      }
      for (let tab of this.tabs) {
        tab.isSelected = false;
      }

      this.tabs[i].isSelected = true;
    },
    close() {
      this.SET_MODAL_STATE_USER_DETAILS(false);
      this.selectTab(0);
    },
  },
};
</script>

<style></style>
