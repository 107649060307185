<template>
  <div class="is-hidden-desktop">
    <div v-if="isShowShareButton" class="navbar-item">
      <a class="icon-text" @click="photoShare">
        <span class="icon"> <i class="fas fa-share-alt"></i></span>
        <span>{{ $t("menu.mobile.share") }}</span>
      </a>
    </div>
    <div v-if="isShowShareButton" class="navbar-item">
      <a class="icon-text" @click="download">
        <span class="icon"> <i class="fas fa-download"></i></span>
        <span>{{ $t("menu.mobile.download") }}</span>
      </a>
    </div>
    <div v-if="currentRoute !== 'Video'" class="navbar-item">
      <a class="icon-text" @click="openVideoPage">
        <span class="icon"> <i class="fas fa-video"></i></span>
        <span>{{ $t("menu.mobile.videos") }}</span></a
      >
    </div>
    <div v-if="currentRoute !== 'Timeline'" class="navbar-item">
      <a class="icon-text" @click="openTimelinePage">
        <span class="icon"> <i class="fas fa-calendar-alt"></i> </span
        ><span>{{ $t("menu.mobile.timeline") }}</span></a
      >
    </div>
    <div v-if="currentRoute !== 'FileList'" class="navbar-item">
      <a class="icon-text" @click="openFileListPage">
        <span class="icon"> <i class="fas fa-list"></i> </span
        ><span>{{ $t("menu.mobile.photos") }}</span></a
      >
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  computed: {
    currentRoute() {
      return this.$route.name;
    },
    isShowShareButton() {
      return this.currentRoute !== "Video" && this.currentRoute !== "UserList";
    },
  },
  methods: {
    ...mapActions(["openVideoPage", "openTimelinePage", "openFileListPage"]),
    download() {
      this.$store.dispatch("download", this.currentPhoto);
    },
    photoShare() {
      if (!this.user.permissions.includes(this.shareModal.permission)) {
        this.$showToastWithType(this.$i18n.t("message.error.noFeaturePermission"),"error");
        return;
      }
      this.SET_MODAL_STATE_PHOTO_SHARE(true);
    },
    openVideoPage() {
      this.$router.push({ name: "Video" });
    },
    openTimelinePage() {
      this.$router.push({ name: "Timeline" });
    },
    openFileListPage() {
      this.$router.push({ name: "FileList" });
    },
  },
};
</script>

<style></style>
