<template>
  <div :class="['modal', isActive ? 'is-active' : '']">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t("video.share.title") }}</p>
        <button class="delete" aria-label="close" @click="close"></button>
      </header>
      <section class="modal-card-body">
        <p class="notification">
          {{ $t("video.share.notification") }}
        </p>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">{{ $t("video.share.validityPeriod") }}</label>
          </div>
          <div class="field-body">
            <div class="field is-narrow">
              <div class="control">
                <div :class="['select', isLoading ? 'is-loading' : '']">
                  <select v-model="linkDuration" :disabled="!!isLoading">
                    <option value="">{{ $t("video.share.select") }}</option>
                    <option value="1">1 {{ $tc("video.share.day", 1) }}</option>
                    <option value="2">2 {{ $tc("video.share.day", 2) }}</option>
                    <option value="3">3 {{ $tc("video.share.day", 2) }}</option>
                    <option value="4">4 {{ $tc("video.share.day", 2) }}</option>
                    <option value="5">5 {{ $tc("video.share.day", 2) }}</option>
                    <option value="6">6 {{ $tc("video.share.day", 2) }}</option>
                    <option value="7">7 {{ $tc("video.share.day", 2) }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <center v-if="videoLink">
          <a
            class="button is-success mt-5 mb-5"
            :href="videoLink"
            download
            target="_blank"
            >{{ $t("video.share.download") }}</a
          >
          <p>{{ $t("video.share.orShare") }}</p>
          <div class="field has-addons mt-5 mb-5">
            <p class="control is-expanded">
              <input
                id="natilon-link"
                type="text"
                class="input"
                :value="videoLink"
              />
            </p>
            <p class="control">
              <button class="button is-success" @click="copyLink">
                {{ $t("video.share.copyLink") }}
              </button>
            </p>
          </div>
          <img v-if="qrlink" :src="qrlink" />
        </center>
      </section>
      <footer class="modal-card-foot"></footer>
    </div>
  </div>
</template>
<style scoped>
.field.is-horizontal {
  margin-top: 1em;
}
.field.is-horizontal .field-body {
  flex-grow: 2;
}
.modal-card-body {
  min-height: 540px;
}
video {
  width: 100%;
}
</style>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  props: ["src"],
  data() {
    return {
      videoLink: null,
      linkDuration: "",
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["selectedCamera"]),
    ...mapState({
      isActive: (state) => state.modals.videoShare.isActive,
    }),
    qrlink() {
      return this.videoLink + ".qr";
    },
  },
  watch: {
    linkDuration() {
      if (this.linkDuration === "") return;
      this.createLink();
    },
  },
  methods: {
    ...mapMutations(["SET_MODAL_STATE_VIDEO_SHARE"]),
    copyLink: function () {
      let input = document.querySelector("#natilon-link");
      input.select();
      input.setSelectionRange(0, 9999);
      document.execCommand("copy");
      this.$showToastWithType(this.$i18n.t("video.share.linkCopied") + "\n\n" + this.src,"error");
    },
    createLink: function () {
      this.videoLink = null;
      this.isLoading = true;
      this.$http
        .get("share-video/", {
          params: {
            cid: this.selectedCamera.id,
            op: "share_video",
            id: this.src,
            d: this.linkDuration,
          },
        })
        .then((res) => {
          this.videoLink = res.data.url;
        })
        .catch((err) => {
          this.$showToastWithType(err.message,"error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    close: function () {
      this.videoLink = null;
      this.linkDuration = "";
      this.isLoading = false;
      this.SET_MODAL_STATE_VIDEO_SHARE(false);
    },
  },
};
</script>

<style></style>
